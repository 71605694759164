@mixin btn-icon-sizing() {
    .fa,
    .fa-brands,
    .fa-duotone,
    .fa-light,
    .fa-regular,
    .fa-solid,
    .fa-thin,
    .fab,
    .fad,
    .fal,
    .far,
    .fas,
    .fat {
        @include font-size(1.125em);
        line-height: 1;
        vertical-align: baseline;
    }
}

.btn {
    @include btn-icon-sizing();

    &:focus,
    .btn-check-input:focus ~ & {
        border-color: $active;
        box-shadow: $btn-focus-box-shadow;
    }
}

.btn-link {
    font-weight: 400;
}

.btn-round {
    width: add(divide(divide(30, 16), .875) * 1em, 2px);
    height: add(divide(divide(30, 16), .875) * 1em, 2px);
    padding: 0;
    @include border-radius(50%);
    overflow: hidden;
}

.btn-create {
    width: 7rem;
    height: 5rem;
    padding: .5rem 1rem;
    margin-bottom: .5rem;
}
.btn-create-img img {
    width: 3rem;
    height: auto;
    max-height: 3rem;
}
.btn-create-img .fa {
    @include font-size(2.5rem);
    line-height: 1.5;
}

.btn-google {
    width: auto;
    padding: 0 .75rem 0 0;
    overflow: hidden;
    font-family: Roboto, arial, sans-serif;
    @include font-size(.875rem);
    font-weight: 500;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    appearance: none;
    cursor: pointer;
    background-color: #4285f4;
    border: 1px solid #4285f4;
    @include border-radius(1px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    @include transition(background-color .218s, border-color .218s, box-shadow .218s);

    img {
        width: 2.125rem;
        height: 2.125rem;
        padding: .425rem;
        margin-right: .5rem;
        background-color: #fff;
    }

    &:hover,
    &:focus {
        outline: 0;
        box-shadow: 0 0 3px 3px rgba(66, 133, 244, .3);
    }
}
