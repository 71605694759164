.login-splash {
    position: relative;
    padding: 1rem .5rem 3rem;
    margin-top: -2rem;
    background-image: url("../img/loginSplash.png"); // 2560px x 1423px
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;

    @include media-breakpoint-down(sm) {
        background-position: top right;

        .container-fluid {
            position: relative;
            z-index: 1;
        }
    }

    @include media-breakpoint-up(md) {
        height: calc(100% * (1423 / 2560));
    }
}
.login-splash-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, .875);

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.login-splash-nav {
    .nav-link {
        font-weight: 700;
        text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
        margin-top: -1rem;

        .navbar-nav {
            position: absolute;
            top: 2.875rem;
            right: 0;
            left: 0;
            display: block;
            background-color: $body-bg;
            @include border-radius($border-radius);


            .nav-link {
                display: block;
                padding: .125rem .75rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .navbar-nav {
            display: inline-flex;
            flex-wrap: wrap;
            padding: .25rem .5rem;
            margin-left: -1rem;
            font-weight: 700;
            // background-color: rgba(255, 255, 255, .875);

            // stylelint-disable declaration-no-important
            > .list-item {
                padding-left: 0 !important;
                margin-right: $list-horizontal-padding !important;
            }
            // stylelint-enable declaration-no-important
        }
    }
}

.login-splash-logo {
    display: flex;
    align-items: flex-end;
    margin-top: 1.5rem;
}
.login-splash-logo-img {
    width: 1em;
    height: 1em;
    @include font-size(9rem);

    @include media-breakpoint-up(md) {
        @include font-size(6rem);
    }
}
.login-splash-logo-text {
    font-family: $font-family-headings;
    @include font-size(6rem);

    @include media-breakpoint-up(md) {
        @include font-size(4.5rem);
    }

}
.login-splash-lead {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: $font-family-headings;
    @include font-size(2.25rem);
    font-weight: 900;
    line-height: 1.25;
    color: $primary;

    @include media-breakpoint-up(md) {
        padding: .25rem .5rem;
        @include font-size(1.875rem);
        background-color: rgba(255, 255, 255, .875);
    }
}
.login-slides {
    position: relative;
}

.login-slides-prev,
.login-slides-next {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: .125rem;
}
.login-slides-next {
    right: 0;
    left: auto;
}

.login-slide-text {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    min-height: 3rem;
    padding: .5rem 3rem;
    text-align: center;
    background-color: rgba(255, 255, 255, .875);
}

.login-card {
    background-color: #f2f2f2;

    .card-footer {
        background-color: transparent;
        border: 0;
    }
}

.login-media {
    margin-bottom: 0;
}

.login-media-icon {
    margin-right: 1rem;
    @include font-size(5rem);
}
