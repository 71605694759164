@if $enable-breadcrumb {
    .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $breadcrumb-margin-bottom;
        @include font-size($breadcrumb-font-size);
        @include list-unstyled();
    }

    .breadcrumb-item {

        // The separator between breadcrumbs (by default, a forward-slash: "/")
        + .breadcrumb-item {
            padding-left: $breadcrumb-item-padding-x;

            &::before {
                float: left; // Suppress inline spacing and underlining of the separator
                padding-right: $breadcrumb-item-padding-x;
                color: $breadcrumb-divider-color;
                content: $breadcrumb-divider;
            }
        }

        // IE10-11 hack to properly handle hyperlink underlines for breadcrumbs built
        // without `<ul>`s. The `::before` pseudo-element generates an element
        // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
        //
        // To trick IE into suppressing the underline, we give the pseudo-element an
        // underline and then immediately remove it.
        // stylelint-disable no-duplicate-selectors
        + .breadcrumb-item:hover::before {
            text-decoration: underline;
        }
        + .breadcrumb-item:hover::before {
            text-decoration: none;
        }
        // stylelint-enable no-duplicate-selectors

        &.active {
            color: $breadcrumb-active-color;
        }
    }
}
