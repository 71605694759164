@if $enable-utility-close {
    .close {
        @include font-size($close-font-size);
        font-weight: $close-font-weight;
        line-height: 1;
        color: inherit;
        text-decoration: none;
        opacity: $close-opacity;

        // Override anchor styling
        @include hover-focus() {
            color: inherit;
            text-decoration: none;
        }

        &:not(:disabled):not(.disabled) {
            @include hover-focus() {
                opacity: $close-hover-opacity;
            }
        }
    }

    // Additional properties for button version
    // iOS requires the button element instead of an anchor tag.
    // If you want the anchor version, it requires `href="#"`.
    // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
    // stylelint-disable-next-line selector-no-qualifying-type
    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
    }

    // Future-proof disabling of clicks on `<a>` elements
    // stylelint-disable-next-line selector-no-qualifying-type
    a.close.disabled {
        pointer-events: none;
    }
}
