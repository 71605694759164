.lazy {
    opacity: 0;

    // Add transition here so that the image can be quickly hidden
    // before fading back in
    &.in {
        opacity: 1;
        @include transition($transition-lazy-fade);
    }
}

.fade {
    opacity: 0;
    @include transition($transition-fade);

    &.in {
        opacity: 1;
    }
}

.collapse {
    &:not(.in) {
        display: none;
    }
}

.collapsing {
    height: 0;
    overflow: hidden;

    @include transition($transition-collapse-y);

    // Horizontal collapse variant
    &.width {
        width: 0;
        height: auto;

        @if $enable-transitions {
            @include transition($transition-collapse-x);
        }
    }
}
