// Site footer

// Settings
// =====
$footer-site-padding:               1rem 0 0 !default;
$footer-site-bg:                    #f2f2f2 !default;
$footer-site-color:                 null !default;
$footer-site-border-width:          1px !default;
$footer-site-border-color:          $border-color !default;

$footer-site-link-color:            null !default;
$footer-site-link-decoration:       underline !default;
$footer-site-link-underline-pos:    auto !default;
$footer-site-link-hover-color:      null !default;
$footer-site-link-hover-decoration: underline !default;

$footer-site-brand-margin-bottom:   .75rem !default;
$footer-site-brand-font-size:       1.25rem !default;
$footer-site-brand-font-weight:     $font-weight-bold !default;

$footer-site-logo-width:            null !default;
$footer-site-logo-height:           null !default;
$footer-site-lg-logo-width:         null !default;
$footer-site-lg-logo-height:        null !default;

$footer-site-title-visible:         true !default;
$footer-site-title-font-family:     $font-family-headings !default;
$footer-site-title-font-size:       1.25rem !default;
$footer-site-title-font-weight:     $font-weight-bold !default;

$footer-site-tagline-visible:       true !default;
$footer-site-tagline-color:         null !default;
$footer-site-tagline-font-family:   null !default;
$footer-site-tagline-font-size:     1rem !default;
$footer-site-tagline-font-weight:   $font-weight-normal !default;

$footer-site-row-margin-bottom:     1rem !default;
$footer-site-row-margin-x:          0 !default;
$footer-site-row-gutter-width:      2rem !default;
$footer-site-col-margin-y:          1rem !default;

$footer-site-divider-width:         1px !default;
$footer-site-divider-color:         $border-color !default;

$footer-site-header-margin-y:       .25rem !default;
$footer-site-header-font-size:      1rem !default;
$footer-site-header-font-weight:    $font-weight-bold !default;

$footer-site-list-margin-bottom:    .25rem !default;
$footer-site-list-item-margin-y:    .75rem !default;
$footer-site-list-line-height:      1.25 !default;
$footer-site-list-object-margin-x:  .75rem !default;

$footer-site-media-color:           $body-color !default;
$footer-site-media-font-size:       .875rem !default;
$footer-site-media-object-width:    6rem !default;
$footer-site-media-object-margin-x: 1rem !default;

$footer-site-social-font-size:      1.5rem !default;
$footer-site-social-color:          $link-color !default;
$footer-site-social-hover-color:    $link-hover-color !default;

$footer-site-copy-font-size:        .875rem !default;


// Rules
// =====
.footer-site {
    position: relative;
    z-index: 10;
    padding: $footer-site-padding;
    clear: both;
    color: $footer-site-color;
    background-color: $footer-site-bg;
    border-top: $footer-site-border-width solid $footer-site-border-color;

    a:not(.btn) {
        color: $footer-site-link-color;
        text-decoration: $footer-site-link-decoration;
        text-underline-position: $footer-site-link-underline-pos;

        @include hover-focus() {
            color: $footer-site-link-hover-color;
            text-decoration: $footer-site-link-hover-decoration;
        }
    }
}

.footer-site-brand {
    margin-bottom: $footer-site-brand-margin-bottom;
    @include font-size($footer-site-brand-font-size);
    font-weight: $footer-site-brand-font-weight;
}

.footer-site-row {
    margin: 0 $footer-site-row-margin-x $footer-site-row-margin-bottom;
    border-top: $footer-site-divider-width solid $footer-site-divider-color;
    border-bottom: $footer-site-divider-width solid $footer-site-divider-color;

    & + .footer-row {
        margin-top: -$footer-site-row-margin-bottom;
        border-top: 0;
    }

    .list-unstyled {
        margin-bottom: $footer-site-list-margin-bottom;
        li {
            margin-bottom: $footer-site-list-item-margin-y;
            line-height: $footer-site-list-line-height;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .media-object {
            margin-right: $footer-site-list-object-margin-x;
        }
    }

    > .col {
        margin-top: $footer-site-col-margin-y;
        margin-bottom: $footer-site-col-margin-y;
        border-right: $footer-site-divider-width solid $footer-site-divider-color;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        > .col {
            flex-basis: 100%;
            max-width: 100%;
            padding-right: 0;
            padding-left: 0;
            border-right: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .col {
            padding-right: ($footer-site-row-gutter-width / 2);
            padding-left: ($footer-site-row-gutter-width / 2);
        }
    }

    @include media-breakpoint-only(md) {
        > .col {
            flex-basis: 50%;
            max-width: 50%;

            & + .col:nth-child(odd) {
                padding-left: 0;
            }
            & + .col:nth-child(even) {
                padding-right: 0;
                border-right: 0;
            }
        }
    }
}

.footer-site-header {
    margin-bottom: $footer-site-header-margin-y;
    @include font-size($footer-site-header-font-size);
    font-weight: $footer-site-header-font-weight;
}

.footer-site-media {
    color: $footer-site-media-color;

    .media-object {
        width: $footer-site-media-object-width;
        max-width: $footer-site-media-object-width;
        margin-right: $footer-site-media-object-margin-x;
    }
    .media-body {
        @include font-size( $footer-site-media-font-size);

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.footer-site-copy {
    align-items: baseline;
    justify-content: space-between;
    @include font-size($footer-site-copy-font-size);

    > .col {
        @include media-breakpoint-down(md) {
            flex-basis: 100%;
            width: 100%;
            margin-bottom: 1rem;
        }
        @include media-breakpoint-up(lg) {
            &:last-child {
                text-align: right;
            }
        }
    }

    .list-horizontal {
        align-items: baseline;

        .list-item:not(:last-child) {
            margin-right: 1em;
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            margin-bottom: 0;

            .list-item {
                margin-right: 0;
                margin-bottom: .75rem;
            }
        }
    }
}

.footer-site-social {
    @include font-size($footer-site-social-font-size);
    color: $footer-site-social-color;

    @include hover-focus() {
        color: $footer-site-social-hover-color;
    }
}

.footer-site-logo {
    display: inline-block;
    width: $footer-site-logo-width;
    height: $footer-site-logo-height;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    @include media-breakpoint-up(lg) {
        width: $footer-site-lg-logo-width;
        height: $footer-site-lg-logo-height;
    }
}

.footer-site-title {
    @if $footer-site-title-visible {
        display: inline-block;
        //margin-right: .375rem;
        font-family: $footer-site-title-font-family;
        @include font-size($footer-site-title-font-size);
        font-weight: $footer-site-title-font-weight;
    } @else {
        @include sr-only();
    }
}

.footer-site-tagline {
    @if $footer-site-tagline-visible {
        display: inline-block;
        font-family: $footer-site-tagline-font-family;
        @include font-size($footer-site-tagline-font-size);
        font-weight: $footer-site-tagline-font-weight;
        color: $footer-site-tagline-color;
    } @else {
        @include sr-only();
    }
}


// CAST Footer

// Settings
// =====
$footer-cast-padding:               1rem 0 !default;
$footer-cast-bg:                    #301c5c;
$footer-cast-color:                 #fff;
$footer-cast-border-width:          0 !default;
$footer-cast-border-color:          #fff;

$footer-cast-link-color:            #b5cc52;
$footer-cast-link-decoration:       underline !default;
$footer-cast-link-underline-pos:    auto !default;
$footer-cast-link-hover-color:      #fff !default;
$footer-cast-link-hover-decoration: underline !default;

$footer-cast-brand-margin-bottom:   .5rem !default;
$footer-cast-logo-width:            18rem !default;
$footer-cast-logo-height:           1.75rem !default;
$footer-cast-lg-logo-width:         22.75rem !default;
$footer-cast-lg-logo-height:        1.875rem !default;

$footer-cast-title-visible:         false !default;
$footer-cast-tagline-visible:       false !default;

$footer-cast-row-margin-bottom:     1rem !default;
$footer-cast-row-margin-x:          0 !default;
$footer-cast-row-gutter-width:      2rem !default;
$footer-cast-col-margin-y:          1rem !default;

$footer-cast-divider-width:         1px !default;
$footer-cast-divider-color:         #fff;

$footer-cast-header-margin-y:       .25rem !default;
$footer-cast-header-font-size:      1rem !default;
$footer-cast-header-font-weight:    $font-weight-bold !default;

$footer-cast-list-margin-bottom:    .25rem !default;
$footer-cast-list-item-margin-y:    .75rem !default;
$footer-cast-list-line-height:      1.25 !default;
$footer-cast-list-object-margin-x:  .75rem !default;

$footer-cast-media-color:           #f1f1f1;
$footer-cast-media-font-size:       .875rem !default;
$footer-cast-media-object-width:    6rem !default;
$footer-cast-media-object-margin-x: 1rem !default;

$footer-cast-social-font-size:      1.5rem !default;
$footer-cast-social-color:          #84d4e5 !default;
$footer-cast-social-hover-color:    #fff !default;

$footer-cast-copy-font-size:        .875rem !default;


// Donate button
// =====
$footer-cast-btn-donate: (
    "base": #b5cc52,
    "bg": #b5cc52,
    "color": #212121,
    "border-color": #b5cc52,
    "hover-bg": palette(#b5cc52, 350),
    "hover-color": #212121,
    "hover-border-color": palette(#b5cc52, 400),
    "active-bg": palette(#b5cc52, 300),
    "active-color": #212121,
    "active-border-color": palette(#b5cc52, 350)
);

// Footer rules
// =====
.footer-cast {
    position: relative;
    z-index: 10;
    padding: $footer-cast-padding;
    clear: both;
    color: $footer-cast-color;
    background-color: $footer-cast-bg;
    border-top: $footer-cast-border-width solid $footer-cast-border-color;

    a:not(.btn) {
        color: $footer-cast-link-color;
        text-decoration: $footer-cast-link-decoration;
        text-underline-position: $footer-cast-link-underline-pos;

        @include hover-focus() {
            color: $footer-cast-link-hover-color;
            text-decoration: $footer-cast-link-hover-decoration;
        }
    }
}

.footer-cast-brand {
    margin-bottom: $footer-cast-brand-margin-bottom;
}

.footer-cast-row {
    margin: 0 $footer-cast-row-margin-x $footer-cast-row-margin-bottom;
    border-top: $footer-cast-divider-width solid $footer-cast-divider-color;
    border-bottom: $footer-cast-divider-width solid $footer-cast-divider-color;

    & + .footer-row {
        margin-top: -$footer-cast-row-margin-bottom;
        border-top: 0;
    }

    .list-unstyled {
        margin-bottom: $footer-cast-list-margin-bottom;

        li {
            margin-bottom: $footer-cast-list-item-margin-y;
            line-height: $footer-cast-list-line-height;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .media-object {
            margin-right: $footer-cast-list-object-margin-x;
        }
    }

    > .col {
        margin-top: $footer-cast-col-margin-y;
        margin-bottom: $footer-cast-col-margin-y;
        border-right: $footer-cast-divider-width solid $footer-cast-divider-color;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        > .col {
            flex-basis: 100%;
            max-width: 100%;
            padding-right: 0;
            padding-left: 0;
            border-right: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .col {
            padding-right: ($footer-cast-row-gutter-width / 2);
            padding-left: ($footer-cast-row-gutter-width / 2);
        }
    }

    @include media-breakpoint-only(md) {
        > .col {
            flex-basis: 50%;
            max-width: 50%;

            & + .col:nth-child(odd) {
                padding-left: 0;
            }
            & + .col:nth-child(even) {
                padding-right: 0;
                border-right: 0;
            }
        }
    }
}

.footer-cast-header {
    margin-bottom: $footer-cast-header-margin-y;
    @include font-size($footer-cast-header-font-size);
    font-weight: $footer-cast-header-font-weight;
}

.footer-cast-media {
    color: $footer-cast-media-color;

    .media-object {
        width: $footer-cast-media-object-width;
        max-width: $footer-cast-media-object-width;
        margin-right: $footer-cast-media-object-margin-x;
    }
    .media-body {
        @include font-size( $footer-cast-media-font-size);

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.footer-cast-copy {
    align-items: baseline;
    justify-content: space-between;
    @include font-size($footer-cast-copy-font-size);

    > .col {
        @include media-breakpoint-down(md) {
            flex-basis: 100%;
            width: 100%;
            margin-bottom: 1rem;
        }
        @include media-breakpoint-up(lg) {
            &:last-child {
                text-align: right;
            }
        }
    }

    .list-horizontal {
        align-items: baseline;

        .list-item:not(:last-child) {
            margin-right: 1em;
        }

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            margin-bottom: 0;

            .list-item {
                margin-right: 0;
                margin-bottom: .75rem;
            }
        }
    }
}

.footer-cast-social {
    @include font-size($footer-cast-social-font-size);
    color: $footer-cast-social-color;

    @include hover-focus() {
        color: $footer-cast-social-hover-color;
    }
}

// Donation button
@include button-variant-control(".footer-cast-btn-donate", $footer-cast-btn-donate);

.footer-cast-logo {
    display: inline-block;
    width: $footer-cast-logo-width;
    height: $footer-cast-logo-height;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    @include media-breakpoint-up(lg) {
        width: $footer-cast-lg-logo-width;
        height: $footer-cast-lg-logo-height;
    }
}

.footer-cast-title {
    @if not $footer-cast-title-visible {
        @include sr-only();
    }
}

.footer-cast-tagline {
    @if not $footer-cast-tagline-visible {
        @include sr-only();
    }
}
