@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Open+Sans:wght@400;700&display=swap");
@import url("https://use.fontawesome.com/releases/v6.1.1/css/all.css");
:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 36em;
  --breakpoint-md: 48em;
  --breakpoint-lg: 62em;
  --breakpoint-xl: 75em;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3d3d3d;
  background-color: #d4eef4;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  line-height: 1.25;
}

h1, .h1 {
  font-size: 1.5rem;
}

h2, .h2 {
  font-size: 1rem;
}

h3, .h3 {
  font-size: 0.875rem;
}

h4, .h4 {
  font-size: 0.875rem;
}

h5, .h5 {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-cfw-tooltip-original-title],
abbr[data-cfw-popover-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark {
  padding: 0.125em 0.25em;
  color: #3d3d3d;
  background-color: #ff0;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #074a7b;
  text-decoration: underline;
}

a:hover {
  color: #00294a;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #5e7182;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
th,
td {
  border: 0 solid;
  border-color: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: normal;
}

legend + * {
  clear: left;
}

progress {
  vertical-align: baseline;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.4375rem + 2px);
  padding: 0.21875rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #424f5b;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #bfc6cd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 10ch;
  margin: 0;
}

.form-control:focus {
  color: #424f5b;
  background-color: #fff;
  border-color: #668ba9;
  outline: 0;
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control::placeholder {
  color: #999;
  opacity: 1;
}

.form-control:disabled {
  color: #9eaab4;
  background-color: #eff1f3;
  opacity: 1;
}

select.form-control, .form-control[role="combobox"] {
  padding-right: calc(0.75rem + 1.125em);
  background-color: #fff;
  background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='rgba%2866, 79, 91, 0.85%29' d='M0 0l4 4 4-4h-8z' transform='translate%280 2%29'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.375em center;
  background-size: 0.75em 0.75em;
}

select.form-control:disabled, .form-control:disabled[role="combobox"] {
  pointer-events: none;
}

select.form-control[size]:not([size="1"]), .form-control[size]:not([size="1"])[role="combobox"], select.form-control[multiple], .form-control[multiple][role="combobox"] {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

select.form-control:focus::-ms-value, .form-control[role="combobox"]:focus::-ms-value {
  color: #424f5b;
  background-color: #fff;
}

select.form-control::-ms-expand, .form-control[role="combobox"]::-ms-expand {
  display: none;
}

select.form-control:-moz-focusring, .form-control:-moz-focusring[role="combobox"] {
  color: transparent;
  text-shadow: 0 0 0 #424f5b;
}

textarea.form-control {
  min-height: calc(1.5em + 0.4375rem + 2px);
}

textarea.form-control:not([rows="1"]) {
  height: auto;
}

.form-label {
  padding-top: calc(0.21875rem + 1px);
  padding-bottom: calc(0.21875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
}

.form-check {
  display: block;
  min-height: calc(1.5 * 1em);
  padding-left: 1.25em;
  margin-bottom: 0.25rem;
}

.form-check > input {
  margin-left: -1.25em;
}

.form-check > input[disabled] ~ .form-check-label, .form-check > input:disabled ~ .form-check-label {
  opacity: 0.6;
}

.form-check > input[disabled] ~ .form-check-label::before,
.form-check > input[disabled] ~ .form-check-label::after, .form-check > input:disabled ~ .form-check-label::before,
.form-check > input:disabled ~ .form-check-label::after {
  opacity: .999;
}

.form-check-label {
  display: inline;
  margin-bottom: 0;
}

.form-checkradio .form-check-input {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.form-checkradio {
  padding-left: 1.375em;
}

.form-checkradio .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: calc(((1em * 1.5) - 1em) * .5);
  margin-left: -1.375em;
}

.form-checkradio .form-check-label::before {
  display: inline-block;
  float: left;
  width: 1em;
  height: 1em;
  margin-top: calc(((1em * 1.5) - 1em) * .5);
  margin-left: -1.375em;
  content: "";
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.625em;
  border: 1px solid #9eaab4;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}

@media (prefers-reduced-motion: reduce) {
  .form-checkradio .form-check-label::before {
    transition: none;
  }
}

.form-checkradio .form-check-input[type="checkbox"] ~ .form-check-label::before {
  border-radius: 0.25rem;
}

.form-checkradio .form-check-input[type="radio"] ~ .form-check-label::before {
  border-radius: 50%;
}

.form-checkradio .form-check-input:active:not([disabled]):not(:disabled) ~ .form-check-label::before {
  filter: brightness(92.5%);
}

.form-checkradio .form-check-input:focus ~ .form-check-label::before {
  border-color: #668ba9;
  outline: 0;
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-checkradio .form-check-input:checked ~ .form-check-label::before {
  background-color: #003d6f;
  border-color: #003d6f;
}

.form-checkradio .form-check-input[type="checkbox"]:checked ~ .form-check-label::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.41 1l-.69.72L2.94 4.5l-.81-.78L1.41 3 0 4.41l.72.72 1.5 1.5.69.72.72-.72 3.5-3.5.72-.72L6.41 1z'/%3e%3c/svg%3e");
}

.form-checkradio .form-check-input[type="radio"]:checked ~ .form-check-label::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle fill='%23fff' r='3'/%3e%3c/svg%3e");
}

.form-checkradio .form-check-input[type="checkbox"]:indeterminate ~ .form-check-label::before {
  background-color: #003d6f;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
  border-color: #003d6f;
}

.form-file {
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.4375rem + 2px);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus ~ .form-file-label {
  color: #424f5b;
  background-color: #fff;
  border-color: #668ba9;
  outline: 0;
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-file-input:focus-within ~ .form-file-label {
  color: #424f5b;
  background-color: #fff;
  border-color: #668ba9;
  outline: 0;
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-file-input:hover:not([disabled]):not(:disabled):not([readonly]) ~ .form-file-label .form-file-button {
  color: #50606f;
  background-color: #dfe3e6;
}

.form-file-input[disabled] ~ .form-file-label, .form-file-input:disabled ~ .form-file-label {
  color: #9eaab4;
  background-color: #eff1f3;
}

.form-file-input[disabled] ~ .form-file-label .form-file-button, .form-file-input:disabled ~ .form-file-label .form-file-button {
  color: #9eaab4;
  background-color: #eff1f3;
  opacity: 1;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: calc(1.5em + 0.4375rem + 2px);
  color: #424f5b;
  background-color: #fff;
  border-color: #bfc6cd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-file-label {
    transition: none;
  }
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.21875rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid;
  border-color: inherit;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.21875rem 0.75rem;
  margin-left: -1px;
  font-weight: 700;
  color: #50606f;
  background-color: #eff1f3;
  border: 1px solid;
  border-color: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-range {
  width: 100%;
  min-width: 8rem;
  height: calc(1.125em + 0.375rem);
  padding: 0;
  margin: 0;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 0.1875rem rgba(0, 61, 111, 0.35);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-ms-tooltip {
  display: none;
}

.form-range::-webkit-slider-runnable-track {
  box-sizing: border-box;
  width: 100%;
  height: 0.5em;
  margin: 0 0.1875rem;
  color: transparent;
  cursor: pointer;
  border: 0;
  border-radius: 0.5em;
  background-color: #dfe3e6;
}

.form-range::-moz-range-track {
  box-sizing: border-box;
  width: 100%;
  height: 0.5em;
  margin: 0 0.1875rem;
  color: transparent;
  cursor: pointer;
  border: 0;
  border-radius: 0.5em;
  background-color: #dfe3e6;
}

.form-range::-ms-track {
  box-sizing: border-box;
  width: 100%;
  height: 0.5em;
  margin: 0 0.1875rem;
  color: transparent;
  cursor: pointer;
  border: 0;
  border-radius: 0.5em;
  background-color: transparent;
}

.form-range::-ms-fill-lower {
  background-color: #dfe3e6;
  border-radius: 0.5em;
}

.form-range::-ms-fill-upper {
  margin-right: 0.5625em;
  background-color: #dfe3e6;
  border-radius: 0.5em;
}

.form-range::-webkit-slider-thumb {
  box-sizing: border-box;
  width: 1.125em;
  height: 1.125em;
  -webkit-appearance: none;
          appearance: none;
  background-color: #003d6f;
  border: 0;
  border-radius: 50%;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: -0.3125em;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #00345e;
}

.form-range::-moz-range-thumb {
  box-sizing: border-box;
  width: 1.125em;
  height: 1.125em;
  -moz-appearance: none;
       appearance: none;
  background-color: #003d6f;
  border: 0;
  border-radius: 50%;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #00345e;
}

.form-range::-ms-thumb {
  box-sizing: border-box;
  width: 1.125em;
  height: 1.125em;
  appearance: none;
  background-color: #003d6f;
  border: 0;
  border-radius: 50%;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 0;
  margin-right: 0.1875rem;
  margin-left: 0.1875rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #00345e;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #9eaab4;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #9eaab4;
}

.form-range:disabled::-ms-thumb {
  background-color: #9eaab4;
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check,
.form-inline .form-group {
  width: 100%;
}

@media (min-width: 36em) {
  .form-inline label:not(.form-check-label) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    width: auto;
    margin-bottom: 0;
  }
  .form-inline .form-control, .form-inline .form-range {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }
  .form-inline .form-check > input {
    margin-right: 0.25em;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.21875rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:focus {
  outline: 0;
}

.btn.disabled,
.btn:disabled {
  pointer-events: none;
  opacity: 0.6;
}

fieldset:disabled a.btn {
  pointer-events: none;
}

.btn {
  color: #5e7182;
  background-color: #fff;
  border-color: #9eaab4;
}

.btn:hover {
  color: #50606f;
  background-color: #eff1f3;
  border-color: #7e8d9b;
}

.btn:focus {
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.btn.disabled,
.btn:disabled {
  color: #5e7182;
  background-color: #fff;
  border-color: #9eaab4;
}

.btn:active,
.btn.active,
.btn.open[data-cfw="dropdown"] {
  color: #262d34;
  background-color: #bfc6cd;
  border-color: #7e8d9b;
}

.btn-primary {
  color: #fff;
  background-color: #003d6f;
  border-color: transparent;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00294a;
  border-color: transparent;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #003d6f;
  border-color: transparent;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary.open[data-cfw="dropdown"] {
  color: #fff;
  background-color: #00294a;
  border-color: transparent;
}

.btn-success {
  color: #3d3d3d;
  background-color: #a3cd4a;
  border-color: transparent;
}

.btn-success:hover {
  color: #fff;
  background-color: #607f2f;
  border-color: transparent;
}

.btn-success:focus {
  box-shadow: 0 0 0 0.125rem #a3cd4a;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #3d3d3d;
  background-color: #a3cd4a;
  border-color: transparent;
}

.btn-success:active,
.btn-success.active,
.btn-success.open[data-cfw="dropdown"] {
  color: #fff;
  background-color: #607f2f;
  border-color: transparent;
}

.btn-secondary {
  color: #3d3d3d;
  background-color: #bfc6cd;
  border-color: transparent;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5e7182;
  border-color: transparent;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.125rem #5e7182;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #3d3d3d;
  background-color: #bfc6cd;
  border-color: transparent;
}

.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.open[data-cfw="dropdown"] {
  color: #fff;
  background-color: #5e7182;
  border-color: transparent;
}

.btn-danger {
  color: #1f252a;
  background-color: #ff483b;
  border-color: transparent;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ab3028;
  border-color: transparent;
}

.btn-danger:focus {
  box-shadow: 0 0 0 0.125rem #ff483b;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #1f252a;
  background-color: #ff483b;
  border-color: transparent;
}

.btn-danger:active,
.btn-danger.active,
.btn-danger.open[data-cfw="dropdown"] {
  color: #fff;
  background-color: #ab3028;
  border-color: transparent;
}

.btn-tts {
  color: #fff;
  background-color: #de7801;
  border-color: transparent;
}

.btn-tts:hover {
  color: #de7801;
  background-color: #fff;
  border-color: transparent;
}

.btn-tts:focus {
  box-shadow: 0 0 0 0.125rem #de7801;
}

.btn-tts.disabled,
.btn-tts:disabled {
  color: #fff;
  background-color: #de7801;
  border-color: transparent;
}

.btn-tts:active,
.btn-tts.active,
.btn-tts.open[data-cfw="dropdown"] {
  color: #9b5401;
  background-color: #fff;
  border-color: transparent;
}

.btn-support {
  color: #fff;
  background-color: #de7801;
  border-color: transparent;
}

.btn-support:hover {
  color: #de7801;
  background-color: #fff;
  border-color: transparent;
}

.btn-support:focus {
  box-shadow: 0 0 0 0.125rem #de7801;
}

.btn-support.disabled,
.btn-support:disabled {
  color: #fff;
  background-color: #de7801;
  border-color: transparent;
}

.btn-support:active,
.btn-support.active,
.btn-support.open[data-cfw="dropdown"] {
  color: #9b5401;
  background-color: #fff;
  border-color: transparent;
}

.btn-link {
  color: #074a7b;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover, .btn-link:focus {
  background-color: transparent;
  border-color: transparent;
}

.btn-link:hover {
  color: #00294a;
}

.btn-link:focus {
  box-shadow: 0 0 0 0.125rem #074a7b;
}

.btn-link.disabled, .btn-link:disabled {
  color: #074a7b;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:active, .btn-link.active, .btn-link.open[data-cfw="dropdown"] {
  color: #00294a;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:active:focus, .btn-link.active:focus, .btn-link.open[data-cfw="dropdown"]:focus {
  box-shadow: 0 0 0 0.125rem #074a7b;
}

.btn-icon {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-small {
  padding: 0.1875rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.btn-small.btn-icon {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  max-width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 36em) {
  .container, .container-sm {
    max-width: 34rem;
  }
}

@media (min-width: 48em) {
  .container, .container-sm, .container-md {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 72rem;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row > * {
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.g-0,
.gx-0 {
  margin-right: 0;
  margin-left: 0;
}

.g-0 > *,
.gx-0 > * {
  padding-right: 0;
  padding-left: 0;
}

.g-0,
.gy-0 {
  margin-top: 0;
}

.g-0 > *,
.gy-0 > * {
  margin-top: 0;
}

.g-0_5,
.gx-0_5 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.g-0_5 > *,
.gx-0_5 > * {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.g-0_5,
.gy-0_5 {
  margin-top: -0.5rem;
}

.g-0_5 > *,
.gy-0_5 > * {
  margin-top: 0.5rem;
}

.g-1,
.gx-1 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.g-1 > *,
.gx-1 > * {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.g-1,
.gy-1 {
  margin-top: -1rem;
}

.g-1 > *,
.gy-1 > * {
  margin-top: 1rem;
}

.g-1_5,
.gx-1_5 {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.g-1_5 > *,
.gx-1_5 > * {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.g-1_5,
.gy-1_5 {
  margin-top: -1.5rem;
}

.g-1_5 > *,
.gy-1_5 > * {
  margin-top: 1.5rem;
}

.g-2,
.gx-2 {
  margin-right: -1rem;
  margin-left: -1rem;
}

.g-2 > *,
.gx-2 > * {
  padding-right: 1rem;
  padding-left: 1rem;
}

.g-2,
.gy-2 {
  margin-top: -2rem;
}

.g-2 > *,
.gy-2 > * {
  margin-top: 2rem;
}

.g-2_5,
.gx-2_5 {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.g-2_5 > *,
.gx-2_5 > * {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.g-2_5,
.gy-2_5 {
  margin-top: -2.5rem;
}

.g-2_5 > *,
.gy-2_5 > * {
  margin-top: 2.5rem;
}

@media (min-width: 36em) {
  .g-sm-0,
  .gx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .g-sm-0 > *,
  .gx-sm-0 > * {
    padding-right: 0;
    padding-left: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    margin-top: 0;
  }
  .g-sm-0 > *,
  .gy-sm-0 > * {
    margin-top: 0;
  }
  .g-sm-0_5,
  .gx-sm-0_5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .g-sm-0_5 > *,
  .gx-sm-0_5 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .g-sm-0_5,
  .gy-sm-0_5 {
    margin-top: -0.5rem;
  }
  .g-sm-0_5 > *,
  .gy-sm-0_5 > * {
    margin-top: 0.5rem;
  }
  .g-sm-1,
  .gx-sm-1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .g-sm-1 > *,
  .gx-sm-1 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    margin-top: -1rem;
  }
  .g-sm-1 > *,
  .gy-sm-1 > * {
    margin-top: 1rem;
  }
  .g-sm-1_5,
  .gx-sm-1_5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .g-sm-1_5 > *,
  .gx-sm-1_5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .g-sm-1_5,
  .gy-sm-1_5 {
    margin-top: -1.5rem;
  }
  .g-sm-1_5 > *,
  .gy-sm-1_5 > * {
    margin-top: 1.5rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .g-sm-2 > *,
  .gx-sm-2 > * {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    margin-top: -2rem;
  }
  .g-sm-2 > *,
  .gy-sm-2 > * {
    margin-top: 2rem;
  }
  .g-sm-2_5,
  .gx-sm-2_5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .g-sm-2_5 > *,
  .gx-sm-2_5 > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .g-sm-2_5,
  .gy-sm-2_5 {
    margin-top: -2.5rem;
  }
  .g-sm-2_5 > *,
  .gy-sm-2_5 > * {
    margin-top: 2.5rem;
  }
}

@media (min-width: 48em) {
  .g-md-0,
  .gx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .g-md-0 > *,
  .gx-md-0 > * {
    padding-right: 0;
    padding-left: 0;
  }
  .g-md-0,
  .gy-md-0 {
    margin-top: 0;
  }
  .g-md-0 > *,
  .gy-md-0 > * {
    margin-top: 0;
  }
  .g-md-0_5,
  .gx-md-0_5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .g-md-0_5 > *,
  .gx-md-0_5 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .g-md-0_5,
  .gy-md-0_5 {
    margin-top: -0.5rem;
  }
  .g-md-0_5 > *,
  .gy-md-0_5 > * {
    margin-top: 0.5rem;
  }
  .g-md-1,
  .gx-md-1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .g-md-1 > *,
  .gx-md-1 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .g-md-1,
  .gy-md-1 {
    margin-top: -1rem;
  }
  .g-md-1 > *,
  .gy-md-1 > * {
    margin-top: 1rem;
  }
  .g-md-1_5,
  .gx-md-1_5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .g-md-1_5 > *,
  .gx-md-1_5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .g-md-1_5,
  .gy-md-1_5 {
    margin-top: -1.5rem;
  }
  .g-md-1_5 > *,
  .gy-md-1_5 > * {
    margin-top: 1.5rem;
  }
  .g-md-2,
  .gx-md-2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .g-md-2 > *,
  .gx-md-2 > * {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .g-md-2,
  .gy-md-2 {
    margin-top: -2rem;
  }
  .g-md-2 > *,
  .gy-md-2 > * {
    margin-top: 2rem;
  }
  .g-md-2_5,
  .gx-md-2_5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .g-md-2_5 > *,
  .gx-md-2_5 > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .g-md-2_5,
  .gy-md-2_5 {
    margin-top: -2.5rem;
  }
  .g-md-2_5 > *,
  .gy-md-2_5 > * {
    margin-top: 2.5rem;
  }
}

@media (min-width: 62em) {
  .g-lg-0,
  .gx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .g-lg-0 > *,
  .gx-lg-0 > * {
    padding-right: 0;
    padding-left: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    margin-top: 0;
  }
  .g-lg-0 > *,
  .gy-lg-0 > * {
    margin-top: 0;
  }
  .g-lg-0_5,
  .gx-lg-0_5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .g-lg-0_5 > *,
  .gx-lg-0_5 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .g-lg-0_5,
  .gy-lg-0_5 {
    margin-top: -0.5rem;
  }
  .g-lg-0_5 > *,
  .gy-lg-0_5 > * {
    margin-top: 0.5rem;
  }
  .g-lg-1,
  .gx-lg-1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .g-lg-1 > *,
  .gx-lg-1 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    margin-top: -1rem;
  }
  .g-lg-1 > *,
  .gy-lg-1 > * {
    margin-top: 1rem;
  }
  .g-lg-1_5,
  .gx-lg-1_5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .g-lg-1_5 > *,
  .gx-lg-1_5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .g-lg-1_5,
  .gy-lg-1_5 {
    margin-top: -1.5rem;
  }
  .g-lg-1_5 > *,
  .gy-lg-1_5 > * {
    margin-top: 1.5rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .g-lg-2 > *,
  .gx-lg-2 > * {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    margin-top: -2rem;
  }
  .g-lg-2 > *,
  .gy-lg-2 > * {
    margin-top: 2rem;
  }
  .g-lg-2_5,
  .gx-lg-2_5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .g-lg-2_5 > *,
  .gx-lg-2_5 > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .g-lg-2_5,
  .gy-lg-2_5 {
    margin-top: -2.5rem;
  }
  .g-lg-2_5 > *,
  .gy-lg-2_5 > * {
    margin-top: 2.5rem;
  }
}

@media (min-width: 75em) {
  .g-xl-0,
  .gx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .g-xl-0 > *,
  .gx-xl-0 > * {
    padding-right: 0;
    padding-left: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    margin-top: 0;
  }
  .g-xl-0 > *,
  .gy-xl-0 > * {
    margin-top: 0;
  }
  .g-xl-0_5,
  .gx-xl-0_5 {
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .g-xl-0_5 > *,
  .gx-xl-0_5 > * {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .g-xl-0_5,
  .gy-xl-0_5 {
    margin-top: -0.5rem;
  }
  .g-xl-0_5 > *,
  .gy-xl-0_5 > * {
    margin-top: 0.5rem;
  }
  .g-xl-1,
  .gx-xl-1 {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .g-xl-1 > *,
  .gx-xl-1 > * {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    margin-top: -1rem;
  }
  .g-xl-1 > *,
  .gy-xl-1 > * {
    margin-top: 1rem;
  }
  .g-xl-1_5,
  .gx-xl-1_5 {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .g-xl-1_5 > *,
  .gx-xl-1_5 > * {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .g-xl-1_5,
  .gy-xl-1_5 {
    margin-top: -1.5rem;
  }
  .g-xl-1_5 > *,
  .gy-xl-1_5 > * {
    margin-top: 1.5rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .g-xl-2 > *,
  .gx-xl-2 > * {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    margin-top: -2rem;
  }
  .g-xl-2 > *,
  .gy-xl-2 > * {
    margin-top: 2rem;
  }
  .g-xl-2_5,
  .gx-xl-2_5 {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
  .g-xl-2_5 > *,
  .gx-xl-2_5 > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .g-xl-2_5,
  .gy-xl-2_5 {
    margin-top: -2.5rem;
  }
  .g-xl-2_5 > *,
  .gy-xl-2_5 > * {
    margin-top: 2.5rem;
  }
}

.col {
  flex: 1 0;
}

@media (min-width: 36em) {
  .col-sm {
    flex: 1 0;
  }
}

@media (min-width: 48em) {
  .col-md {
    flex: 1 0;
  }
}

@media (min-width: 62em) {
  .col-lg {
    flex: 1 0;
  }
}

@media (min-width: 75em) {
  .col-xl {
    flex: 1 0;
  }
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.666667%;
}

@media (min-width: 36em) {
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
}

@media (min-width: 48em) {
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
}

@media (min-width: 62em) {
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
}

@media (min-width: 75em) {
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.666667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  width: 8.333333%;
}

.col-2 {
  width: 16.666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.333333%;
}

.col-5 {
  width: 41.666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.333333%;
}

.col-8 {
  width: 66.666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.333333%;
}

.col-11 {
  width: 91.666667%;
}

.col-12 {
  width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 36em) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    width: 8.333333%;
  }
  .col-sm-2 {
    width: 16.666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.333333%;
  }
  .col-sm-5 {
    width: 41.666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.333333%;
  }
  .col-sm-8 {
    width: 66.666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.333333%;
  }
  .col-sm-11 {
    width: 91.666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 48em) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    width: 8.333333%;
  }
  .col-md-2 {
    width: 16.666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.333333%;
  }
  .col-md-5 {
    width: 41.666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.333333%;
  }
  .col-md-8 {
    width: 66.666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.333333%;
  }
  .col-md-11 {
    width: 91.666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 62em) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    width: 8.333333%;
  }
  .col-lg-2 {
    width: 16.666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.333333%;
  }
  .col-lg-5 {
    width: 41.666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.333333%;
  }
  .col-lg-8 {
    width: 66.666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.333333%;
  }
  .col-lg-11 {
    width: 91.666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 75em) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    width: 8.333333%;
  }
  .col-xl-2 {
    width: 16.666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.333333%;
  }
  .col-xl-5 {
    width: 41.666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.333333%;
  }
  .col-xl-8 {
    width: 66.666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.333333%;
  }
  .col-xl-11 {
    width: 91.666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.lazy {
  opacity: 0;
}

.lazy.in {
  opacity: 1;
  transition: opacity 0.3s linear;
}

@media (prefers-reduced-motion: reduce) {
  .lazy.in {
    transition: none;
  }
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade.in {
  opacity: 1;
}

.collapse:not(.in) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.3s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.caret, .caretup, .caretstart, .caretend {
  display: inline-block;
  width: 0.75rem;
  text-align: center;
}

.caret::after, .caretup::after, .caretstart::after, .caretend::after {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
}

.caret::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0;
  border-left: 0.3125rem solid transparent;
}

.open > .caret::after {
  margin-top: -0.15625rem;
  border-top: 0;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0.3125rem solid;
  border-left: 0.3125rem solid transparent;
}

.caretup::after {
  margin-top: -0.15625rem;
  border-top: 0;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0.3125rem solid;
  border-left: 0.3125rem solid transparent;
}

.open > .caretup::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0;
  border-left: 0.3125rem solid transparent;
}

.caretstart::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid transparent;
  border-right: 0.3125rem solid;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0;
}

.open > .caretstart::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid transparent;
  border-right: 0;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.3125rem solid;
}

.caretend::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid transparent;
  border-right: 0;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.3125rem solid;
}

.open > .caretend::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid transparent;
  border-right: 0.3125rem solid;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0;
}

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: inherit;
  text-decoration: none;
  opacity: 0.65;
}

.close:hover, .close:focus {
  color: inherit;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.85;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.list {
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1rem;
  border-color: #bfc6cd;
  padding-left: 0;
  list-style: none;
}

.list .list {
  padding: 0;
  margin-bottom: 0;
  margin-left: 1.25rem;
}

.list-marker-inner {
  margin-left: 0;
}

.list-marker-inner > .list-item::before {
  position: static;
  margin-left: 0;
}

.list-divided > .list-item {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.list-divided > .list-item + .list-item {
  border-top-width: 0;
}

.list-divided > .list-item + .list-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-divided > .list-item:first-child {
  border-top: 0;
}

.list-divided > .list-item:last-child {
  border-bottom: 0;
}

.list-spaced > .list-item {
  padding: 0.5rem 1rem;
}

.list-horizontal {
  flex-direction: row;
  padding-left: 0;
}

.list-horizontal > .list-item:not(:last-child) {
  padding-right: 0.5em;
}

.list-horizontal.list-divided > .list-item {
  padding-right: 0.5em;
  padding-left: 0.5em;
  margin-top: 0;
  margin-left: -1px;
  border-width: 0 1px;
}

.list-horizontal.list-divided > .list-item + .list-item {
  margin-top: 0;
}

.list-horizontal.list-divided > .list-item + .list-item.active {
  margin-left: -1px;
  border-top-width: 0;
  border-left-width: 1px;
}

.list-horizontal.list-divided > .list-item:first-child {
  padding-left: 0;
  border-left: 0;
}

.list-horizontal.list-divided > .list-item:last-child {
  padding-right: 0;
  border-right: 0;
}

.list-horizontal.list-spaced > .list-item {
  padding: 0.5rem 1rem;
}

.list-item {
  position: relative;
  margin-bottom: 0;
  background-color: transparent;
  border: 0 solid;
  border-color: inherit;
}

.list-item.disabled, .list-item:disabled {
  color: #9eaab4;
  text-decoration: none;
  pointer-events: none;
  cursor: default;
  background-color: transparent;
}

.list-item.active {
  z-index: 1;
  color: #fff;
  background-color: #003d6f;
  border-color: #00345e;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  padding-left: 0;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.25rem;
  color: inherit;
  content: ">";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: inherit;
}

.pagination {
  display: flex;
  padding-left: 0;
  margin-bottom: 1rem;
  list-style: none;
}

.page-item {
  display: flex;
}

.page-text,
.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 2.25em;
  padding: 0.21875rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border: 0 solid #003d6f;
  border-radius: 0.5rem;
}

.page-link {
  position: relative;
  color: #fff;
  text-decoration: none;
  background-color: #003d6f;
}

.page-link:hover, .page-link:focus {
  z-index: 2;
  color: #fff;
  background-color: #00294a;
  border-color: #00294a;
}

.page-link.active,
.active > .page-link {
  z-index: 1;
  color: #003d6f;
  background-color: #d4eef4;
  border-color: #d4eef4;
}

.page-link.disabled,
.disabled > .page-link {
  color: #fff;
  pointer-events: none;
  background-color: #003d6f;
}

.pagination-group .page-text,
.pagination-group .page-link {
  border-width: 2px;
}

.pagination-group .page-item {
  margin: 0;
}

.pagination-group .page-item:not(:first-child) .page-text,
.pagination-group .page-item:not(:first-child) .page-link {
  margin-left: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-group .page-item:not(:last-child) .page-text,
.pagination-group .page-item:not(:last-child) .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown,
.dropdown-submenu {
  position: relative;
}

.dropdown-container {
  position: absolute;
  pointer-events: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.3125rem 0;
  margin: 0.125rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #3d3d3d;
  text-align: left;
  list-style: none;
  pointer-events: auto;
  background-color: #d4eef4;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.5rem;
}

.dropdown-menu.open {
  display: block;
}

.dropdown-submenu > .dropdown-menu {
  margin-top: -0.4375rem;
}

.dropdown-submenu > a::after,
.dropdown-submenu > .dropdown-item::after {
  position: absolute;
  top: calc(1.5 * 1rem * .5);
  right: 0.375rem;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid transparent;
  border-right: 0;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.3125rem solid  #7e8d9b;
}

.dropdown-submenu > a.active:not(:hover):not(:focus)::after,
.dropdown-submenu > .dropdown-item.active:not(:hover):not(:focus)::after {
  margin-top: -0.15625rem;
  border-top: 0.3125rem solid transparent;
  border-right: 0;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.3125rem solid  #fff;
}

.dropreverse {
  right: 0;
  left: auto;
}

.dropdown-menu a,
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.125rem 1.125rem;
  margin: 0;
  clear: both;
  font-weight: 400;
  color: #424f5b;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-menu a.active,
.dropdown-item.active {
  color: #fff;
  background-color: #00294a;
}

.dropdown-menu a:hover, .dropdown-menu a:focus,
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: #003d6f;
}

.dropdown-menu a.disabled, .dropdown-menu a:disabled,
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #9eaab4;
  text-decoration: none;
  pointer-events: none;
  background-color: transparent;
  background-image: none;
}

.dropdown-text {
  display: block;
  padding: 0.125rem 1.125rem;
  color: #3d3d3d;
}

.dropdown-divider {
  height: 0;
  margin: 0.3125rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(38, 45, 52, 0.3);
  opacity: 1;
}

.dropdown-subalign-reverse,
.dropstart {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropdown-subalign-reverse {
  margin-right: 0;
}

.dropdown-subalign-forward,
.dropend {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropdown-subalign-forward {
  margin-left: 0;
}

.dropcenter {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.dropmiddle {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.dropup {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -0.4375rem;
}

.dropup.dropstart, .dropup.dropend {
  bottom: 0;
  margin-bottom: 0;
}

.dropdown-menu[x-placement],
.dropdown-submenu > .dropdown-menu[x-placement] {
  right: auto;
  bottom: auto;
  left: auto;
  transform: none;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.3125rem 1rem;
  color: #074a7b;
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #00294a;
}

.nav-link.disabled, .nav-link:disabled {
  color: #9eaab4;
  text-decoration: none;
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.nav-tabs {
  flex-flow: row wrap;
  border-bottom: 2px solid #d4eef4;
}

.nav-tabs .nav-link {
  margin-bottom: -2px;
  border: 2px solid transparent;
  border-radius: 0.5rem 0.5rem 0 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #fff;
  background-color: #00294a;
  border-color: #ffc74f #ffc74f #d4eef4;
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: #9eaab4;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.open {
  color: #003d6f;
  background-color: #d4eef4;
  border-color: #d4eef4 #d4eef4 #d4eef4;
}

.nav-tabs .dropdown-menu[x-placement^="bottom"] {
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
}

.navbar:not([class*="navbar-expand"]) .navbar-nav .dropdown-menu {
  position: static;
  margin: 0;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand > img {
  display: block;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  text-decoration: none;
}

.navbar-text {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.navbar-collapse {
  flex: 1 1 100%;
  align-items: center;
}

.navbar-toggle {
  padding: 0.21875rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggle {
    transition: none;
  }
}

.navbar-toggle:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1875rem;
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
  align-items: center;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggle {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

@media (max-width: 35.98em) {
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: static;
    margin: 0;
  }
}

@media (min-width: 36em) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggle {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (max-width: 47.98em) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: static;
    margin: 0;
  }
}

@media (min-width: 48em) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggle {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (max-width: 61.98em) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: static;
    margin: 0;
  }
}

@media (min-width: 62em) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggle {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (max-width: 74.98em) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: static;
    margin: 0;
  }
}

@media (min-width: 75em) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggle {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4375em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #5e7182;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.media {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.media .media {
  margin-top: 1rem;
}

.media .media:last-child {
  margin-bottom: 0;
}

.media-body {
  flex: 1 1;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1rem;
  word-wrap: break-word;
  background-color: #d4eef4;
  border: 1px solid transparent;
  border-radius: 0.5rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.75rem 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-header {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  background-color: #acdcf1;
  border-bottom: 1px solid transparent;
}

.card-header:first-child {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1rem;
  background-color: rgba(38, 45, 52, 0.0875);
  border-top: 1px solid rgba(38, 45, 52, 0.3);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  min-height: 1px;
}

.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}

.alert {
  position: relative;
  padding: 0.375rem 0.75rem;
  padding-right: 2.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.5rem;
}

.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 0.75rem;
}

.alert-success {
  color: #3d3d3d;
  background-color: #a3cd4a;
  border-color: transparent;
}

.alert-warning {
  color: #3d3d3d;
  background-color: #f6fea2;
  border-color: transparent;
}

.alert-danger {
  color: #1f252a;
  background-color: #ff483b;
  border-color: transparent;
}

@keyframes loader-spin-cw {
  to {
    transform: rotate(360deg);
  }
}

.loader-circle {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -1.5px;
}

.loader-circle::before, .loader-circle::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: inherit;
  height: inherit;
  content: "";
  border: 3px solid transparent;
  border-radius: 50%;
}

.loader-circle::after {
  border-left-color: currentcolor;
  animation: 1s linear infinite loader-spin-cw;
}

.loader-circle::before {
  border-color: currentcolor;
  opacity: 0.25;
}

@media (prefers-reduced-motion: reduce) {
  .loader-circle::after,
  .loader-double::after {
    animation-duration: 2s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1035;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  font-size: 1rem;
  word-wrap: break-word;
  visibility: hidden;
  background-color: #d4eef4;
  background-clip: padding-box;
  outline: 0;
}

.offcanvas-start.offcanvas {
  top: 0;
  left: 0;
  width: 17rem;
  border-right: 0 solid rgba(38, 45, 52, 0.4);
  transform: translateX(-100%);
}

.showing.offcanvas, .in.offcanvas:not(.hiding) {
  transform: none;
}

.showing.offcanvas, .in.hiding.offcanvas {
  transition: transform 0.3s linear;
}

@media (prefers-reduced-motion: reduce) {
  .showing.offcanvas, .in.hiding.offcanvas {
    transition: none;
  }
}

.showing.offcanvas, .hiding.offcanvas, .in.offcanvas {
  visibility: visible;
}

.offcanvas-open:not(body) .offcanvas {
  position: absolute;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  width: 100vw;
  height: 100vh;
  background-color: #1f252a;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.in {
  opacity: 0.5;
}

.offcanvas-open:not(body) .offcanvas-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
}

.offcanvas-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 0 solid rgba(38, 45, 52, 0.2);
}

.offcanvas-header .close {
  order: 1;
  padding: 0.75rem 0.75rem;
  margin: -0.75rem -1rem -0.75rem auto;
}

.offcanvas-title {
  margin: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 0.75rem 1rem;
  overflow-y: auto;
}

.offcanvas.offcanvas-blocked.in {
  transition: transform 0.15s linear;
  transform: scale(1.01);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas.offcanvas-blocked.in {
    transition: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1065;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  direction: ltr /*rtl:ignore*/;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: transform 0.15s linear;
  transform: translate(0, -3rem);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal-dialog {
  position: relative;
  display: flex;
  width: auto;
  margin: 0.625rem;
  direction: ltr;
  pointer-events: none;
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1.25rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1.25rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1.25rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1.25rem);
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal.in .modal-dialog {
  transform: none;
}

.modal.modal-blocked .modal-content {
  transition: transform 0.15s linear;
  transform: scale(1.01);
}

@media (prefers-reduced-motion: reduce) {
  .modal.modal-blocked .modal-content {
    transition: none;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  font-size: 1rem;
  word-wrap: break-word;
  pointer-events: auto;
  background-color: #d4eef4;
  background-clip: padding-box;
  border: 0 solid rgba(38, 45, 52, 0.4);
  border-radius: 0.375rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100vw;
  height: 100vh;
  background-color: #1f252a;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.in {
  opacity: 0.5;
}

.modal-open:not(body) .modal,
.modal-open:not(body) .modal-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 0 solid rgba(38, 45, 52, 0.2);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.modal-header .close {
  order: 1;
  padding: 0.75rem 0.75rem;
  margin: -0.75rem -1rem -0.75rem auto;
}

.modal-title {
  margin: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.75rem 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 1rem;
  border-top: 0 solid rgba(38, 45, 52, 0.2);
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

@media (min-width: 36em) {
  .modal-dialog {
    max-width: 33rem;
    margin: 1.875rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.75rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.75rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.75rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.75rem);
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 19rem;
  }
}

@media (min-width: 62em) {
  .modal-lg {
    max-width: 56rem;
  }
}

.tooltip {
  position: absolute;
  z-index: 1050;
  display: none;
  margin: 0.125rem;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  font-size: 0.875rem;
  word-wrap: break-word;
  outline: 0;
  opacity: 0;
}

.tooltip.in {
  opacity: 1;
}

.tooltip .close {
  position: static;
  float: right;
  padding: 0.125rem 0.375rem;
  color: #003d6f;
  font-size: 1.25rem;
  text-shadow: none;
  opacity: 1;
}

.tooltip .close:not(:disabled):not(.disabled):hover, .tooltip .close:not(:disabled):not(.disabled):focus {
  color: #00294a;
  opacity: 1;
}

.tooltip-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

/* rtl:begin:ignore */
.cfw-tooltip-top, .cfw-tooltip-auto[x-placement^="top"] {
  padding-bottom: 0.5rem;
}

.cfw-tooltip-top .tooltip-arrow, .cfw-tooltip-auto[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.cfw-tooltip-top .tooltip-arrow::before, .cfw-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #d4eef4;
}

.cfw-tooltip-right, .cfw-tooltip-auto[x-placement^="right"] {
  padding-left: 0.5rem;
}

.cfw-tooltip-right .tooltip-arrow, .cfw-tooltip-auto[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.5rem;
  height: 1rem;
}

.cfw-tooltip-right .tooltip-arrow::before, .cfw-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #d4eef4;
}

.cfw-tooltip-bottom, .cfw-tooltip-auto[x-placement^="bottom"] {
  padding-top: 0.5rem;
}

.cfw-tooltip-bottom .tooltip-arrow, .cfw-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.cfw-tooltip-bottom .tooltip-arrow::before, .cfw-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #d4eef4;
}

.cfw-tooltip-left, .cfw-tooltip-auto[x-placement^="left"] {
  padding-right: 0.5rem;
}

.cfw-tooltip-left .tooltip-arrow, .cfw-tooltip-auto[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.5rem;
  height: 1rem;
}

.cfw-tooltip-left .tooltip-arrow::before, .cfw-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #d4eef4;
}

/* rtl:end:ignore */
.tooltip-body {
  max-width: 13rem;
  padding: 0.25rem 0.5rem;
  color: #3d3d3d;
  text-align: center;
  background-color: #d4eef4;
  border-radius: 0.5rem;
}

.close + .tooltip-body {
  padding-right: 1.625rem;
}

.popover {
  position: absolute;
  z-index: 1040;
  display: none;
  max-width: 18rem;
  margin: 0.125rem;
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  font-size: 0.9375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(38, 45, 52, 0.4);
  border-radius: 0.3125rem;
  outline: 0;
}

.popover.in {
  opacity: 1;
}

.popover-arrow {
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 0.625rem;
  margin: 0 0.3125rem;
}

.popover-arrow::before, .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

/* rtl:begin:ignore */
.cfw-popover-top, .cfw-popover-auto[x-placement^="top"] {
  margin-bottom: 0.75rem;
}

.cfw-popover-top > .popover-arrow, .cfw-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: calc(-0.625rem - 1px);
}

.cfw-popover-top > .popover-arrow::before, .cfw-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.625rem 0.625rem 0;
  border-top-color: rgba(38, 45, 52, 0.4);
}

.cfw-popover-top > .popover-arrow::after, .cfw-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.625rem 0.625rem 0;
  border-top-color: #fff;
}

.cfw-popover-right, .cfw-popover-auto[x-placement^="right"] {
  margin-left: 0.75rem;
}

.cfw-popover-right > .popover-arrow, .cfw-popover-auto[x-placement^="right"] > .popover-arrow {
  left: calc(-0.625rem - 1px);
  width: 0.625rem;
  height: 1.25rem;
  margin: 0.3125rem 0;
}

.cfw-popover-right > .popover-arrow::before, .cfw-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.625rem 0.625rem 0.625rem 0;
  border-right-color: rgba(38, 45, 52, 0.4);
}

.cfw-popover-right > .popover-arrow::after, .cfw-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.625rem 0.625rem 0.625rem 0;
  border-right-color: #fff;
}

.cfw-popover-bottom, .cfw-popover-auto[x-placement^="bottom"] {
  margin-top: 0.75rem;
}

.cfw-popover-bottom > .popover-arrow, .cfw-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: calc(-0.625rem - 1px);
}

.cfw-popover-bottom > .popover-arrow::before, .cfw-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.625rem 0.625rem 0.625rem;
  border-bottom-color: rgba(38, 45, 52, 0.4);
}

.cfw-popover-bottom > .popover-arrow::after, .cfw-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.625rem 0.625rem 0.625rem;
  border-bottom-color: #fff;
}

.cfw-popover-left, .cfw-popover-auto[x-placement^="left"] {
  margin-right: 0.75rem;
}

.cfw-popover-left > .popover-arrow, .cfw-popover-auto[x-placement^="left"] > .popover-arrow {
  right: calc(-0.625rem - 1px);
  width: 0.625rem;
  height: 1.25rem;
  margin: 0.3125rem 0;
}

.cfw-popover-left > .popover-arrow::before, .cfw-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.625rem 0 0.625rem 0.625rem;
  border-left-color: rgba(38, 45, 52, 0.4);
}

.cfw-popover-left > .popover-arrow::after, .cfw-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.625rem 0 0.625rem 0.625rem;
  border-left-color: #fff;
}

/* rtl:end:ignore */
.popover-header {
  padding: 0.5rem 0.75rem;
  padding-right: 1.5rem;
  margin: 0;
  font-size: 1rem;
  background-color: rgba(38, 45, 52, 0.0875);
  border-bottom: 1px solid rgba(38, 45, 52, 0.3);
  border-radius: calc(0.3125rem - 1px) calc(0.3125rem - 1px) 0 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #3d3d3d;
}

.popover-body:empty {
  display: none;
}

.close ~ .popover-header:empty ~ .popover-body {
  padding-right: 1.8125rem;
}

.popover .close {
  float: right;
  padding: 0.125rem 0.3125rem;
}

.bg-primary {
  background-color: #ffc74f !important;
}

.bg-autofill {
  background-color: #acdcf1 !important;
}

.bg-unfilled {
  background-color: #f6fea2 !important;
}

.bg-active {
  background-color: #003d6f !important;
}

.bg-success {
  background-color: #a3cd4a !important;
}

.bg-warning {
  background-color: #f6fea2 !important;
}

.bg-danger {
  background-color: #ff483b !important;
}

.bg-body {
  background-color: #d4eef4 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.radius-circle {
  border-radius: 50% !important;
}

.radius {
  border-radius: 0.5rem;
}

.radius-t {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.radius-e {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.radius-b {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.radius-s {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.radius-te {
  border-top-right-radius: 0.5rem;
}

.radius-ts {
  border-top-left-radius: 0.5rem;
}

.radius-be {
  border-bottom-right-radius: 0.5rem;
}

.radius-bs {
  border-bottom-left-radius: 0.5rem;
}

.radius-0 {
  border-radius: 0 !important;
}

.radius-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.radius-e-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.radius-b-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-s-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-te-0 {
  border-top-right-radius: 0 !important;
}

.radius-ts-0 {
  border-top-left-radius: 0 !important;
}

.radius-be-0 {
  border-bottom-right-radius: 0 !important;
}

.radius-bs-0 {
  border-bottom-left-radius: 0 !important;
}

.border {
  border: 1px solid #bfc6cd !important;
}

.border-t,
.border-y {
  border-top: 1px solid #bfc6cd !important;
}

.border-e,
.border-x {
  border-right: 1px solid #bfc6cd !important;
}

.border-b,
.border-y {
  border-bottom: 1px solid #bfc6cd !important;
}

.border-s,
.border-x {
  border-left: 1px solid #bfc6cd !important;
}

@media (min-width: 36em) {
  .border-sm {
    border: 1px solid #bfc6cd !important;
  }
  .border-sm-t,
  .border-sm-y {
    border-top: 1px solid #bfc6cd !important;
  }
  .border-sm-e,
  .border-sm-x {
    border-right: 1px solid #bfc6cd !important;
  }
  .border-sm-b,
  .border-sm-y {
    border-bottom: 1px solid #bfc6cd !important;
  }
  .border-sm-s,
  .border-sm-x {
    border-left: 1px solid #bfc6cd !important;
  }
}

@media (min-width: 48em) {
  .border-md {
    border: 1px solid #bfc6cd !important;
  }
  .border-md-t,
  .border-md-y {
    border-top: 1px solid #bfc6cd !important;
  }
  .border-md-e,
  .border-md-x {
    border-right: 1px solid #bfc6cd !important;
  }
  .border-md-b,
  .border-md-y {
    border-bottom: 1px solid #bfc6cd !important;
  }
  .border-md-s,
  .border-md-x {
    border-left: 1px solid #bfc6cd !important;
  }
}

@media (min-width: 62em) {
  .border-lg {
    border: 1px solid #bfc6cd !important;
  }
  .border-lg-t,
  .border-lg-y {
    border-top: 1px solid #bfc6cd !important;
  }
  .border-lg-e,
  .border-lg-x {
    border-right: 1px solid #bfc6cd !important;
  }
  .border-lg-b,
  .border-lg-y {
    border-bottom: 1px solid #bfc6cd !important;
  }
  .border-lg-s,
  .border-lg-x {
    border-left: 1px solid #bfc6cd !important;
  }
}

@media (min-width: 75em) {
  .border-xl {
    border: 1px solid #bfc6cd !important;
  }
  .border-xl-t,
  .border-xl-y {
    border-top: 1px solid #bfc6cd !important;
  }
  .border-xl-e,
  .border-xl-x {
    border-right: 1px solid #bfc6cd !important;
  }
  .border-xl-b,
  .border-xl-y {
    border-bottom: 1px solid #bfc6cd !important;
  }
  .border-xl-s,
  .border-xl-x {
    border-left: 1px solid #bfc6cd !important;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-none {
  display: none !important;
}

@media (max-width: 35.98em) {
  .d-xs-down-none {
    display: none !important;
  }
}

@media (min-width: 36em) {
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-none {
    display: none !important;
  }
}

@media (max-width: 47.98em) {
  .d-sm-down-none {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-none {
    display: none !important;
  }
}

@media (max-width: 61.98em) {
  .d-md-down-none {
    display: none !important;
  }
}

@media (min-width: 62em) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-none {
    display: none !important;
  }
}

@media (max-width: 74.98em) {
  .d-lg-down-none {
    display: none !important;
  }
}

@media (min-width: 75em) {
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-none {
    display: none !important;
  }
}

@media print {
  .print-block {
    display: block !important;
  }
  .print-flex {
    display: flex !important;
  }
  .print-inline {
    display: inline !important;
  }
  .print-inline-block {
    display: inline-block !important;
  }
  .print-inline-flex {
    display: inline-flex !important;
  }
  .print-grid {
    display: grid !important;
  }
  .print-inline-grid {
    display: inline-grid !important;
  }
  .print-table {
    display: table !important;
  }
  .print-table-row {
    display: table-row !important;
  }
  .print-table-cell {
    display: table-cell !important;
  }
  .print-none {
    display: none !important;
  }
}

.embed-fluid {
  position: relative;
  width: 100%;
}

.embed-fluid::before {
  display: block;
  padding-top: 56.25%;
  content: "";
}

.embed-fluid > .embed-fluid-item,
.embed-fluid > iframe,
.embed-fluid > embed,
.embed-fluid > object,
.embed-fluid > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-fluid-21x9::before {
  padding-top: 42.857143%;
}

.embed-fluid-16x9::before {
  padding-top: 56.25%;
}

.embed-fluid-4x3::before {
  padding-top: 75%;
}

.embed-fluid-1x1::before {
  padding-top: 100%;
}

:-webkit-full-screen .embed-fluid {
  position: initial;
}

:fullscreen .embed-fluid {
  position: initial;
}

:-ms-fullscreen .embed-fluid > .embed-fluid-item,
:-ms-fullscreen .embed-fluid > iframe,
:-ms-fullscreen .embed-fluid > embed,
:-ms-fullscreen .embed-fluid > object,
:-ms-fullscreen .embed-fluid > video {
  position: -ms-device-fixed;
}

.order-first {
  order: -1;
}

.order-last {
  order: 1;
}

.order-unordered {
  order: 0;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-center {
  justify-content: center !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-around {
  justify-content: space-around !important;
}

.flex-between {
  justify-content: space-between !important;
}

.flex-evenly {
  justify-content: space-evenly !important;
}

.flex-items-start {
  align-items: flex-start !important;
}

.flex-items-center {
  align-items: center !important;
}

.flex-items-end {
  align-items: flex-end !important;
}

.flex-items-baseline {
  align-items: baseline !important;
}

.flex-items-stretch {
  align-items: stretch !important;
}

.flex-content-start {
  align-content: flex-start !important;
}

.flex-content-end {
  align-content: flex-end !important;
}

.flex-content-center {
  align-content: center !important;
}

.flex-content-between {
  align-content: space-between !important;
}

.flex-content-around {
  align-content: space-around !important;
}

.flex-content-stretch {
  align-content: stretch !important;
}

.flex-self-start {
  align-self: flex-start !important;
}

.flex-self-center {
  align-self: center !important;
}

.flex-self-end {
  align-self: flex-end !important;
}

.flex-self-baseline {
  align-self: baseline !important;
}

.flex-self-stretch {
  align-self: stretch !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow {
  flex: 1 0 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink {
  flex: 0 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-none {
  flex: none !important;
}

@media (min-width: 36em) {
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 1;
  }
  .order-sm-unordered {
    order: 0;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-start {
    justify-content: flex-start !important;
  }
  .flex-sm-center {
    justify-content: center !important;
  }
  .flex-sm-end {
    justify-content: flex-end !important;
  }
  .flex-sm-around {
    justify-content: space-around !important;
  }
  .flex-sm-between {
    justify-content: space-between !important;
  }
  .flex-sm-evenly {
    justify-content: space-evenly !important;
  }
  .flex-sm-items-start {
    align-items: flex-start !important;
  }
  .flex-sm-items-center {
    align-items: center !important;
  }
  .flex-sm-items-end {
    align-items: flex-end !important;
  }
  .flex-sm-items-baseline {
    align-items: baseline !important;
  }
  .flex-sm-items-stretch {
    align-items: stretch !important;
  }
  .flex-sm-content-start {
    align-content: flex-start !important;
  }
  .flex-sm-content-end {
    align-content: flex-end !important;
  }
  .flex-sm-content-center {
    align-content: center !important;
  }
  .flex-sm-content-between {
    align-content: space-between !important;
  }
  .flex-sm-content-around {
    align-content: space-around !important;
  }
  .flex-sm-content-stretch {
    align-content: stretch !important;
  }
  .flex-sm-self-start {
    align-self: flex-start !important;
  }
  .flex-sm-self-center {
    align-self: center !important;
  }
  .flex-sm-self-end {
    align-self: flex-end !important;
  }
  .flex-sm-self-baseline {
    align-self: baseline !important;
  }
  .flex-sm-self-stretch {
    align-self: stretch !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow {
    flex: 1 0 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink {
    flex: 0 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-none {
    flex: none !important;
  }
}

@media (min-width: 48em) {
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 1;
  }
  .order-md-unordered {
    order: 0;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-start {
    justify-content: flex-start !important;
  }
  .flex-md-center {
    justify-content: center !important;
  }
  .flex-md-end {
    justify-content: flex-end !important;
  }
  .flex-md-around {
    justify-content: space-around !important;
  }
  .flex-md-between {
    justify-content: space-between !important;
  }
  .flex-md-evenly {
    justify-content: space-evenly !important;
  }
  .flex-md-items-start {
    align-items: flex-start !important;
  }
  .flex-md-items-center {
    align-items: center !important;
  }
  .flex-md-items-end {
    align-items: flex-end !important;
  }
  .flex-md-items-baseline {
    align-items: baseline !important;
  }
  .flex-md-items-stretch {
    align-items: stretch !important;
  }
  .flex-md-content-start {
    align-content: flex-start !important;
  }
  .flex-md-content-end {
    align-content: flex-end !important;
  }
  .flex-md-content-center {
    align-content: center !important;
  }
  .flex-md-content-between {
    align-content: space-between !important;
  }
  .flex-md-content-around {
    align-content: space-around !important;
  }
  .flex-md-content-stretch {
    align-content: stretch !important;
  }
  .flex-md-self-start {
    align-self: flex-start !important;
  }
  .flex-md-self-center {
    align-self: center !important;
  }
  .flex-md-self-end {
    align-self: flex-end !important;
  }
  .flex-md-self-baseline {
    align-self: baseline !important;
  }
  .flex-md-self-stretch {
    align-self: stretch !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow {
    flex: 1 0 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink {
    flex: 0 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-none {
    flex: none !important;
  }
}

@media (min-width: 62em) {
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 1;
  }
  .order-lg-unordered {
    order: 0;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-start {
    justify-content: flex-start !important;
  }
  .flex-lg-center {
    justify-content: center !important;
  }
  .flex-lg-end {
    justify-content: flex-end !important;
  }
  .flex-lg-around {
    justify-content: space-around !important;
  }
  .flex-lg-between {
    justify-content: space-between !important;
  }
  .flex-lg-evenly {
    justify-content: space-evenly !important;
  }
  .flex-lg-items-start {
    align-items: flex-start !important;
  }
  .flex-lg-items-center {
    align-items: center !important;
  }
  .flex-lg-items-end {
    align-items: flex-end !important;
  }
  .flex-lg-items-baseline {
    align-items: baseline !important;
  }
  .flex-lg-items-stretch {
    align-items: stretch !important;
  }
  .flex-lg-content-start {
    align-content: flex-start !important;
  }
  .flex-lg-content-end {
    align-content: flex-end !important;
  }
  .flex-lg-content-center {
    align-content: center !important;
  }
  .flex-lg-content-between {
    align-content: space-between !important;
  }
  .flex-lg-content-around {
    align-content: space-around !important;
  }
  .flex-lg-content-stretch {
    align-content: stretch !important;
  }
  .flex-lg-self-start {
    align-self: flex-start !important;
  }
  .flex-lg-self-center {
    align-self: center !important;
  }
  .flex-lg-self-end {
    align-self: flex-end !important;
  }
  .flex-lg-self-baseline {
    align-self: baseline !important;
  }
  .flex-lg-self-stretch {
    align-self: stretch !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow {
    flex: 1 0 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink {
    flex: 0 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-none {
    flex: none !important;
  }
}

@media (min-width: 75em) {
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 1;
  }
  .order-xl-unordered {
    order: 0;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-start {
    justify-content: flex-start !important;
  }
  .flex-xl-center {
    justify-content: center !important;
  }
  .flex-xl-end {
    justify-content: flex-end !important;
  }
  .flex-xl-around {
    justify-content: space-around !important;
  }
  .flex-xl-between {
    justify-content: space-between !important;
  }
  .flex-xl-evenly {
    justify-content: space-evenly !important;
  }
  .flex-xl-items-start {
    align-items: flex-start !important;
  }
  .flex-xl-items-center {
    align-items: center !important;
  }
  .flex-xl-items-end {
    align-items: flex-end !important;
  }
  .flex-xl-items-baseline {
    align-items: baseline !important;
  }
  .flex-xl-items-stretch {
    align-items: stretch !important;
  }
  .flex-xl-content-start {
    align-content: flex-start !important;
  }
  .flex-xl-content-end {
    align-content: flex-end !important;
  }
  .flex-xl-content-center {
    align-content: center !important;
  }
  .flex-xl-content-between {
    align-content: space-between !important;
  }
  .flex-xl-content-around {
    align-content: space-around !important;
  }
  .flex-xl-content-stretch {
    align-content: stretch !important;
  }
  .flex-xl-self-start {
    align-self: flex-start !important;
  }
  .flex-xl-self-center {
    align-self: center !important;
  }
  .flex-xl-self-end {
    align-self: flex-end !important;
  }
  .flex-xl-self-baseline {
    align-self: baseline !important;
  }
  .flex-xl-self-stretch {
    align-self: stretch !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow {
    flex: 1 0 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink {
    flex: 0 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-none {
    flex: none !important;
  }
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

@media (min-width: 36em) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 48em) {
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 62em) {
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 75em) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1020;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1020;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1010;
  }
  @media (min-width: 36em) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1010;
    }
  }
  @media (min-width: 48em) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1010;
    }
  }
  @media (min-width: 62em) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1010;
    }
  }
  @media (min-width: 75em) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1010;
    }
  }
}

.sr-only, .sr-only-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.sr-only:not(caption), .sr-only-focusable:not(caption):not(:focus):not(:focus-within) {
  position: absolute !important;
}

@media (max-width: 35.98em) {
  .sr-only-xs-down, .sr-only-xs-down-focusable:not(:focus) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-xs-down:not(caption), .sr-only-xs-down-focusable:not(caption):not(:focus) {
    position: absolute !important;
  }
}

@media (min-width: 36em) {
  .sr-only-sm, .sr-only-sm-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-sm:not(caption), .sr-only-sm-focusable:not(caption):not(:focus):not(:focus-within) {
    position: absolute !important;
  }
}

@media (max-width: 47.98em) {
  .sr-only-sm-down, .sr-only-sm-down-focusable:not(:focus) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-sm-down:not(caption), .sr-only-sm-down-focusable:not(caption):not(:focus) {
    position: absolute !important;
  }
}

@media (min-width: 48em) {
  .sr-only-md, .sr-only-md-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-md:not(caption), .sr-only-md-focusable:not(caption):not(:focus):not(:focus-within) {
    position: absolute !important;
  }
}

@media (max-width: 61.98em) {
  .sr-only-md-down, .sr-only-md-down-focusable:not(:focus) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-md-down:not(caption), .sr-only-md-down-focusable:not(caption):not(:focus) {
    position: absolute !important;
  }
}

@media (min-width: 62em) {
  .sr-only-lg, .sr-only-lg-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-lg:not(caption), .sr-only-lg-focusable:not(caption):not(:focus):not(:focus-within) {
    position: absolute !important;
  }
}

@media (max-width: 74.98em) {
  .sr-only-lg-down, .sr-only-lg-down-focusable:not(:focus) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-lg-down:not(caption), .sr-only-lg-down-focusable:not(caption):not(:focus) {
    position: absolute !important;
  }
}

@media (min-width: 75em) {
  .sr-only-xl, .sr-only-xl-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .sr-only-xl:not(caption), .sr-only-xl-focusable:not(caption):not(:focus):not(:focus-within) {
    position: absolute !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.m-0_25 {
  margin: 0.25rem !important;
}

.m-0_5 {
  margin: 0.5rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.m-1_5 {
  margin: 1.5rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.me-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ms-0,
.mx-0 {
  margin-left: 0 !important;
}

.mt-0_25,
.my-0_25 {
  margin-top: 0.25rem !important;
}

.me-0_25,
.mx-0_25 {
  margin-right: 0.25rem !important;
}

.mb-0_25,
.my-0_25 {
  margin-bottom: 0.25rem !important;
}

.ms-0_25,
.mx-0_25 {
  margin-left: 0.25rem !important;
}

.mt-0_5,
.my-0_5 {
  margin-top: 0.5rem !important;
}

.me-0_5,
.mx-0_5 {
  margin-right: 0.5rem !important;
}

.mb-0_5,
.my-0_5 {
  margin-bottom: 0.5rem !important;
}

.ms-0_5,
.mx-0_5 {
  margin-left: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 1rem !important;
}

.me-1,
.mx-1 {
  margin-right: 1rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 1rem !important;
}

.ms-1,
.mx-1 {
  margin-left: 1rem !important;
}

.mt-1_5,
.my-1_5 {
  margin-top: 1.5rem !important;
}

.me-1_5,
.mx-1_5 {
  margin-right: 1.5rem !important;
}

.mb-1_5,
.my-1_5 {
  margin-bottom: 1.5rem !important;
}

.ms-1_5,
.mx-1_5 {
  margin-left: 1.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 2rem !important;
}

.me-2,
.mx-2 {
  margin-right: 2rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 2rem !important;
}

.ms-2,
.mx-2 {
  margin-left: 2rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-0_25 {
  padding: 0.25rem !important;
}

.p-0_5 {
  padding: 0.5rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-1_5 {
  padding: 1.5rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pe-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.ps-0,
.px-0 {
  padding-left: 0 !important;
}

.pt-0_25,
.py-0_25 {
  padding-top: 0.25rem !important;
}

.pe-0_25,
.px-0_25 {
  padding-right: 0.25rem !important;
}

.pb-0_25,
.py-0_25 {
  padding-bottom: 0.25rem !important;
}

.ps-0_25,
.px-0_25 {
  padding-left: 0.25rem !important;
}

.pt-0_5,
.py-0_5 {
  padding-top: 0.5rem !important;
}

.pe-0_5,
.px-0_5 {
  padding-right: 0.5rem !important;
}

.pb-0_5,
.py-0_5 {
  padding-bottom: 0.5rem !important;
}

.ps-0_5,
.px-0_5 {
  padding-left: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 1rem !important;
}

.pe-1,
.px-1 {
  padding-right: 1rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 1rem !important;
}

.ps-1,
.px-1 {
  padding-left: 1rem !important;
}

.pt-1_5,
.py-1_5 {
  padding-top: 1.5rem !important;
}

.pe-1_5,
.px-1_5 {
  padding-right: 1.5rem !important;
}

.pb-1_5,
.py-1_5 {
  padding-bottom: 1.5rem !important;
}

.ps-1_5,
.px-1_5 {
  padding-left: 1.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 2rem !important;
}

.pe-2,
.px-2 {
  padding-right: 2rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 2rem !important;
}

.ps-2,
.px-2 {
  padding-left: 2rem !important;
}

.m-n0_25 {
  margin: -0.25rem !important;
}

.m-n0_5 {
  margin: -0.5rem !important;
}

.m-n1 {
  margin: -1rem !important;
}

.m-n1_5 {
  margin: -1.5rem !important;
}

.m-n2 {
  margin: -2rem !important;
}

.mt-n0_25,
.my-n0_25 {
  margin-top: -0.25rem !important;
}

.me-n0_25,
.mx-n0_25 {
  margin-right: -0.25rem !important;
}

.mb-n0_25,
.my-n0_25 {
  margin-bottom: -0.25rem !important;
}

.ms-n0_25,
.mx-n0_25 {
  margin-left: -0.25rem !important;
}

.mt-n0_5,
.my-n0_5 {
  margin-top: -0.5rem !important;
}

.me-n0_5,
.mx-n0_5 {
  margin-right: -0.5rem !important;
}

.mb-n0_5,
.my-n0_5 {
  margin-bottom: -0.5rem !important;
}

.ms-n0_5,
.mx-n0_5 {
  margin-left: -0.5rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -1rem !important;
}

.me-n1,
.mx-n1 {
  margin-right: -1rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important;
}

.ms-n1,
.mx-n1 {
  margin-left: -1rem !important;
}

.mt-n1_5,
.my-n1_5 {
  margin-top: -1.5rem !important;
}

.me-n1_5,
.mx-n1_5 {
  margin-right: -1.5rem !important;
}

.mb-n1_5,
.my-n1_5 {
  margin-bottom: -1.5rem !important;
}

.ms-n1_5,
.mx-n1_5 {
  margin-left: -1.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -2rem !important;
}

.me-n2,
.mx-n2 {
  margin-right: -2rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -2rem !important;
}

.ms-n2,
.mx-n2 {
  margin-left: -2rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.me-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ms-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 36em) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-0_25 {
    margin: 0.25rem !important;
  }
  .m-sm-0_5 {
    margin: 0.5rem !important;
  }
  .m-sm-1 {
    margin: 1rem !important;
  }
  .m-sm-1_5 {
    margin: 1.5rem !important;
  }
  .m-sm-2 {
    margin: 2rem !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .me-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ms-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .mt-sm-0_25,
  .my-sm-0_25 {
    margin-top: 0.25rem !important;
  }
  .me-sm-0_25,
  .mx-sm-0_25 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-0_25,
  .my-sm-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .ms-sm-0_25,
  .mx-sm-0_25 {
    margin-left: 0.25rem !important;
  }
  .mt-sm-0_5,
  .my-sm-0_5 {
    margin-top: 0.5rem !important;
  }
  .me-sm-0_5,
  .mx-sm-0_5 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-0_5,
  .my-sm-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .ms-sm-0_5,
  .mx-sm-0_5 {
    margin-left: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important;
  }
  .me-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important;
  }
  .ms-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important;
  }
  .mt-sm-1_5,
  .my-sm-1_5 {
    margin-top: 1.5rem !important;
  }
  .me-sm-1_5,
  .mx-sm-1_5 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-1_5,
  .my-sm-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-sm-1_5,
  .mx-sm-1_5 {
    margin-left: 1.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2rem !important;
  }
  .me-sm-2,
  .mx-sm-2 {
    margin-right: 2rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2rem !important;
  }
  .ms-sm-2,
  .mx-sm-2 {
    margin-left: 2rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-0_25 {
    padding: 0.25rem !important;
  }
  .p-sm-0_5 {
    padding: 0.5rem !important;
  }
  .p-sm-1 {
    padding: 1rem !important;
  }
  .p-sm-1_5 {
    padding: 1.5rem !important;
  }
  .p-sm-2 {
    padding: 2rem !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pe-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .ps-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .pt-sm-0_25,
  .py-sm-0_25 {
    padding-top: 0.25rem !important;
  }
  .pe-sm-0_25,
  .px-sm-0_25 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-0_25,
  .py-sm-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .ps-sm-0_25,
  .px-sm-0_25 {
    padding-left: 0.25rem !important;
  }
  .pt-sm-0_5,
  .py-sm-0_5 {
    padding-top: 0.5rem !important;
  }
  .pe-sm-0_5,
  .px-sm-0_5 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-0_5,
  .py-sm-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .ps-sm-0_5,
  .px-sm-0_5 {
    padding-left: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important;
  }
  .pe-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important;
  }
  .ps-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important;
  }
  .pt-sm-1_5,
  .py-sm-1_5 {
    padding-top: 1.5rem !important;
  }
  .pe-sm-1_5,
  .px-sm-1_5 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-1_5,
  .py-sm-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-sm-1_5,
  .px-sm-1_5 {
    padding-left: 1.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2rem !important;
  }
  .pe-sm-2,
  .px-sm-2 {
    padding-right: 2rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2rem !important;
  }
  .ps-sm-2,
  .px-sm-2 {
    padding-left: 2rem !important;
  }
  .m-sm-n0_25 {
    margin: -0.25rem !important;
  }
  .m-sm-n0_5 {
    margin: -0.5rem !important;
  }
  .m-sm-n1 {
    margin: -1rem !important;
  }
  .m-sm-n1_5 {
    margin: -1.5rem !important;
  }
  .m-sm-n2 {
    margin: -2rem !important;
  }
  .mt-sm-n0_25,
  .my-sm-n0_25 {
    margin-top: -0.25rem !important;
  }
  .me-sm-n0_25,
  .mx-sm-n0_25 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n0_25,
  .my-sm-n0_25 {
    margin-bottom: -0.25rem !important;
  }
  .ms-sm-n0_25,
  .mx-sm-n0_25 {
    margin-left: -0.25rem !important;
  }
  .mt-sm-n0_5,
  .my-sm-n0_5 {
    margin-top: -0.5rem !important;
  }
  .me-sm-n0_5,
  .mx-sm-n0_5 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n0_5,
  .my-sm-n0_5 {
    margin-bottom: -0.5rem !important;
  }
  .ms-sm-n0_5,
  .mx-sm-n0_5 {
    margin-left: -0.5rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important;
  }
  .me-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important;
  }
  .ms-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important;
  }
  .mt-sm-n1_5,
  .my-sm-n1_5 {
    margin-top: -1.5rem !important;
  }
  .me-sm-n1_5,
  .mx-sm-n1_5 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n1_5,
  .my-sm-n1_5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-sm-n1_5,
  .mx-sm-n1_5 {
    margin-left: -1.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2rem !important;
  }
  .me-sm-n2,
  .mx-sm-n2 {
    margin-right: -2rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2rem !important;
  }
  .ms-sm-n2,
  .mx-sm-n2 {
    margin-left: -2rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 48em) {
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-0_25 {
    margin: 0.25rem !important;
  }
  .m-md-0_5 {
    margin: 0.5rem !important;
  }
  .m-md-1 {
    margin: 1rem !important;
  }
  .m-md-1_5 {
    margin: 1.5rem !important;
  }
  .m-md-2 {
    margin: 2rem !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .me-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ms-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .mt-md-0_25,
  .my-md-0_25 {
    margin-top: 0.25rem !important;
  }
  .me-md-0_25,
  .mx-md-0_25 {
    margin-right: 0.25rem !important;
  }
  .mb-md-0_25,
  .my-md-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .ms-md-0_25,
  .mx-md-0_25 {
    margin-left: 0.25rem !important;
  }
  .mt-md-0_5,
  .my-md-0_5 {
    margin-top: 0.5rem !important;
  }
  .me-md-0_5,
  .mx-md-0_5 {
    margin-right: 0.5rem !important;
  }
  .mb-md-0_5,
  .my-md-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .ms-md-0_5,
  .mx-md-0_5 {
    margin-left: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important;
  }
  .me-md-1,
  .mx-md-1 {
    margin-right: 1rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important;
  }
  .ms-md-1,
  .mx-md-1 {
    margin-left: 1rem !important;
  }
  .mt-md-1_5,
  .my-md-1_5 {
    margin-top: 1.5rem !important;
  }
  .me-md-1_5,
  .mx-md-1_5 {
    margin-right: 1.5rem !important;
  }
  .mb-md-1_5,
  .my-md-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-md-1_5,
  .mx-md-1_5 {
    margin-left: 1.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2rem !important;
  }
  .me-md-2,
  .mx-md-2 {
    margin-right: 2rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2rem !important;
  }
  .ms-md-2,
  .mx-md-2 {
    margin-left: 2rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-0_25 {
    padding: 0.25rem !important;
  }
  .p-md-0_5 {
    padding: 0.5rem !important;
  }
  .p-md-1 {
    padding: 1rem !important;
  }
  .p-md-1_5 {
    padding: 1.5rem !important;
  }
  .p-md-2 {
    padding: 2rem !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pe-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .ps-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .pt-md-0_25,
  .py-md-0_25 {
    padding-top: 0.25rem !important;
  }
  .pe-md-0_25,
  .px-md-0_25 {
    padding-right: 0.25rem !important;
  }
  .pb-md-0_25,
  .py-md-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .ps-md-0_25,
  .px-md-0_25 {
    padding-left: 0.25rem !important;
  }
  .pt-md-0_5,
  .py-md-0_5 {
    padding-top: 0.5rem !important;
  }
  .pe-md-0_5,
  .px-md-0_5 {
    padding-right: 0.5rem !important;
  }
  .pb-md-0_5,
  .py-md-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .ps-md-0_5,
  .px-md-0_5 {
    padding-left: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important;
  }
  .pe-md-1,
  .px-md-1 {
    padding-right: 1rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important;
  }
  .ps-md-1,
  .px-md-1 {
    padding-left: 1rem !important;
  }
  .pt-md-1_5,
  .py-md-1_5 {
    padding-top: 1.5rem !important;
  }
  .pe-md-1_5,
  .px-md-1_5 {
    padding-right: 1.5rem !important;
  }
  .pb-md-1_5,
  .py-md-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-md-1_5,
  .px-md-1_5 {
    padding-left: 1.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2rem !important;
  }
  .pe-md-2,
  .px-md-2 {
    padding-right: 2rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2rem !important;
  }
  .ps-md-2,
  .px-md-2 {
    padding-left: 2rem !important;
  }
  .m-md-n0_25 {
    margin: -0.25rem !important;
  }
  .m-md-n0_5 {
    margin: -0.5rem !important;
  }
  .m-md-n1 {
    margin: -1rem !important;
  }
  .m-md-n1_5 {
    margin: -1.5rem !important;
  }
  .m-md-n2 {
    margin: -2rem !important;
  }
  .mt-md-n0_25,
  .my-md-n0_25 {
    margin-top: -0.25rem !important;
  }
  .me-md-n0_25,
  .mx-md-n0_25 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n0_25,
  .my-md-n0_25 {
    margin-bottom: -0.25rem !important;
  }
  .ms-md-n0_25,
  .mx-md-n0_25 {
    margin-left: -0.25rem !important;
  }
  .mt-md-n0_5,
  .my-md-n0_5 {
    margin-top: -0.5rem !important;
  }
  .me-md-n0_5,
  .mx-md-n0_5 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n0_5,
  .my-md-n0_5 {
    margin-bottom: -0.5rem !important;
  }
  .ms-md-n0_5,
  .mx-md-n0_5 {
    margin-left: -0.5rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important;
  }
  .me-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important;
  }
  .ms-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important;
  }
  .mt-md-n1_5,
  .my-md-n1_5 {
    margin-top: -1.5rem !important;
  }
  .me-md-n1_5,
  .mx-md-n1_5 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n1_5,
  .my-md-n1_5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-md-n1_5,
  .mx-md-n1_5 {
    margin-left: -1.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2rem !important;
  }
  .me-md-n2,
  .mx-md-n2 {
    margin-right: -2rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2rem !important;
  }
  .ms-md-n2,
  .mx-md-n2 {
    margin-left: -2rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .me-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 62em) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-0_25 {
    margin: 0.25rem !important;
  }
  .m-lg-0_5 {
    margin: 0.5rem !important;
  }
  .m-lg-1 {
    margin: 1rem !important;
  }
  .m-lg-1_5 {
    margin: 1.5rem !important;
  }
  .m-lg-2 {
    margin: 2rem !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .me-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ms-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .mt-lg-0_25,
  .my-lg-0_25 {
    margin-top: 0.25rem !important;
  }
  .me-lg-0_25,
  .mx-lg-0_25 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-0_25,
  .my-lg-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .ms-lg-0_25,
  .mx-lg-0_25 {
    margin-left: 0.25rem !important;
  }
  .mt-lg-0_5,
  .my-lg-0_5 {
    margin-top: 0.5rem !important;
  }
  .me-lg-0_5,
  .mx-lg-0_5 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-0_5,
  .my-lg-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .ms-lg-0_5,
  .mx-lg-0_5 {
    margin-left: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important;
  }
  .me-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important;
  }
  .ms-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important;
  }
  .mt-lg-1_5,
  .my-lg-1_5 {
    margin-top: 1.5rem !important;
  }
  .me-lg-1_5,
  .mx-lg-1_5 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-1_5,
  .my-lg-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-lg-1_5,
  .mx-lg-1_5 {
    margin-left: 1.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2rem !important;
  }
  .me-lg-2,
  .mx-lg-2 {
    margin-right: 2rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2rem !important;
  }
  .ms-lg-2,
  .mx-lg-2 {
    margin-left: 2rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-0_25 {
    padding: 0.25rem !important;
  }
  .p-lg-0_5 {
    padding: 0.5rem !important;
  }
  .p-lg-1 {
    padding: 1rem !important;
  }
  .p-lg-1_5 {
    padding: 1.5rem !important;
  }
  .p-lg-2 {
    padding: 2rem !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pe-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .ps-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .pt-lg-0_25,
  .py-lg-0_25 {
    padding-top: 0.25rem !important;
  }
  .pe-lg-0_25,
  .px-lg-0_25 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-0_25,
  .py-lg-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .ps-lg-0_25,
  .px-lg-0_25 {
    padding-left: 0.25rem !important;
  }
  .pt-lg-0_5,
  .py-lg-0_5 {
    padding-top: 0.5rem !important;
  }
  .pe-lg-0_5,
  .px-lg-0_5 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-0_5,
  .py-lg-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .ps-lg-0_5,
  .px-lg-0_5 {
    padding-left: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important;
  }
  .pe-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important;
  }
  .ps-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important;
  }
  .pt-lg-1_5,
  .py-lg-1_5 {
    padding-top: 1.5rem !important;
  }
  .pe-lg-1_5,
  .px-lg-1_5 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-1_5,
  .py-lg-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-lg-1_5,
  .px-lg-1_5 {
    padding-left: 1.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2rem !important;
  }
  .pe-lg-2,
  .px-lg-2 {
    padding-right: 2rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2rem !important;
  }
  .ps-lg-2,
  .px-lg-2 {
    padding-left: 2rem !important;
  }
  .m-lg-n0_25 {
    margin: -0.25rem !important;
  }
  .m-lg-n0_5 {
    margin: -0.5rem !important;
  }
  .m-lg-n1 {
    margin: -1rem !important;
  }
  .m-lg-n1_5 {
    margin: -1.5rem !important;
  }
  .m-lg-n2 {
    margin: -2rem !important;
  }
  .mt-lg-n0_25,
  .my-lg-n0_25 {
    margin-top: -0.25rem !important;
  }
  .me-lg-n0_25,
  .mx-lg-n0_25 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n0_25,
  .my-lg-n0_25 {
    margin-bottom: -0.25rem !important;
  }
  .ms-lg-n0_25,
  .mx-lg-n0_25 {
    margin-left: -0.25rem !important;
  }
  .mt-lg-n0_5,
  .my-lg-n0_5 {
    margin-top: -0.5rem !important;
  }
  .me-lg-n0_5,
  .mx-lg-n0_5 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n0_5,
  .my-lg-n0_5 {
    margin-bottom: -0.5rem !important;
  }
  .ms-lg-n0_5,
  .mx-lg-n0_5 {
    margin-left: -0.5rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important;
  }
  .me-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important;
  }
  .ms-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important;
  }
  .mt-lg-n1_5,
  .my-lg-n1_5 {
    margin-top: -1.5rem !important;
  }
  .me-lg-n1_5,
  .mx-lg-n1_5 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n1_5,
  .my-lg-n1_5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-lg-n1_5,
  .mx-lg-n1_5 {
    margin-left: -1.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2rem !important;
  }
  .me-lg-n2,
  .mx-lg-n2 {
    margin-right: -2rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2rem !important;
  }
  .ms-lg-n2,
  .mx-lg-n2 {
    margin-left: -2rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 75em) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-0_25 {
    margin: 0.25rem !important;
  }
  .m-xl-0_5 {
    margin: 0.5rem !important;
  }
  .m-xl-1 {
    margin: 1rem !important;
  }
  .m-xl-1_5 {
    margin: 1.5rem !important;
  }
  .m-xl-2 {
    margin: 2rem !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .me-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ms-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .mt-xl-0_25,
  .my-xl-0_25 {
    margin-top: 0.25rem !important;
  }
  .me-xl-0_25,
  .mx-xl-0_25 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-0_25,
  .my-xl-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .ms-xl-0_25,
  .mx-xl-0_25 {
    margin-left: 0.25rem !important;
  }
  .mt-xl-0_5,
  .my-xl-0_5 {
    margin-top: 0.5rem !important;
  }
  .me-xl-0_5,
  .mx-xl-0_5 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-0_5,
  .my-xl-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .ms-xl-0_5,
  .mx-xl-0_5 {
    margin-left: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important;
  }
  .me-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important;
  }
  .ms-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important;
  }
  .mt-xl-1_5,
  .my-xl-1_5 {
    margin-top: 1.5rem !important;
  }
  .me-xl-1_5,
  .mx-xl-1_5 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-1_5,
  .my-xl-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .ms-xl-1_5,
  .mx-xl-1_5 {
    margin-left: 1.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2rem !important;
  }
  .me-xl-2,
  .mx-xl-2 {
    margin-right: 2rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2rem !important;
  }
  .ms-xl-2,
  .mx-xl-2 {
    margin-left: 2rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-0_25 {
    padding: 0.25rem !important;
  }
  .p-xl-0_5 {
    padding: 0.5rem !important;
  }
  .p-xl-1 {
    padding: 1rem !important;
  }
  .p-xl-1_5 {
    padding: 1.5rem !important;
  }
  .p-xl-2 {
    padding: 2rem !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pe-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .ps-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .pt-xl-0_25,
  .py-xl-0_25 {
    padding-top: 0.25rem !important;
  }
  .pe-xl-0_25,
  .px-xl-0_25 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-0_25,
  .py-xl-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .ps-xl-0_25,
  .px-xl-0_25 {
    padding-left: 0.25rem !important;
  }
  .pt-xl-0_5,
  .py-xl-0_5 {
    padding-top: 0.5rem !important;
  }
  .pe-xl-0_5,
  .px-xl-0_5 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-0_5,
  .py-xl-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .ps-xl-0_5,
  .px-xl-0_5 {
    padding-left: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important;
  }
  .pe-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important;
  }
  .ps-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important;
  }
  .pt-xl-1_5,
  .py-xl-1_5 {
    padding-top: 1.5rem !important;
  }
  .pe-xl-1_5,
  .px-xl-1_5 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-1_5,
  .py-xl-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .ps-xl-1_5,
  .px-xl-1_5 {
    padding-left: 1.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2rem !important;
  }
  .pe-xl-2,
  .px-xl-2 {
    padding-right: 2rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2rem !important;
  }
  .ps-xl-2,
  .px-xl-2 {
    padding-left: 2rem !important;
  }
  .m-xl-n0_25 {
    margin: -0.25rem !important;
  }
  .m-xl-n0_5 {
    margin: -0.5rem !important;
  }
  .m-xl-n1 {
    margin: -1rem !important;
  }
  .m-xl-n1_5 {
    margin: -1.5rem !important;
  }
  .m-xl-n2 {
    margin: -2rem !important;
  }
  .mt-xl-n0_25,
  .my-xl-n0_25 {
    margin-top: -0.25rem !important;
  }
  .me-xl-n0_25,
  .mx-xl-n0_25 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n0_25,
  .my-xl-n0_25 {
    margin-bottom: -0.25rem !important;
  }
  .ms-xl-n0_25,
  .mx-xl-n0_25 {
    margin-left: -0.25rem !important;
  }
  .mt-xl-n0_5,
  .my-xl-n0_5 {
    margin-top: -0.5rem !important;
  }
  .me-xl-n0_5,
  .mx-xl-n0_5 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n0_5,
  .my-xl-n0_5 {
    margin-bottom: -0.5rem !important;
  }
  .ms-xl-n0_5,
  .mx-xl-n0_5 {
    margin-left: -0.5rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important;
  }
  .me-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important;
  }
  .ms-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important;
  }
  .mt-xl-n1_5,
  .my-xl-n1_5 {
    margin-top: -1.5rem !important;
  }
  .me-xl-n1_5,
  .mx-xl-n1_5 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n1_5,
  .my-xl-n1_5 {
    margin-bottom: -1.5rem !important;
  }
  .ms-xl-n1_5,
  .mx-xl-n1_5 {
    margin-left: -1.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2rem !important;
  }
  .me-xl-n2,
  .mx-xl-n2 {
    margin-right: -2rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2rem !important;
  }
  .ms-xl-n2,
  .mx-xl-n2 {
    margin-left: -2rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 36em) {
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 48em) {
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 62em) {
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 75em) {
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.text-danger {
  color: #ff483b !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #d93d32 !important;
}

.text-body {
  color: #3d3d3d !important;
}

.text-muted {
  color: #5e7182 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.fs-xsmall {
  font-size: 0.75rem !important;
}

.fs-small {
  font-size: 0.875rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-large {
  font-size: 1.125rem !important;
}

.fs-xlarge {
  font-size: 1.25rem !important;
}

.fs-2xlarge {
  font-size: 1.5rem !important;
}

.fs-3xlarge {
  font-size: 1.75rem !important;
}

.fs-4xlarge {
  font-size: 2rem !important;
}

.fs-5xlarge {
  font-size: 2.5rem !important;
}

@media (min-width: 36em) {
  .fs-sm-xsmall {
    font-size: 0.75rem !important;
  }
  .fs-sm-small {
    font-size: 0.875rem !important;
  }
  .fs-sm-base {
    font-size: 1rem !important;
  }
  .fs-sm-large {
    font-size: 1.125rem !important;
  }
  .fs-sm-xlarge {
    font-size: 1.25rem !important;
  }
  .fs-sm-2xlarge {
    font-size: 1.5rem !important;
  }
  .fs-sm-3xlarge {
    font-size: 1.75rem !important;
  }
  .fs-sm-4xlarge {
    font-size: 2rem !important;
  }
  .fs-sm-5xlarge {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 48em) {
  .fs-md-xsmall {
    font-size: 0.75rem !important;
  }
  .fs-md-small {
    font-size: 0.875rem !important;
  }
  .fs-md-base {
    font-size: 1rem !important;
  }
  .fs-md-large {
    font-size: 1.125rem !important;
  }
  .fs-md-xlarge {
    font-size: 1.25rem !important;
  }
  .fs-md-2xlarge {
    font-size: 1.5rem !important;
  }
  .fs-md-3xlarge {
    font-size: 1.75rem !important;
  }
  .fs-md-4xlarge {
    font-size: 2rem !important;
  }
  .fs-md-5xlarge {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 62em) {
  .fs-lg-xsmall {
    font-size: 0.75rem !important;
  }
  .fs-lg-small {
    font-size: 0.875rem !important;
  }
  .fs-lg-base {
    font-size: 1rem !important;
  }
  .fs-lg-large {
    font-size: 1.125rem !important;
  }
  .fs-lg-xlarge {
    font-size: 1.25rem !important;
  }
  .fs-lg-2xlarge {
    font-size: 1.5rem !important;
  }
  .fs-lg-3xlarge {
    font-size: 1.75rem !important;
  }
  .fs-lg-4xlarge {
    font-size: 2rem !important;
  }
  .fs-lg-5xlarge {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 75em) {
  .fs-xl-xsmall {
    font-size: 0.75rem !important;
  }
  .fs-xl-small {
    font-size: 0.875rem !important;
  }
  .fs-xl-base {
    font-size: 1rem !important;
  }
  .fs-xl-large {
    font-size: 1.125rem !important;
  }
  .fs-xl-xlarge {
    font-size: 1.25rem !important;
  }
  .fs-xl-2xlarge {
    font-size: 1.5rem !important;
  }
  .fs-xl-3xlarge {
    font-size: 1.75rem !important;
  }
  .fs-xl-4xlarge {
    font-size: 2rem !important;
  }
  .fs-xl-5xlarge {
    font-size: 2.5rem !important;
  }
}

.valign-baseline {
  vertical-align: baseline !important;
}

.valign-top {
  vertical-align: top !important;
}

.valign-middle {
  vertical-align: middle !important;
}

.valign-bottom {
  vertical-align: bottom !important;
}

.valign-text-bottom {
  vertical-align: text-bottom !important;
}

.valign-text-top {
  vertical-align: text-top !important;
}

@media (min-width: 36em) {
  .valign-sm-baseline {
    vertical-align: baseline !important;
  }
  .valign-sm-top {
    vertical-align: top !important;
  }
  .valign-sm-middle {
    vertical-align: middle !important;
  }
  .valign-sm-bottom {
    vertical-align: bottom !important;
  }
  .valign-sm-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-sm-text-top {
    vertical-align: text-top !important;
  }
}

@media (min-width: 48em) {
  .valign-md-baseline {
    vertical-align: baseline !important;
  }
  .valign-md-top {
    vertical-align: top !important;
  }
  .valign-md-middle {
    vertical-align: middle !important;
  }
  .valign-md-bottom {
    vertical-align: bottom !important;
  }
  .valign-md-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-md-text-top {
    vertical-align: text-top !important;
  }
}

@media (min-width: 62em) {
  .valign-lg-baseline {
    vertical-align: baseline !important;
  }
  .valign-lg-top {
    vertical-align: top !important;
  }
  .valign-lg-middle {
    vertical-align: middle !important;
  }
  .valign-lg-bottom {
    vertical-align: bottom !important;
  }
  .valign-lg-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-lg-text-top {
    vertical-align: text-top !important;
  }
}

@media (min-width: 75em) {
  .valign-xl-baseline {
    vertical-align: baseline !important;
  }
  .valign-xl-top {
    vertical-align: top !important;
  }
  .valign-xl-middle {
    vertical-align: middle !important;
  }
  .valign-xl-bottom {
    vertical-align: bottom !important;
  }
  .valign-xl-text-bottom {
    vertical-align: text-bottom !important;
  }
  .valign-xl-text-top {
    vertical-align: text-top !important;
  }
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p, h2, .h2, h3, .h3 {
    orphans: 3;
    widows: 3;
  }
  h2, .h2, h3, .h3 {
    page-break-after: avoid;
  }
  .badge {
    border: 1px solid #000;
  }
}

.container-max {
  position: relative;
  width: 100%;
  max-width: 100rem;
}

h3, .h3,
.h3 {
  font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dropdown-menu,
.tooltip-body {
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.15);
}

.page-wrapper {
  min-height: calc(100vh - 3rem);
  background-color: #f1f9fb;
}

.has-bottomnav .page-wrapper {
  min-height: calc(100vh - 6rem);
}

.content {
  height: 100%;
  margin: 0;
  background-color: #f1f9fb;
}

.content.row > * {
  padding-right: 1rem;
  padding-left: 1rem;
}

.content .content-sidebar {
  padding-left: 0;
}

.content.has-sidebar .content-main {
  padding-right: 2rem;
}

.content-sidebar {
  position: sticky;
  top: 3rem;
  left: 0;
  display: none;
  height: 100%;
  padding-top: 1rem;
}

.has-bottomnav .content-sidebar {
  height: calc(100vh - 6rem);
}

.content-main-inner {
  max-width: 56.25rem;
  padding-top: 2rem;
  padding-bottom: 10rem;
  margin: 0 auto;
}

@media (min-width: 48em) {
  .content-main-sticky-top {
    position: sticky;
    top: 3rem;
    z-index: 1010;
    background-color: #f1f9fb;
  }
}

.review-anno {
  display: none;
}

.annoview .content-main-inner {
  max-width: 100%;
}

@media (min-width: 62em) {
  .annoview .review-main {
    width: 66.666667%;
  }
  .annoview .review-anno {
    display: block;
    width: 33.333333%;
  }
}

.avatar-img,
.collab-img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.slide-img {
  width: 1rem;
  height: 1rem;
  vertical-align: baseline;
}

.list-nostyle {
  list-style: none;
}

.section {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.section-border-top {
  padding-top: 1.5rem;
  border-top: 1px solid currentcolor;
}

.section-border-top-dashed {
  padding-top: 1.5rem;
  border-top: 3px dashed currentcolor;
}

.hinline {
  padding-right: 2rem;
  margin-bottom: .5rem;
}

.hinline > h1, .hinline > .h1,
.hinline > h2,
.hinline > .h2,
.hinline > h3,
.hinline > .h3,
.hinline > h4,
.hinline > .h4,
.hinline > h5,
.hinline > .h5,
.hinline > h6,
.hinline > .h6 {
  display: inline;
  padding-right: 0;
  margin-right: .25em;
  margin-bottom: 0;
}

.hinline > .btn-info {
  vertical-align: text-bottom;
}

.toc {
  padding-left: 1.75rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
  font-size: 1.25rem;
  font-weight: 700;
  color: #003d6f;
}

.toc li {
  position: relative;
  margin-bottom: .5rem;
  margin-left: 1.5rem;
}

.toc .list-nostyle {
  margin-left: 0;
}

.eyebrow {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}

.directions {
  font-size: 0.875rem;
  font-style: italic;
}

.vocab-word {
  -webkit-text-decoration: underline dashed 2px;
          text-decoration: underline dashed 2px;
}

.vocab-tooltip .tooltip-body {
  text-align: left;
}

.vocab-tooltip .close {
  font-size: 1.5rem;
}

.attachment {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.attachment-media {
  max-width: Min(75%, 50vw);
}

.attachment-media:not(:empty) {
  margin-right: .5rem;
}

@media (min-width: 48em) {
  .attachment-media {
    max-width: Min(75%, 37vw);
  }
}

@media (min-width: 62em) {
  .attachment-media {
    max-width: Min(75%, 26vw);
  }
}

@media (min-width: 75em) {
  .attachment-media {
    max-width: Min(75%, 20vw);
  }
}

.attachment-media .img-fluid {
  max-height: 30vw;
}

@media (min-width: 48em) {
  .attachment-media .img-fluid {
    max-height: 25vw;
  }
}

@media (min-width: 62em) {
  .attachment-media .img-fluid {
    max-height: 20vw;
  }
}

@media (min-width: 75em) {
  .attachment-media .img-fluid {
    max-height: 15vw;
  }
}

.attachment-action {
  flex: 1 1;
}

.attachment-action:empty {
  display: none;
}

.fa-stack-custom {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25em;
  height: 1.25em;
  font-size: 1.25em;
  line-height: 1;
  vertical-align: middle;
}

.fa-stack-custom .fa-stack-sm {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  font-size: 0.625em;
  text-align: center;
  transform: translateY(-50%);
}

.rating {
  justify-content: space-around;
  margin-bottom: 1rem;
}

.rating-img {
  padding: .25rem;
  text-align: center;
  border: solid 3px transparent;
  border-radius: 0.5rem;
}

.rating-txt {
  font-size: 0.875rem;
  line-height: 1.25;
  text-align: center;
}

.rating-label {
  max-width: 100%;
  margin-bottom: 0;
  cursor: pointer;
}

.rating-check {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.rating-check:checked ~ .rating-img {
  background-color: #fff;
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.rating-check:focus ~ .rating-img {
  background-color: #fff;
  border-color: #ffc74f;
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.library-icons {
  display: flex;
  flex: 0 0 auto;
  width: auto;
}

.library-icon {
  width: 2rem;
  height: 2rem;
  margin-left: .325rem;
}

.library-icon:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #003d6f;
  border-radius: 0.25rem;
}

.white-space {
  white-space: pre-wrap;
}

.picker.picker-dialog-bg {
  z-index: 1100;
}

.picker.picker-dialog {
  z-index: 1101;
}

.link-icon {
  position: relative;
  margin-right: 0.3125rem;
}

.link-icon .fa,
.link-icon .fa-brands,
.link-icon .fa-duotone,
.link-icon .fa-light,
.link-icon .fa-regular,
.link-icon .fa-solid,
.link-icon .fa-thin,
.link-icon .fab,
.link-icon .fad,
.link-icon .fal,
.link-icon .far,
.link-icon .fas,
.link-icon .fat {
  margin-left: 0.25em;
  font-size: 0.875em;
  text-decoration: none;
}

.link-icon .fa:hover, .link-icon .fa:focus,
.link-icon .fa-brands:hover,
.link-icon .fa-brands:focus,
.link-icon .fa-duotone:hover,
.link-icon .fa-duotone:focus,
.link-icon .fa-light:hover,
.link-icon .fa-light:focus,
.link-icon .fa-regular:hover,
.link-icon .fa-regular:focus,
.link-icon .fa-solid:hover,
.link-icon .fa-solid:focus,
.link-icon .fa-thin:hover,
.link-icon .fa-thin:focus,
.link-icon .fab:hover,
.link-icon .fab:focus,
.link-icon .fad:hover,
.link-icon .fad:focus,
.link-icon .fal:hover,
.link-icon .fal:focus,
.link-icon .far:hover,
.link-icon .far:focus,
.link-icon .fas:hover,
.link-icon .fas:focus,
.link-icon .fat:hover,
.link-icon .fat:focus {
  text-decoration: none;
}

.link-icon .fa-external-link {
  top: .125em;
}

.card-recent .card-header {
  padding: .25rem 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  border-bottom: 0;
}

.card-recent .card-body {
  padding: .5rem 1rem;
}

.card-recent h3, .card-recent .h3 {
  font-size: 1rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
}

.card-newguide .card-img {
  padding: 1rem;
  margin: 1rem 1rem .5rem;
  background-color: #fff;
  border-radius: calc(0.5rem - 1px);
}

.card-newguide .card-img img {
  display: block;
  max-width: 66.666666%;
  margin: 0 auto;
}

.card-newguide h3, .card-newguide .h3 {
  font-size: 1rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
}

.card-library .card-header {
  padding: .25rem 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  border-bottom: 0;
}

.card-library .card-header .col {
  display: flex;
  align-items: center;
}

.card-library .card-body {
  padding: .5rem 1rem;
}

.card-library .card-title {
  margin-bottom: 0;
  font-size: 1rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
}

.box {
  min-height: calc(1.5rem + 1rem);
  padding: calc(.5rem - 3px);
  margin-bottom: 1rem;
  word-break: break-word;
  border: 3px solid transparent;
  border-radius: 0.375rem;
}

.box .box {
  margin-bottom: .5rem;
}

.box-primary {
  color: #3d3d3d;
  background-color: #ffc74f;
}

.box-autofill {
  color: #3d3d3d;
  background-color: #acdcf1;
}

.box-unfilled {
  color: #3d3d3d;
  background-color: #f6fea2;
}

.box-row-close {
  margin-top: -.5rem;
  margin-right: -.5rem;
  text-align: right;
}

.connector {
  width: 100%;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
}

.connector:not(.bg-autofill) {
  padding-bottom: 0;
}

.connector.bg-autofill {
  margin-top: .5rem;
}

@media (min-width: 36em) {
  .connector {
    width: 50%;
  }
}

@media (min-width: 48em) {
  .connector {
    width: 41.666667%;
  }
}

@media (min-width: 62em) {
  .connector {
    width: 33.333333%;
  }
}

.has-sidebar-open .content-sidebar .connector {
  width: 66.666667%;
}

.bracket {
  position: relative;
  display: flex;
  justify-content: center;
  height: 66px;
  margin: -1rem 0 0;
  pointer-events: none;
}

.bracket-short {
  height: 33px;
}

.bracket-tall {
  height: 90px;
}

.bracket-print {
  display: none;
}

.bracket-print + .box {
  margin-top: 0;
}

.bracket-action {
  position: absolute;
  top: .5rem;
  left: 0;
  pointer-events: auto;
}

.bracket-start {
  width: 32px;
  height: 66px;
  content: url("../img/bracket-start.png");
}

.bracket-down .bracket-start {
  content: url("../img/bracket-down-start.png");
}

.bracket-end {
  width: 32px;
  height: 66px;
  content: url("../img/bracket-end.png");
}

.bracket-down .bracket-end {
  content: url("../img/bracket-down-end.png");
}

.bracket-arrow {
  width: 49px;
  height: 66px;
  content: url("../img/bracket-arrow.png");
}

.bracket-down .bracket-arrow {
  content: url("../img/bracket-down-arrow.png");
}

.bracket-bar {
  width: 20%;
  height: 66px;
  content: url("../img/bracket-bar.png");
}

.bracket-down .bracket-bar {
  content: url("../img/bracket-down-bar.png");
}

.bracket-bar-start {
  width: 32px;
  height: 66px;
  content: url("../img/bracket-bar-start.png");
}

.bracket-down .bracket-bar-start {
  content: url("../img/bracket-down-bar-start.png");
}

.bracket-bar-end {
  width: 32px;
  height: 66px;
  content: url("../img/bracket-bar-end.png");
}

.bracket-down .bracket-bar-end {
  content: url("../img/bracket-down-bar-end.png");
}

.bracket-arrow-vertical {
  width: 49px;
  height: 66px;
  content: url("../img/bracket-arrow-vertical.png");
}

.bracket-short .bracket-arrow-vertical {
  height: 33px;
  content: url("../img/bracket-arrow-vertical-short.png");
}

.bracket-tall .bracket-arrow-vertical {
  height: 90px;
  content: url("../img/bracket-arrow-vertical-tall.png");
}

.bracket-bar-vertical {
  width: 49px;
  height: 66px;
  content: url("../img/bracket-bar-vertical.png");
}

.bracket-short .bracket-bar-vertical {
  height: 33px;
  content: url("../img/bracket-bar-vertical-short.png");
}

.bracket-tall .bracket-bar-vertical {
  height: 90px;
  content: url("../img/bracket-bar-vertical-tall.png");
}

.bracket-bar-vertical-stretch {
  height: 100%;
  background-image: url("../img/bracket-bar-vertical.png");
  background-repeat: repeat-y;
  background-position: calc(50% + 1px) center;
}

@media (max-width: 47.98em) {
  .bracket-2 > :not(.bracket-arrow-vertical),
  .bracket-3 > :not(.bracket-arrow-vertical),
  .bracket-4 > :not(.bracket-arrow-vertical) {
    display: none;
  }
}

@media print, (min-width: 48em) {
  .bracket-2 > .bracket-arrow-vertical,
  .bracket-3 > .bracket-arrow-vertical,
  .bracket-4 > .bracket-arrow-vertical {
    display: none;
  }
}

.bracket-3 .bracket-start + .bracket-bar {
  width: 24%;
}

.bracket-3 .bracket-bar-start + .bracket-bar {
  width: 0%;
}

.bracket-3 .bracket-arrow + .bracket-bar {
  width: calc(24% + 32px);
}

.bracket-4 .bracket-start + .bracket-bar,
.bracket-4 .bracket-bar-end + .bracket-bar {
  width: 22%;
}

.bracket-4 .bracket-bar-start + .bracket-bar,
.bracket-4 .bracket-arrow + .bracket-bar {
  width: 7%;
}

.grouping {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

@media (max-width: 47.98em) {
  .grouping {
    flex-direction: column;
  }
}

.grouping > * {
  flex: 0 0 auto;
  max-width: 100%;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.grouping + .grouping {
  margin-top: 1.5rem;
}

.grouping-title {
  flex: 0 0 100%;
  margin-top: .75rem;
  margin-bottom: 0;
}

@media print, (min-width: 48em) {
  .grouping-title {
    margin-top: -1.5rem;
  }
}

:not(.bracket) + .grouping .grouping-title {
  margin-top: 0;
}

h2 + .row .review-main .grouping-title, .h2 + .row .review-main .grouping-title,
h2 + .row .col-lg-8 .grouping-title,
.h2 + .row .col-lg-8 .grouping-title,
h3 + .row .review-main .grouping-title,
.h3 + .row .review-main .grouping-title,
h3 + .row .col-lg-8 .grouping-title,
.h3 + .row .col-lg-8 .grouping-title,
h4 + .row .review-main .grouping-title,
.h4 + .row .review-main .grouping-title,
h4 + .row .col-lg-8 .grouping-title,
.h4 + .row .col-lg-8 .grouping-title,
h5 + .row .review-main .grouping-title,
.h5 + .row .review-main .grouping-title,
h5 + .row .col-lg-8 .grouping-title,
.h5 + .row .col-lg-8 .grouping-title,
h6 + .row .review-main .grouping-title,
.h6 + .row .review-main .grouping-title,
h6 + .row .col-lg-8 .grouping-title,
.h6 + .row .col-lg-8 .grouping-title {
  margin-top: 0;
}

.grouping-text {
  flex: 1 0;
  width: 100%;
}

.grouping-img {
  width: 100%;
  margin-top: .5rem;
}

.grouping-img:empty {
  display: none;
}

@media (min-width: 48em) {
  .grouping-img {
    width: 41.666667%;
    margin-top: 0;
    text-align: right;
  }
}

.grouping-img .img-fluid {
  max-height: 30vw;
}

@media (min-width: 48em) {
  .grouping-img .img-fluid {
    max-height: 25vw;
  }
}

@media (min-width: 62em) {
  .grouping-img .img-fluid {
    max-height: 20vw;
  }
}

@media (min-width: 75em) {
  .grouping-img .img-fluid {
    max-height: 15vw;
  }
}

.grouping-item {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.grouping-item > .box {
  flex-grow: 1;
}

@media print, (min-width: 48em) {
  .grouping-item {
    flex: 1 0;
  }
}

.grouping-item + .grouping-item::before {
  display: block;
  width: 49px;
  height: 33px;
  margin: -1rem auto 0;
  content: "";
  background-image: url("../img/bracket-bar-vertical-short.png");
}

@media print, (min-width: 48em) {
  .grouping-item + .grouping-item::before {
    display: none;
  }
}

.grouping-item + .grouping-item .grouping-item-title {
  position: absolute;
  top: -.5rem;
}

@media print, (min-width: 48em) {
  .grouping-item + .grouping-item .grouping-item-title {
    top: 0;
    margin-top: 0;
  }
}

.bracket + .grouping .grouping-item-title {
  position: absolute;
  top: -1.5rem;
}

.row + .row .review-main .grouping-title,
.row + .row .col-lg-8 .grouping-title,
.bracket-down + .grouping .grouping-title {
  position: absolute;
  top: -2.25rem;
}

@media print, (min-width: 48em) {
  .row + .row .review-main .grouping-title,
  .row + .row .col-lg-8 .grouping-title,
  .bracket-down + .grouping .grouping-title {
    top: -1.25rem;
  }
}

@media print, (max-width: 47.98em) {
  .row + .row .review-main .grouping-title,
  .row + .row .col-lg-8 .grouping-title,
  .bracket-down + .grouping .grouping-title {
    top: 0;
  }
}

.grouping-match .grouping-item > .box {
  flex-grow: 1;
}

.grouping-icon {
  width: 100%;
  margin-top: .75rem;
}

.grouping-icon .fa {
  position: relative;
  top: -.25rem;
  display: block;
  align-self: center;
  line-height: 1.5;
  text-align: center;
}

@media (min-width: 48em) {
  .grouping-icon {
    width: auto;
  }
}

.grouping-icon-title-offset {
  margin-top: 2.375rem;
}

@media print, (min-width: 48em) {
  .grouping-1 .grouping-title {
    top: 0 !important;
  }
  .grouping-1 .grouping-img {
    width: 25%;
  }
}

@media print, (min-width: 48em) {
  .grouping-2 .grouping-item {
    width: 50%;
  }
  .grouping-2 .grouping-img {
    width: 50%;
  }
}

@media print, (min-width: 48em) {
  .grouping-3 .grouping-item {
    width: 33.333333%;
  }
  .grouping-3 .grouping-text {
    flex: 0 0 auto;
  }
  .grouping-3 .grouping-img {
    width: 66.666667%;
  }
}

@media print, (min-width: 48em) {
  .grouping-4 .grouping-item {
    width: 25%;
  }
  .grouping-4 .grouping-text {
    flex: 0 0 auto;
  }
  .grouping-4 .grouping-img {
    width: 75%;
  }
}

.has-sidebar-open .content-sidebar .bracket-2 > :not(.bracket-arrow-vertical),
.has-sidebar-open .content-sidebar .bracket-3 > :not(.bracket-arrow-vertical),
.has-sidebar-open .content-sidebar .bracket-4 > :not(.bracket-arrow-vertical) {
  display: none;
}

.has-sidebar-open .content-sidebar .bracket-2 > .bracket-arrow-vertical,
.has-sidebar-open .content-sidebar .bracket-3 > .bracket-arrow-vertical,
.has-sidebar-open .content-sidebar .bracket-4 > .bracket-arrow-vertical {
  display: block;
}

.has-sidebar-open .content-sidebar .grouping-item {
  flex: 0 0 auto;
  width: 100%;
}

.has-sidebar-open .content-sidebar .grouping-item::before {
  display: block !important;
}

.has-sidebar-open .content-sidebar .grouping-item + .grouping-item .grouping-item-title {
  position: absolute;
  top: -.5rem;
}

.has-sidebar-open .content-sidebar .grouping-text {
  flex: 1 0;
  width: 100%;
}

.has-sidebar-open .content-sidebar .grouping-img {
  width: 100%;
}

@media print, (min-width: 48em) {
  .has-sidebar-open .content-sidebar .grouping-img {
    width: 33.333333%;
  }
}

.has-sidebar-open .content-sidebar .grouping-icon {
  width: 100%;
  margin-top: -.5rem;
}

.has-sidebar-open .content-sidebar .bracket + .grouping .grouping-title {
  top: 0;
}

@media print, (min-width: 48em) {
  .grouping-item {
    justify-content: flex-end;
  }
}

.grouping-item > .box {
  flex-grow: 0;
}

@media print, (min-width: 48em) {
  .connector ~ .grouping .grouping-item {
    justify-content: flex-start;
  }
}

@media print, (min-width: 48em) {
  .grouping-start .grouping-item {
    justify-content: flex-start;
  }
}

@media print, (min-width: 48em) {
  .grouping-end .grouping-item {
    justify-content: flex-end;
  }
}

.grouping-lessonmap .grouping-item {
  flex-grow: 1;
}

.grouping-lessonmap .grouping-item + .grouping-item::before {
  content: none;
}

.btn .fa,
.btn .fa-brands,
.btn .fa-duotone,
.btn .fa-light,
.btn .fa-regular,
.btn .fa-solid,
.btn .fa-thin,
.btn .fab,
.btn .fad,
.btn .fal,
.btn .far,
.btn .fas,
.btn .fat {
  font-size: 1.125em;
  line-height: 1;
  vertical-align: baseline;
}

.btn:focus,
.btn-check-input:focus ~ .btn {
  border-color: #ffc74f;
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.btn-link {
  font-weight: 400;
}

.btn-round {
  width: calc(2.142857em + 2px);
  height: calc(2.142857em + 2px);
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}

.btn-create {
  width: 7rem;
  height: 5rem;
  padding: .5rem 1rem;
  margin-bottom: .5rem;
}

.btn-create-img img {
  width: 3rem;
  height: auto;
  max-height: 3rem;
}

.btn-create-img .fa {
  font-size: 2.5rem;
  line-height: 1.5;
}

.btn-google {
  width: auto;
  padding: 0 .75rem 0 0;
  overflow: hidden;
  font-family: Roboto, arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  background-color: #4285f4;
  border: 1px solid #4285f4;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}

@media (prefers-reduced-motion: reduce) {
  .btn-google {
    transition: none;
  }
}

.btn-google img {
  width: 2.125rem;
  height: 2.125rem;
  padding: .425rem;
  margin-right: .5rem;
  background-color: #fff;
}

.btn-google:hover, .btn-google:focus {
  outline: 0;
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.badge-tag {
  color: #3d3d3d;
  background-color: #acdcf1;
  border-color: #acdcf1;
}

.form-control {
  height: calc(1.5em + .25rem + 4px);
  padding-top: calc(.125rem + 1px);
  padding-bottom: calc(.125rem + 1px);
}

.form-control::-moz-placeholder {
  font-style: italic;
}

.form-control::placeholder {
  font-style: italic;
}

.form-file-dropdown {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  margin: 0;
  line-height: 1;
  opacity: 0;
}

.form-file-dropdown ~ .dropdown-item:focus ~ .dropdown-item, .form-file-dropdown:focus ~ .dropdown-item {
  color: #fff;
  background-color: #003d6f;
}

.form-file-dropdown:focus-within ~ .dropdown-item {
  color: #fff;
  background-color: #003d6f;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (min-width: 36em) {
  .form-inline label:not(.form-check-label) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.alert-topbar {
  padding: .25rem 2rem;
  margin-bottom: 0;
  text-align: center;
  border-radius: 0;
}

.alert-topbar .close {
  top: 50%;
  right: .25rem;
  padding: .25rem .5rem;
  font-size: 1rem;
  opacity: .9;
  transform: translateY(-50%);
}

.alert-topbar .close:hover, .alert-topbar .close:focus {
  opacity: 1;
}

.alert-view {
  color: #fff;
  background: #000;
  border: 0;
}

.alert-view a {
  color: #eee;
}

.alert-view a:hover, .alert-view a:focus {
  color: #fff;
}

.alert-view a:focus {
  outline: #fff solid 1px;
}

.firepad,
.firepad-form,
.firepad-form.form-control,
.firepad-form.form-control-lg {
  height: -moz-fit-content;
  height: fit-content;
}

textarea.form-control,
.firepad-form.text-area {
  min-height: calc(((1rem * 1.5) + 0.21875rem + 1px) * 2);
}

.firepad-form.text-area .CodeMirror-code {
  min-height: calc(1rem * 1.5 * 2);
}

.firepad .CodeMirror {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

.CodeMirror {
  height: -moz-fit-content;
  height: fit-content;
  font-family: inherit;
  color: #474747;
}

.CodeMirror .CodeMirror-placeholder {
  position: absolute;
  font-family: inherit;
  font-style: italic;
  font-weight: 300;
  color: #999;
}

.CodeMirror pre {
  padding: 0;
}

.powered-by-firepad {
  display: none;
}

.pagination {
  margin: 0 .25rem;
}

.page-text,
.page-link {
  font-weight: 700;
}

.page-text .fa,
.page-text .fa-brands,
.page-text .fa-duotone,
.page-text .fa-light,
.page-text .fa-regular,
.page-text .fa-solid,
.page-text .fa-thin,
.page-text .fab,
.page-text .fad,
.page-text .fal,
.page-text .far,
.page-text .fas,
.page-text .fat,
.page-link .fa,
.page-link .fa-brands,
.page-link .fa-duotone,
.page-link .fa-light,
.page-link .fa-regular,
.page-link .fa-solid,
.page-link .fa-thin,
.page-link .fab,
.page-link .fad,
.page-link .fal,
.page-link .far,
.page-link .fas,
.page-link .fat {
  font-size: 1.125em;
  line-height: 1;
  vertical-align: baseline;
}

.page-link:focus {
  color: #fff;
  background-color: #003d6f;
  border-color: #ffc74f;
  outline: 0;
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.page-link:hover {
  color: #fff;
  background-color: #00294a;
  border-color: #00294a;
}

.page-link.disabled,
.disabled > .page-link {
  opacity: 0.6;
}

.pages-prev-icon,
.pages-next-icon {
  font-size: 0.875rem;
}

@media (min-width: 36em) {
  .pages-prev-icon {
    margin-right: .25rem;
  }
}

@media (min-width: 36em) {
  .pages-next-icon {
    margin-left: .25rem;
  }
}

.footer-pages {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1019;
  display: flex;
  justify-content: center;
  max-width: 100rem;
  padding: 0.5rem 1rem;
  background-color: #d4eef4;
}

@media (max-width: 61.98em) {
  .footer-pages .page-item:not(.pages-active):not(.pages-toc):not(.pages-bpv):not(.pages-create):not(.pages-prev):not(.pages-next) {
    display: none;
  }
}

@media print {
  .footer-pages {
    display: none;
  }
}

.footer-pages-tour {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

body:not(.has-bottomnav) .footer-pages {
  display: none;
}

.modal {
  text-align: left;
}

.modal-dialog {
  padding-top: 1.25rem;
}

.modal-title {
  line-height: 1.25;
}

.modal-header {
  padding-bottom: 0;
}

.modal-body {
  padding-top: .5rem;
}

.modal .close {
  color: #003d6f;
  opacity: 1;
}

.modal .close:hover {
  color: #00294a;
  opacity: 1;
}

.modal .close:focus {
  color: #003d6f;
  border-color: #ffc74f;
  outline: 0;
  box-shadow: 0 0 0 0.125rem #003d6f;
  opacity: 1;
}

.modal-extra {
  position: relative;
  padding: 0.75rem 1rem;
}

.keyterm-modal .form-check,
.pair-modal .form-check {
  margin-bottom: .5rem;
}

.popover .img-fluid {
  max-height: none;
}

.popover.has-corgi .popover-body {
  padding-bottom: 5rem;
}

.popover.has-corgi .popover-body::before {
  position: absolute;
  bottom: 0;
  left: .75rem;
  display: block;
  width: 4rem;
  height: calc(4rem * .9310345);
  content: "";
  background-image: url("../img/corgi left-sm.png");
  background-size: cover;
}

.popover.has-corgi .corgi-offset {
  padding-top: 1.5rem;
  margin-bottom: -4rem;
  margin-left: 4.5rem;
}

.onboard-popover {
  width: 21rem;
  max-width: 21rem;
}

.onboard-dots {
  font-size: 0.5rem;
  white-space: nowrap;
}

.onboard-dots .active {
  color: #de7802;
}

.onboarding {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.onboarding .popover {
  pointer-events: auto;
}

.skipnav {
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 1025;
  display: block;
  max-width: 100%;
  padding: .25rem .5rem;
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #343e48;
}

.skipnav:focus {
  top: 0;
  left: 0;
  color: #fff;
}

.skipnav-target {
  outline: 0;
}

.skipnav-main {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

:root {
  --header-offset: 3rem;
  scroll-margin: var(--header-offset);
}

:target {
  scroll-margin: var(--header-offset);
  background-clip: content-box;
}

:target:not(.main) {
  display: inline-block;
}

.site-header {
  position: sticky;
  top: 0;
  z-index: 1021;
  display: flex;
  justify-content: space-between;
  background-color: #d4eef4;
}

.site-header .offcanvas-body {
  font-size: 0.875rem;
}

@media print {
  .site-header {
    display: none;
  }
}

.site-header-end {
  display: flex;
  padding: .5rem 1rem;
  margin-left: auto;
}

.header-menu-btn {
  padding-top: .25rem;
  padding-bottom: .125rem;
}

.header-menu-btn .fa-bars {
  font-size: 1.25rem;
}

.header-brand {
  display: inline-flex;
  align-items: center;
  margin-left: .25rem;
  text-decoration: none;
}

.header-brand:hover, .header-brand:focus {
  text-decoration: underline;
}

.header-logo {
  display: inline-block;
  width: 2.5rem;
  height: 120%;
  margin-top: -.375rem;
  margin-right: .5rem;
  margin-bottom: calc((.5rem + 3px) * -1);
}

.header-title {
  padding: 0;
  margin-right: .25rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
  font-size: 1.25rem;
  font-weight: 700;
  color: #000;
  vertical-align: middle;
}

.header-tagline {
  font-size: 0.875rem;
  color: #000;
}

@media (max-width: 51.98em) {
  .header-tagline {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .header-tagline:not(caption) {
    position: absolute !important;
  }
}

.header-tts {
  position: fixed;
  top: 0;
  left: Min(50%, 800px);
  z-index: 1066;
  display: flex;
  padding: .5rem;
  background-color: #d4eef4;
  border-radius: 0 0 0.375rem 0.375rem;
  transform: translateX(-50%);
}

.header-tts .btn-tts:not(:first-child) {
  margin-left: .25rem;
}

.header-tts .tts-pause {
  display: none;
}

.header-tts.playing .tts-play {
  display: none;
}

.header-tts.playing .tts-pause {
  display: inline-block;
}

@media print {
  .header-tts {
    display: none;
  }
}

.header-collab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: .5rem;
  margin-right: .5rem;
  border-right: 1px solid #bfc6cd;
}

.header-collab .media {
  margin-bottom: 0;
  white-space: nowrap;
}

.header-collab-count {
  display: inline-block;
  margin-left: .125rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: #003d6f;
}

.header-user {
  position: relative;
}

.header-btns {
  display: inline-flex;
  align-items: center;
}

.footer-site {
  position: relative;
  z-index: 10;
  padding: 1rem 0 0;
  clear: both;
  background-color: #f2f2f2;
  border-top: 1px solid #bfc6cd;
}

.footer-site a:not(.btn) {
  text-decoration: underline;
  text-underline-position: auto;
}

.footer-site a:not(.btn):hover, .footer-site a:not(.btn):focus {
  text-decoration: underline;
}

.footer-site-brand {
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.footer-site-row {
  margin: 0 0 1rem;
  border-top: 1px solid #bfc6cd;
  border-bottom: 1px solid #bfc6cd;
}

.footer-site-row + .footer-row {
  margin-top: -1rem;
  border-top: 0;
}

.footer-site-row .list-unstyled {
  margin-bottom: 0.25rem;
}

.footer-site-row .list-unstyled li {
  margin-bottom: 0.75rem;
  line-height: 1.25;
}

.footer-site-row .list-unstyled li:last-child {
  margin-bottom: 0;
}

.footer-site-row .list-unstyled .media-object {
  margin-right: 0.75rem;
}

.footer-site-row > .col {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-right: 1px solid #bfc6cd;
}

.footer-site-row > .col:first-child {
  padding-left: 0;
}

.footer-site-row > .col:last-child {
  padding-right: 0;
  border-right: 0;
}

@media (max-width: 47.98em) {
  .footer-site-row > .col {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    border-right: 0;
  }
}

@media (min-width: 48em) {
  .footer-site-row .col {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 48em) and (max-width: 61.98em) {
  .footer-site-row > .col {
    flex-basis: 50%;
    max-width: 50%;
  }
  .footer-site-row > .col + .col:nth-child(odd) {
    padding-left: 0;
  }
  .footer-site-row > .col + .col:nth-child(even) {
    padding-right: 0;
    border-right: 0;
  }
}

.footer-site-header {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.footer-site-media {
  color: #3d3d3d;
}

.footer-site-media .media-object {
  width: 6rem;
  max-width: 6rem;
  margin-right: 1rem;
}

.footer-site-media .media-body {
  font-size: 0.875rem;
}

.footer-site-media .media-body p:last-child {
  margin-bottom: 0;
}

.footer-site-copy {
  align-items: baseline;
  justify-content: space-between;
  font-size: 0.875rem;
}

@media (max-width: 61.98em) {
  .footer-site-copy > .col {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (min-width: 62em) {
  .footer-site-copy > .col:last-child {
    text-align: right;
  }
}

.footer-site-copy .list-horizontal {
  align-items: baseline;
}

.footer-site-copy .list-horizontal .list-item:not(:last-child) {
  margin-right: 1em;
}

@media (max-width: 35.98em) {
  .footer-site-copy .list-horizontal {
    flex-direction: column;
    margin-bottom: 0;
  }
  .footer-site-copy .list-horizontal .list-item {
    margin-right: 0;
    margin-bottom: .75rem;
  }
}

.footer-site-social {
  font-size: 1.5rem;
  color: #074a7b;
}

.footer-site-social:hover, .footer-site-social:focus {
  color: #00294a;
}

.footer-site-logo {
  display: inline-block;
}

.footer-site-logo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.footer-site-title {
  display: inline-block;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
  font-size: 1.25rem;
  font-weight: 700;
}

.footer-site-tagline {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
}

.footer-cast {
  position: relative;
  z-index: 10;
  padding: 1rem 0;
  clear: both;
  color: #fff;
  background-color: #301c5c;
  border-top: 0 solid #fff;
}

.footer-cast a:not(.btn) {
  color: #b5cc52;
  text-decoration: underline;
  text-underline-position: auto;
}

.footer-cast a:not(.btn):hover, .footer-cast a:not(.btn):focus {
  color: #fff;
  text-decoration: underline;
}

.footer-cast-brand {
  margin-bottom: 0.5rem;
}

.footer-cast-row {
  margin: 0 0 1rem;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.footer-cast-row + .footer-row {
  margin-top: -1rem;
  border-top: 0;
}

.footer-cast-row .list-unstyled {
  margin-bottom: 0.25rem;
}

.footer-cast-row .list-unstyled li {
  margin-bottom: 0.75rem;
  line-height: 1.25;
}

.footer-cast-row .list-unstyled li:last-child {
  margin-bottom: 0;
}

.footer-cast-row .list-unstyled .media-object {
  margin-right: 0.75rem;
}

.footer-cast-row > .col {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-right: 1px solid #fff;
}

.footer-cast-row > .col:first-child {
  padding-left: 0;
}

.footer-cast-row > .col:last-child {
  padding-right: 0;
  border-right: 0;
}

@media (max-width: 47.98em) {
  .footer-cast-row > .col {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    border-right: 0;
  }
}

@media (min-width: 48em) {
  .footer-cast-row .col {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 48em) and (max-width: 61.98em) {
  .footer-cast-row > .col {
    flex-basis: 50%;
    max-width: 50%;
  }
  .footer-cast-row > .col + .col:nth-child(odd) {
    padding-left: 0;
  }
  .footer-cast-row > .col + .col:nth-child(even) {
    padding-right: 0;
    border-right: 0;
  }
}

.footer-cast-header {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.footer-cast-media {
  color: #f1f1f1;
}

.footer-cast-media .media-object {
  width: 6rem;
  max-width: 6rem;
  margin-right: 1rem;
}

.footer-cast-media .media-body {
  font-size: 0.875rem;
}

.footer-cast-media .media-body p:last-child {
  margin-bottom: 0;
}

.footer-cast-copy {
  align-items: baseline;
  justify-content: space-between;
  font-size: 0.875rem;
}

@media (max-width: 61.98em) {
  .footer-cast-copy > .col {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media (min-width: 62em) {
  .footer-cast-copy > .col:last-child {
    text-align: right;
  }
}

.footer-cast-copy .list-horizontal {
  align-items: baseline;
}

.footer-cast-copy .list-horizontal .list-item:not(:last-child) {
  margin-right: 1em;
}

@media (max-width: 35.98em) {
  .footer-cast-copy .list-horizontal {
    flex-direction: column;
    margin-bottom: 0;
  }
  .footer-cast-copy .list-horizontal .list-item {
    margin-right: 0;
    margin-bottom: .75rem;
  }
}

.footer-cast-social {
  font-size: 1.5rem;
  color: #84d4e5;
}

.footer-cast-social:hover, .footer-cast-social:focus {
  color: #fff;
}

.footer-cast-btn-donate {
  color: #212121;
  background-color: #b5cc52;
  border-color: #b5cc52;
}

.footer-cast-btn-donate:hover {
  color: #212121;
  background-color: #cbdb86;
  border-color: #c4d675;
}

.footer-cast-btn-donate:focus {
  box-shadow: 0 0 0 0.125rem #b5cc52;
}

.footer-cast-btn-donate.disabled,
.footer-cast-btn-donate:disabled {
  color: #212121;
  background-color: #b5cc52;
  border-color: #b5cc52;
}

.footer-cast-btn-donate:active,
.footer-cast-btn-donate.active,
.footer-cast-btn-donate.open[data-cfw="dropdown"] {
  color: #212121;
  background-color: #d3e097;
  border-color: #cbdb86;
}

.footer-cast-logo {
  display: inline-block;
  width: 18rem;
  height: 1.75rem;
}

.footer-cast-logo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 62em) {
  .footer-cast-logo {
    width: 22.75rem;
    height: 1.875rem;
  }
}

.footer-cast-title {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.footer-cast-title:not(caption) {
  position: absolute !important;
}

.footer-cast-tagline {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.footer-cast-tagline:not(caption) {
  position: absolute !important;
}

.content.has-sidebar .content-sidebar {
  display: block;
  flex: 0 0 auto;
  width: 8rem;
}

.content.has-sidebar-open .content-sidebar {
  display: block;
  flex: 0 0 auto;
  width: 33%;
}

.content.has-sidebar-expand .content-sidebar {
  display: block;
  flex: 0 0 auto;
  width: 90%;
}

.content.has-sidebar-expand .content-main {
  visibility: hidden;
}

.content.has-sidebar-expand #sidebarActionExpand {
  display: none;
}

.content:not(.has-sidebar-expand) #sidebarActionCollapse {
  display: none;
}

.content:not(.has-sidebar-open):not(.has-sidebar-expand) .content-sidebar-action,
.content:not(.has-sidebar-open):not(.has-sidebar-expand) .content-sidebar-panel {
  display: none;
}

.content:not(.has-sidebar-open):not(.has-sidebar-expand) .nav-link-txt {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.content:not(.has-sidebar-open):not(.has-sidebar-expand) .nav-link-txt:not(caption) {
  position: absolute !important;
}

.content.has-sidebar-open .nav-link-icon,
.content.has-sidebar-expand .nav-link-icon {
  margin-right: .25rem;
}

.content-sidebar .nav-tabs {
  padding-left: .5rem;
  border-bottom: 0;
}

.content-sidebar .nav-item + .nav-item {
  margin-left: .25rem;
}

.content-sidebar .nav-link {
  padding: 0.21875rem 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 700;
  border-bottom: 0;
}

.content-sidebar .nav-link .fa,
.content-sidebar .nav-link .fa-brands,
.content-sidebar .nav-link .fa-duotone,
.content-sidebar .nav-link .fa-light,
.content-sidebar .nav-link .fa-regular,
.content-sidebar .nav-link .fa-solid,
.content-sidebar .nav-link .fa-thin,
.content-sidebar .nav-link .fab,
.content-sidebar .nav-link .fad,
.content-sidebar .nav-link .fal,
.content-sidebar .nav-link .far,
.content-sidebar .nav-link .fas,
.content-sidebar .nav-link .fat {
  font-size: 1.125em;
  line-height: 1;
  vertical-align: baseline;
}

.content-sidebar .nav-link:not(.active) {
  color: #fff;
  background-color: #003d6f;
}

.content-sidebar .nav-link:hover {
  box-shadow: none;
}

.content-sidebar .nav-link:focus {
  border-color: #ffc74f;
  outline: 0;
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.content-sidebar-container {
  position: relative;
  height: calc(100% - 1.75rem - 2px);
  background-color: #d4eef4;
  border-top-right-radius: 0.5rem;
}

.content-sidebar-action {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 2rem;
  padding-right: .5rem;
}

.content-sidebar-action .btn-link {
  padding: 0;
}

.content-sidebar-action .btn + .btn {
  margin-left: .5rem;
}

.content-sidebar-panel:not(.in) {
  display: none;
}

.content-sidebar-panel.in {
  position: absolute;
  top: 2rem;
  left: 0;
  display: block;
  width: 100%;
  height: calc(100% - 2rem);
  overflow-x: hidden;
  overflow-y: scroll;
}

.content-sidebar-inner {
  max-width: 56.25rem;
  padding: .75rem .75em 1.5em;
  margin: 0 auto;
}

.content-sidebar-inner .section {
  margin-bottom: 1.5rem;
}

@media (min-width: 62em) {
  .has-sidebar-expand .content-sidebar-sticky-top {
    position: sticky;
    top: -2px;
    z-index: 1010;
    background-color: #d4eef4;
  }
}

@media (min-width: 75em) {
  .content-sidebar-sticky-top {
    position: sticky;
    top: -2px;
    z-index: 1010;
    background-color: #d4eef4;
  }
}

.login-splash {
  position: relative;
  padding: 1rem .5rem 3rem;
  margin-top: -2rem;
  background-image: url("../img/loginSplash.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

@media (max-width: 47.98em) {
  .login-splash {
    background-position: top right;
  }
  .login-splash .container-fluid, .login-splash .container-sm, .login-splash .container-md, .login-splash .container-lg, .login-splash .container-xl {
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 48em) {
  .login-splash {
    height: calc(100% * (1423 / 2560));
  }
}

.login-splash-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.875);
}

@media (min-width: 48em) {
  .login-splash-overlay {
    display: none;
  }
}

.login-splash-nav .nav-link {
  font-weight: 700;
  text-decoration: underline;
}

@media (max-width: 47.98em) {
  .login-splash-nav {
    margin-top: -1rem;
  }
  .login-splash-nav .navbar-nav {
    position: absolute;
    top: 2.875rem;
    right: 0;
    left: 0;
    display: block;
    background-color: #d4eef4;
    border-radius: 0.5rem;
  }
  .login-splash-nav .navbar-nav .nav-link {
    display: block;
    padding: .125rem .75rem;
  }
}

@media (min-width: 48em) {
  .login-splash-nav .navbar-nav {
    display: inline-flex;
    flex-wrap: wrap;
    padding: .25rem .5rem;
    margin-left: -1rem;
    font-weight: 700;
  }
  .login-splash-nav .navbar-nav > .list-item {
    padding-left: 0 !important;
    margin-right: 0.5em !important;
  }
}

.login-splash-logo {
  display: flex;
  align-items: flex-end;
  margin-top: 1.5rem;
}

.login-splash-logo-img {
  width: 1em;
  height: 1em;
  font-size: 9rem;
}

@media (min-width: 48em) {
  .login-splash-logo-img {
    font-size: 6rem;
  }
}

.login-splash-logo-text {
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
  font-size: 6rem;
}

@media (min-width: 48em) {
  .login-splash-logo-text {
    font-size: 4.5rem;
  }
}

.login-splash-lead {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: Bitter, Georgia, "Times New Roman", Times, serif;
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.25;
  color: #003d6f;
}

@media (min-width: 48em) {
  .login-splash-lead {
    padding: .25rem .5rem;
    font-size: 1.875rem;
    background-color: rgba(255, 255, 255, 0.875);
  }
}

.login-slides {
  position: relative;
}

.login-slides-prev,
.login-slides-next {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: .125rem;
}

.login-slides-next {
  right: 0;
  left: auto;
}

.login-slide-text {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  min-height: 3rem;
  padding: .5rem 3rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.875);
}

.login-card {
  background-color: #f2f2f2;
}

.login-card .card-footer {
  background-color: transparent;
  border: 0;
}

.login-media {
  margin-bottom: 0;
}

.login-media-icon {
  margin-right: 1rem;
  font-size: 5rem;
}

.stt-wrapper {
  position: relative;
}

.stt-wrapper textarea,
.stt-wrapper input {
  padding-right: 1.5rem;
}

.stt-wrapper .CodeMirror-code {
  padding-right: 1rem;
}

.stt-wrapper:focus-within .stt-btn:not(:hover):not(:focus):not(.active) {
  color: #003d6f;
  opacity: .7;
}

.stt-btn {
  position: absolute;
  top: .25em;
  right: 0;
  z-index: 100;
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 1rem;
  color: #666;
  text-align: center;
  cursor: pointer;
  content: " ";
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 50%;
  opacity: .2;
}

.stt-btn.enabled {
  display: block;
}

.stt-btn:hover, .stt-btn:focus {
  color: #003d6f;
  opacity: 1;
}

.stt-btn:focus {
  border-color: #ffc74f;
  outline: 0;
  box-shadow: 0 0 0 0.125rem #003d6f;
}

.stt-btn.disabled {
  display: block;
  color: #ddd;
  cursor: not-allowed;
}

.stt-btn.active {
  display: block;
  color: #fff;
  background-color: #003d6f;
  opacity: 1;
}

.video-wrapper {
  margin-bottom: 1rem;
}

.player {
  display: none;
}

.player.ready {
  display: flex;
}

.player-wrapper {
  margin-top: .25rem;
}

.player-control {
  white-space: nowrap;
}

.player-control .off {
  display: none;
}

.player-time {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  align-self: center;
  margin-right: .5rem;
  margin-left: .5rem;
}

.player-seek {
  flex: 0 1 100%;
}

.player-seek .form-range {
  min-width: auto;
}

.btn[data-cfw-player="caption"] {
  margin-right: .25rem;
}

.player-fullscreen {
  display: inline-block;
}

.player-fullscreen .player-fullscreen-on {
  display: none;
}

.player-fullscreen .player-fullscreen-off {
  display: inline-block;
}

.player-fullscreen.active .player-fullscreen-on {
  display: inline-block;
}

.player-fullscreen.active .player-fullscreen-off {
  display: none;
}

.player-unstarted .embed-fluid:hover {
  cursor: pointer;
}

.player-unstarted .embed-fluid::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  padding: 5px;
  font-family: "Font Awesome 5 Free";
  font-size: 35px;
  font-weight: 900;
  line-height: 1;
  color: #3d3d3d;
  pointer-events: none;
  content: "\f04b";
  background-color: #ffc74f;
  border: 4px solid #3d3d3d;
  border-radius: 50%;
  opacity: .8;
  transform: translate3d(-50%, -50%, 0);
}

.player-unstarted .embed-fluid:hover::after {
  opacity: 1;
}

.player-notime .player-time-current,
.player-notime .player-time-duration,
.player-notime .player-seek,
.player-live .player-time-duration,
.player-live .player-seek {
  visibility: hidden;
}

.player-fulldisplay {
  position: relative;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100%;
  padding: 0;
  margin: 0;
  background: #000;
}

.player-fulldisplay .player-wrapper {
  position: absolute;
  right: 0;
  bottom: 1rem;
  left: 0;
  margin-top: 0;
  text-align: center;
  pointer-events: none;
}

.player-fulldisplay .player {
  display: inline-flex;
  width: 30rem;
  max-width: 100vw;
  padding: .5rem;
  margin: 0;
  color: #222;
  pointer-events: auto;
  background-color: #d4eef4;
  border: 1px solid #9eaab4;
  border-radius: 0.375rem;
}

.player-fulldisplay.player-inactive {
  cursor: none;
}

.player-fulldisplay.player-inactive .player {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s;
}

@media (prefers-reduced-motion: reduce) {
  .player-fulldisplay.player-inactive .player {
    transition: none;
  }
}

.player-fulldisplay.player-inactive.player-paused .player {
  visibility: visible;
  opacity: 1;
  transition: all 0.15s;
}

@media (prefers-reduced-motion: reduce) {
  .player-fulldisplay.player-inactive.player-paused .player {
    transition: none;
  }
}

.player-caption-display {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  display: block;
  max-width: 100%;
  padding: .25rem;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 0.25rem;
  transform: translateX(-50%);
}

.player-fulldisplay .player-caption-display {
  bottom: 5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

@page {
  size: a4;
  margin: 15mm 0;
}

@media print {
  *,
  *::before,
  *::after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  p, h2, .h2, h3, .h3 {
    orphans: 3;
    widows: 3;
  }
  h2, .h2, h3, .h3 {
    page-break-after: avoid;
  }
  .main {
    min-height: none !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .box,
  .box-print,
  .box-autofill,
  .box-unfilled {
    background: transparent !important;
    border: 3px solid #000 !important;
  }
  .print-nobreak {
    page-break-inside: avoid;
  }
  .footer-pages,
  .footer-site {
    display: none;
  }
  .bracket {
    display: flex;
  }
  .bracket > :not(.bracket-arrow-vertical) {
    display: block;
  }
  .box,
  .grouping {
    page-break-inside: avoid;
  }
  .connector {
    width: 50% !important;
  }
}
/*# sourceMappingURL=corgi.css.map */