.container-max {
    position: relative;
    width: 100%;
    max-width: $container-max-width;
}

h3,
.h3 {
    font-family: $font-family-base;
}

.dropdown-menu,
.tooltip-body {
    box-shadow: 0 .125rem .1875rem rgba(0, 0, 0, .15);
}

.page-wrapper {
    min-height: calc(100vh - 3rem);
    background-color: $main-bg;

    .has-bottomnav & {
        min-height: calc(100vh - 6rem);
    }

}

.content {
    height: 100%;
    margin: 0;
    background-color: $main-bg;

    &.row > * {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .content-sidebar {
        padding-left: 0;
    }
    &.has-sidebar .content-main {
        padding-right: 2rem;
    }
}
.content-sidebar {
    position: sticky;
    top: 3rem;
    left: 0;
    display: none;
    height: 100%;
    padding-top: 1rem;

    .has-bottomnav & {
        height: calc(100vh - 6rem);
    }
}

.content-main-inner {
    max-width: $content-inner-max-width;
    padding-top: 2rem;
    padding-bottom: 10rem;
    margin: 0 auto;
}

.content-main-sticky-top {
    @include media-breakpoint-up(md) {
        position: sticky;
        top: 3rem;
        z-index: $zindex-sticky;
        background-color: $main-bg;
    }
}

.review-anno {
    display: none;
}
.annoview {
    .content-main-inner {
        max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
        .review-main {
            width: percentage(2 / 3);
        }
        .review-anno {
            display: block;
            width: percentage(1 / 3);
        }
    }
}

.avatar-img,
.collab-img {
    width: 2rem;
    height: 2rem;
    @include border-radius(50%);
}
.slide-img {
    width: 1rem;
    height: 1rem;
    vertical-align: baseline;
}

.list-nostyle {
    list-style: none;
}

.section {
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.section-border-top {
    padding-top: 1.5rem;
    border-top: 1px solid currentcolor;
}
.section-border-top-dashed {
    padding-top: 1.5rem;
    border-top: 3px dashed currentcolor;
}

.hinline {
    padding-right: 2rem;
    margin-bottom: .5rem;

    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
        display: inline;
        padding-right: 0;
        margin-right: .25em;
        margin-bottom: 0;
    }

    & > .btn-info {
        vertical-align: text-bottom;
    }
}

.toc {
    padding-left: 1.75rem;
    font-family: $font-family-headings;
    @include font-size(1.25rem);
    font-weight: $font-weight-bold;
    color: $primary;

    li {
        position: relative;
        margin-bottom: .5rem;
        margin-left: 1.5rem;
    }

    .list-nostyle {
        margin-left: 0;
    }
}

.eyebrow {
    margin-bottom: 1rem;
    @include font-size(.875rem);
    font-weight: 700;
    text-transform: uppercase;
}

.directions {
    @include font-size(.875rem);
    font-style: italic;
}

.vocab-word {
    text-decoration: underline dashed 2px;
}
.vocab-tooltip {
    .tooltip-body {
        text-align: left;
    }
    .close {
        @include font-size($tooltip-close-font-size);
    }
}

.attachment {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
}
.attachment-media {
    max-width: Min(75%, 50vw); // stylelint-disable-line function-name-case

    &:not(:empty) {
        margin-right: .5rem;
    }

    @include media-breakpoint-up(md) {
        max-width: Min(75%, 37vw); // stylelint-disable-line function-name-case
    }
    @include media-breakpoint-up(lg) {
        max-width: Min(75%, 26vw); // stylelint-disable-line function-name-case
    }
    @include media-breakpoint-up(xl) {
        max-width: Min(75%, 20vw); // stylelint-disable-line function-name-case
    }

    .img-fluid {
        max-height: 30vw;

        @include media-breakpoint-up(md) {
            max-height: 25vw;
        }
        @include media-breakpoint-up(lg) {
            max-height: 20vw;
        }
        @include media-breakpoint-up(xl) {
            max-height: 15vw;
        }
    }
}
.attachment-action {
    flex: 1;

    &:empty {
        display: none;
    }
}

.fa-stack-custom {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.25em;
    height: 1.25em;
    @include font-size(1.25em);
    line-height: 1;
    vertical-align: middle;

    .fa-stack-sm {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: var(--fa-stack-z-index, auto);
        width: 100%;
        @include font-size(.625em);
        text-align: center;
        transform: translateY(-50%);
    }
}

.form-control[role="combobox"] {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend select.form-control;
}

.rating {
    justify-content: space-around;
    margin-bottom: 1rem;
}
.rating-img {
    padding: .25rem;
    text-align: center;
    border: solid 3px transparent;
    @include border-radius($border-radius);
}
.rating-txt {
    @include font-size(.875rem);
    line-height: 1.25;
    text-align: center;
}
.rating-label {
    max-width: 100%;
    margin-bottom: 0;
    cursor: pointer;
}
.rating-check {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    opacity: 0;

    &:checked ~ .rating-img {
        background-color: #fff;
        box-shadow: $btn-focus-box-shadow;
    }

    &:focus ~ .rating-img {
        background-color: #fff;
        border-color: $active;
        box-shadow: $btn-focus-box-shadow;
    }
}

.library-icons {
    display: flex;
    flex: 0 0 auto;
    width: auto;
}
.library-icon {
    width: 2rem;
    height: 2rem;
    margin-left: .325rem;

    &:focus-visible {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
        @include border-radius(.25rem);
    }
}
.white-space {
    white-space: pre-wrap;
}

// Using `.picker` gives slightly higher specificity than Google's styles
// that are loaded later
.picker.picker-dialog-bg {
    z-index: 1100;
}
.picker.picker-dialog {
    z-index: 1101;
}
