// stylelint-disable selector-class-pattern, selector-no-qualifying-type

// Styling for Firepad/CodeMirror based editor boxes

.firepad,
.firepad-form,
.firepad-form.form-control,
.firepad-form.form-control-lg {
    height: fit-content;
}

textarea.form-control,
.firepad-form.text-area {
    min-height: calc(((#{$input-font-size} * #{$input-line-height}) + #{$input-padding-y} + #{$input-border-width}) * 2);
}

.firepad-form.text-area .CodeMirror-code {
    min-height: calc(#{$input-font-size} * #{$input-line-height} * 2);
}

.firepad .CodeMirror {
    position: relative;
    height: fit-content;
}

.CodeMirror {
    height: fit-content;
    font-family: inherit;
    color: #474747;
}

.CodeMirror .CodeMirror-placeholder {
    position: absolute;
    font-family: inherit;
    font-style: italic;
    font-weight: 300;
    color: #999;
}

.CodeMirror pre {
    padding: 0;
}

.powered-by-firepad {
    display: none;
}
