// stylelint-disable declaration-no-important

@if $enable-utility-valign {
    @each $breakpoint in $utility-valign-breakpoints {
        $bprule: breakpoint-designator($breakpoint);

        @include media-breakpoint-up($breakpoint) {
            .valign#{$bprule}-baseline { vertical-align: baseline !important; }
            .valign#{$bprule}-top { vertical-align: top !important; }
            .valign#{$bprule}-middle { vertical-align: middle !important; }
            .valign#{$bprule}-bottom { vertical-align: bottom !important; }
            .valign#{$bprule}-text-bottom { vertical-align: text-bottom !important; }
            .valign#{$bprule}-text-top { vertical-align: text-top !important; }
        }
    }
}
