// stylelint-disable declaration-no-important

// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://kittygiraudel.com/2016/10/13/css-hide-and-seek/
@mixin sr-only {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;

    // Fix for positioned table caption that could become anonymous cells
    &:not(caption) {
        position: absolute !important;
    }
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
@mixin sr-only-focusable {
    &:not(:focus):not(:focus-within) {
        @include sr-only();
    }
}
