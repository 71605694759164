.popover {
    .img-fluid {
        max-height: none;
    }

    &.has-corgi {
        .popover-body {
            padding-bottom: 5rem;

            &::before {
                position: absolute;
                bottom: 0;
                left: .75rem;
                display: block;
                width: 4rem;
                height: calc(4rem * .9310345); // stylelint-disable-line number-max-precision
                content: "";
                background-image: url("../img/corgi left-sm.png");
                background-size: cover;
            }
        }

        .corgi-offset {
            padding-top: 1.5rem;
            margin-bottom: -4rem;
            margin-left: 4.5rem;
        }
    }
}

.onboard-popover {
    width: 21rem;
    max-width: 21rem;
}
.onboard-dots {
    @include font-size(.5rem);
    white-space: nowrap;

    .active {
        color: #de7802;
    }
}

.onboarding {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-popover;
    width: 100vw;
    height: 100vh;
    pointer-events: none;

    .popover {
        pointer-events: auto;
    }
}
