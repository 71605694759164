.stt-wrapper {
    position: relative;

    textarea,
    input {
        padding-right: 1.5rem;
    }

    // stylelint-disable-next-line selector-class-pattern
    .CodeMirror-code {
        padding-right: 1rem;
    }

    &:focus-within {
        .stt-btn:not(:hover):not(:focus):not(.active) {
            color: $primary;
            opacity: .7;
        }
    }
}

.stt-btn {
    position: absolute;
    top: .25em;
    right: 0;
    z-index: 100;
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    line-height: 1rem;
    color: #666;
    text-align: center;
    cursor: pointer;
    content: " ";
    user-select: none;
    background-color: transparent;
    border: 2px solid transparent;
    @include border-radius(50%);
    opacity: .2;

    &.enabled {
        display: block;
    }
    &:hover,
    &:focus {
        color: $primary;
        opacity: 1;
    }
    &:focus {
        border-color: $active;
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    &.disabled {
        display: block;
        color: #ddd;
        cursor: not-allowed;
    }
    &.active {
        display: block;
        color: #fff;
        background-color: $primary;
        opacity: 1;
    }
}
