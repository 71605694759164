// stylelint-disable declaration-no-important

// http://cssmojo.com/the-very-latest-clearfix-reloaded/
@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin clearfix-disable() {
    &::after {
        // display: initial;
        // clear: initial;
        content: normal !important;
    }
}
