.form-control {
    height: calc(1.5em + .25rem + 4px);
    padding-top: calc(.125rem + 1px);
    padding-bottom: calc(.125rem + 1px);

    &::placeholder {
        font-style: italic;
    }
}

.form-file-dropdown {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    margin: 0;
    line-height: 1;
    opacity: 0;

    // Separate rules for :focus and :focus-within
    // IE doesn't support :focus-within and will ignore ruleset otherwise
    & ~ .dropdown-item:focus,
    &:focus {
        ~ .dropdown-item {
            color: $dropdown-link-hover-color;
            background-color: $dropdown-link-hover-bg;
        }
    }

    &:focus-within {
        ~ .dropdown-item {
            color: $dropdown-link-hover-color;
            background-color: $dropdown-link-hover-bg;
        }
    }
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

@media (min-width: 36em) {
    .form-inline label:not(.form-check-label) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-label {
        margin-bottom: 0;
        vertical-align: middle;
    }
}
