// stylelint-disable declaration-no-important, selector-no-qualifying-type
@page {
    size: a4;
    //size: a4 landscape;
    //size: a4 portait;
    margin: 15mm 0;
}

@media print {
    *,
    *::before,
    *::after {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .main {
        min-height: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .box,
    .box-print,
    .box-autofill,
    .box-unfilled {
        background: transparent !important;
        border: 3px solid #000 !important;
    }

    .print-nobreak {
        page-break-inside: avoid;
    }

    .footer-pages,
    .footer-site {
        display: none;
    }

    .bracket {
        display: flex;

        > :not(.bracket-arrow-vertical) {
            display: block;
        }
    }

    .box,
    .grouping {
        page-break-inside: avoid;
    }

    .connector {
        width: 50% !important;
    }
}
