.pagination {
    margin: 0 .25rem;
}

.page-text,
.page-link {
    font-weight: 700;
    @include btn-icon-sizing();
}

.page-link:focus {
    color: $pagination-color;
    background-color: $pagination-bg;
    border-color: $active;
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
}
.page-link:hover {
    color: $pagination-hover-color;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
}

.page-link.disabled,
.disabled > .page-link {
    opacity: $pagination-disabled-opacity;
}

.pages-prev-icon,
.pages-next-icon {
    @include font-size(.875rem);
}

.pages-prev-icon {
    @include media-breakpoint-up(sm) {
        margin-right: .25rem;
    }
}

.pages-next-icon {
    @include media-breakpoint-up(sm) {
        margin-left: .25rem;
    }
}

.footer-pages {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed - 1;
    display: flex;
    justify-content: center;
    max-width: $container-max-width;
    padding: $navbar-padding-y $navbar-padding-x;
    background-color: $secondary;

    @include media-breakpoint-down(md) {
        .page-item:not(.pages-active):not(.pages-toc):not(.pages-bpv):not(.pages-create):not(.pages-prev):not(.pages-next) {
            display: none;
        }
    }

    @media print {
        display: none;
    }
}

.footer-pages-tour {
    position: absolute;
    right: 0;
    margin-right: 1rem;

    //@include media-breakpoint-down(md) {
    //    display: none;
    //}
}

body:not(.has-bottomnav) {
    .footer-pages {
        display: none;
    }
}
