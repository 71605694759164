.modal {
    text-align: left;
}

.modal-dialog {
    padding-top: 1.25rem;
}
.modal-title {
    line-height: $headings-line-height;
}
.modal-header {
    padding-bottom: 0;
}
.modal-body {
    padding-top: .5rem;
}

.modal .close {
    color: $primary;
    opacity: 1;

    &:hover {
        color: $primary-hover;
        opacity: 1;
    }
    &:focus {
        color: $primary;
        border-color: $active;
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
        opacity: 1;
    }
}

.modal-extra {
    position: relative;
    padding: $modal-body-padding-y $modal-body-padding-x;
}

.keyterm-modal,
.pair-modal {
    .form-check {
        margin-bottom: .5rem;
    }
}
