// stylelint-disable declaration-no-important

@if $enable-utility-bg {
    // Contextual backgrounds
    @if $enable-utility-bg-colors {
        @if (type-of($utility-bg-colors) == "map" and length($utility-bg-colors) != 0) {
            @each $theme, $color in $utility-bg-colors {
                .bg-#{$theme} {
                    background-color: $color !important;
                }
            }
        }
    }

    // Palette colors
    @if $enable-palette and $enable-utility-bg-palette {
        $palette-colors-bg: $palette-colors !default;
        $palette-levels-bg: $palette-levels !default;

        @each $theme, $color in $palette-colors-bg {
            @each $level in $palette-levels-bg {
                .bg-#{$theme}-#{$level} {
                    background-color: palette($color, $level) !important;
                }
            }
        }
    }

    // Special background colors
    @if $enable-utility-bg-special {
        .bg-body { background-color: $body-bg !important; }
        .bg-white { background-color: $white !important; }
        .bg-black { background-color: $black !important; }
        .bg-transparent { background-color: transparent !important; }
    }

    // Background colors with contrasting text
    @if $enable-utility-bgtext {
        @each $color, $value in $utility-bgtext-colors {
            .bgtext-#{$color} {
                color: color-contrast($value) !important;
                background-color: $value !important;
            }
        }
    }
}
