.video-wrapper {
    margin-bottom: 1rem;
}

.player {
    display: none;

    &.ready {
        display: flex;
    }
}

.player-wrapper {
    margin-top: .25rem;
}

.player-control {
    white-space: nowrap;

    .off {
        display: none;
    }
}

.player-time {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    align-self: center;
    margin-right: .5rem;
    margin-left: .5rem;
}
.player-seek {
    flex: 0 1 100%;

    .form-range {
        min-width: auto;
    }
}

.btn[data-cfw-player="caption"] {
    margin-right: .25rem;
}

.player-fullscreen {
    display: inline-block;

    .player-fullscreen-on {
        display: none;
    }
    .player-fullscreen-off {
        display: inline-block;
    }

    &.active {
        .player-fullscreen-on {
            display: inline-block;
        }
        .player-fullscreen-off {
            display: none;
        }
    }
}

.player-unstarted  {
    .embed-fluid {
        &:hover {
            cursor: pointer;
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 65px;
            height: 65px;
            padding: 5px;
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: "Font Awesome 5 Free";
            // stylelint-disable-next-line property-disallowed-list
            font-size: 35px;
            font-weight: 900;
            line-height: 1;
            color: $body-color;
            pointer-events: none;
            content: "\f04b";
            background-color: $active;
            border: 4px solid $body-color;
            @include border-radius(50%);
            opacity: .8;
            transform: translate3d(-50%, -50%, 0);
        }

        &:hover::after {
            opacity: 1;
        }
    }
}

.player-notime .player-time-current,
.player-notime .player-time-duration,
.player-notime .player-seek,
.player-live .player-time-duration,
.player-live .player-seek {
    visibility: hidden;
}

// Player in fullscreen mode
.player-fulldisplay {
    position: relative;
    // stylelint-disable declaration-no-important
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    // stylelint-enable declaration-no-important
    max-height: 100%;
    padding: 0;
    margin: 0;
    background: #000;

    .player-wrapper {
        position: absolute;
        right: 0;
        bottom: 1rem;
        left: 0;
        margin-top: 0;
        text-align: center;
        pointer-events: none;
    }

    .player {
        display: inline-flex;
        width: 30rem;
        max-width: 100vw;
        padding: .5rem;
        margin: 0;
        color: #222;
        pointer-events: auto;
        background-color: #d4eef4;
        border: 1px solid $uibase-300;
        @include border-radius(.375rem);
    }

    &.player-inactive {
        cursor: none;

        .player {
            visibility: hidden;
            opacity: 0;
            @include transition(visibility 1s, opacity 1s);
        }
    }

    &.player-inactive.player-paused .player {
        visibility: visible;
        opacity: 1;
        @include transition(all .15s);
    }
}

.player-caption-display {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    display: block;
    max-width: 100%;
    padding: .25rem;
    @include font-size(.875rem);
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, .65);
    @include border-radius(.25rem);
    transform: translateX(-50%);
}
.player-fulldisplay .player-caption-display {
    bottom: 5rem;
    padding: .5rem 1rem;
    @include font-size(1.25rem);
}
