@if $enable-typography {
    // Headings
    @if $enable-typography-headings {
        .h1 {
            @extend h1;
        }

        .h2 {
            @extend h2;
        }

        .h3 {
            @extend h3;
        }

        .h4 {
            @extend h4;
        }

        .h5 {
            @extend h5;
        }

        .h6 {
            @extend h6;
        }
    }

    // Lead in
    @if $enable-typography-lead {
        .lead {
            @include font-size($lead-font-size);
            font-weight: $lead-font-weight;
            line-height: $lead-line-height;
        }
    }

    // Emphasis
    @if $enable-typography-small {
        .small {
            @extend small;
        }
    }

    @if $enable-typography-mark {
        .mark {
            @extend mark;
        }
    }

    // Lists
    @if $enable-typography-list-unstyled {
        .list-unstyled {
            @include list-unstyled();
        }
    }

    //  Abbr extension
    @if $enable-typography-initialism {
        .initialism {
            @include font-size($initialism-font-size);
            text-transform: uppercase;
        }
    }

    // Blockquotes
    @if $enable-typography-blockquote {
        .blockquote {
            margin: $blockquote-margin;
            @include font-size($blockquote-font-size);
            color: $blockquote-color;

            > :last-child {
                margin-bottom: 0;
            }
        }
        .blockquote-footer {
            margin: $blockquote-footer-margin;
            @include font-size($blockquote-footer-font-size);
            color: $blockquote-footer-color;

            &::before {
                content: $blockquote-footer-content;
            }
        }
    }
}
