.card-recent {
    .card-header {
        padding: .25rem 1rem;
        @include font-size(.875rem);
        font-weight: 700;
        border-bottom: 0;
    }

    .card-body {
        padding: .5rem 1rem;
    }

    h3 {
        @include font-size(1rem);
        font-family: $font-family-headings;
    }
}

.card-newguide {
    .card-img {
        padding: 1rem;
        margin: 1rem 1rem .5rem;
        background-color: #fff;
        @include border-radius($card-inner-border-radius);

        img {
            display: block;
            max-width: 66.666666%;
            margin: 0 auto;
        }
    }

    h3 {
        @include font-size(1rem);
        font-family: $font-family-headings;
    }
}

.card-library {
    .card-header {
        padding: .25rem 1rem;
        @include font-size(.875rem);
        font-weight: 700;
        border-bottom: 0;

        .col {
            display: flex;
            align-items: center;
        }
    }

    .card-body {
        padding: .5rem 1rem;
    }

    .card-title {
        margin-bottom: 0;
        @include font-size(1rem);
        font-family: $font-family-headings;
    }
}
