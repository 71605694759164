.content.has-sidebar .content-sidebar {
    display: block;
    flex: 0 0 auto;
    width: 8rem;
}
.content.has-sidebar-open .content-sidebar {
    display: block;
    flex: 0 0 auto;
    width: 33%;
}
.content.has-sidebar-expand {
    .content-sidebar {
        display: block;
        flex: 0 0 auto;
        width: 90%;
    }
    .content-main {
        // Use visibility to maintain scroll position in Chrome
        // Firefox still has issue with reseting - may need JS intervention to save/reset scrollTop
        // when sidebar is expanded full then collapsed/hidden
        //display: none;
        visibility: hidden;
    }
}
.content.has-sidebar-expand #sidebarActionExpand { // stylelint-disable-line selector-max-id
    display: none;
}
.content:not(.has-sidebar-expand) #sidebarActionCollapse { // stylelint-disable-line selector-max-id
    display: none;
}
.content:not(.has-sidebar-open):not(.has-sidebar-expand) .content-sidebar-action,
.content:not(.has-sidebar-open):not(.has-sidebar-expand) .content-sidebar-panel {
    display: none;
}

.content:not(.has-sidebar-open):not(.has-sidebar-expand) .nav-link-txt {
    @include sr-only();
}
.content.has-sidebar-open .nav-link-icon,
.content.has-sidebar-expand .nav-link-icon {
    margin-right: .25rem;
}

.content-sidebar {
    .nav-tabs {
        padding-left: .5rem;
        border-bottom: 0;
    }
    .nav-item + .nav-item {
        margin-left: .25rem;
    }
    .nav-link {
        padding: $nav-tabs-padding-y $nav-tabs-padding-x;
        margin-bottom: 0;
        @include font-size($nav-tabs-font-size);
        font-weight: 700;
        border-bottom: 0;

        @include btn-icon-sizing();

        &:not(.active) {
            color: $nav-tabs-color;
            background-color: $nav-tabs-bg;
        }
        &:hover {
            // color: $nav-tabs-hover-color;
            // background-color: $nav-tabs-hover-bg;
            // border-color: $nav-tabs-hover-bg;
            box-shadow: none;
        }
        &:focus {
            // color: $nav-tabs-color;
            // background-color: $nav-tabs-bg;
            border-color: $active;
            outline: 0;
            box-shadow: $btn-focus-box-shadow;
        }
    }
}

.content-sidebar-container {
    position: relative;
    height: calc(100% - 1.75rem - 2px);
    background-color: #d4eef4;
    @include border-top-end-radius($border-radius);
}
.content-sidebar-action {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 2rem;
    padding-right: .5rem;

    .btn-link {
        padding: 0;
    }

    .btn + .btn {
        margin-left: .5rem;
    }
}

.content-sidebar-panel {
    &:not(.in) {
        display: none;
    }

    &.in {
        position: absolute;
        top: 2rem;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% - 2rem);
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

.content-sidebar-inner {
    max-width: $content-inner-max-width;
    padding: .75rem .75em 1.5em;
    margin: 0 auto;

    .section {
        margin-bottom: 1.5rem;
    }
}

.content-sidebar-sticky-top {
    @include media-breakpoint-up(lg) {
        .has-sidebar-expand & {
            position: sticky;
            top: -2px;
            z-index: $zindex-sticky;
            background-color: #d4eef4;
        }
    }
    @include media-breakpoint-up(xl) {
        position: sticky;
        top: -2px;
        z-index: $zindex-sticky;
        background-color: #d4eef4;
    }
}
