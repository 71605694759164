// Settings
// =====

$link-icon-margin-end:              .3125rem !default;
$link-icon-icon-margin-start:       .25em !default;
$link-icon-icon-font-size:          .875em !default;

// Rules
// =====

// Link with icon
.link-icon {
    position: relative;
    margin-right: $link-icon-margin-end;

    .fa,
    .fa-brands,
    .fa-duotone,
    .fa-light,
    .fa-regular,
    .fa-solid,
    .fa-thin,
    .fab,
    .fad,
    .fal,
    .far,
    .fas,
    .fat {
        margin-left: $link-icon-icon-margin-start;
        @include font-size($link-icon-icon-font-size);
        text-decoration: none;

        @include hover-focus() {
            text-decoration: none;
        }
    }

    .fa-external-link {
        top: .125em;
    }
}
