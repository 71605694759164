// stylelint-disable declaration-no-important

@if $enable-utility-user-select {
    .user-select-all {
        user-select: all !important;
    }
    .user-select-auto {
        user-select: auto !important;
    }
    .user-select-none {
        user-select: none !important;
    }
}

@if $enable-utility-pointer-events {
    .pe-none {
        pointer-events: none !important;
    }
    .pe-auto {
        pointer-events: auto !important;
    }
}
