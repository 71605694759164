@if $enable-badge {
    // Base class
    .badge {
        display: inline-block;
        padding: $badge-padding-y $badge-padding-x;
        font-family: $badge-font-family;
        @include font-size($badge-font-size);
        font-weight: $badge-font-weight;
        line-height: $badge-line-height;
        color: $badge-color;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        vertical-align: baseline;
        background-color: $badge-bg;
        border: $badge-border-width solid transparent;
        @include border-radius($badge-border-radius);

        // Empty badges collapse automatically
        &:empty {
            display: none;
        }

        @if $enable-badge-close {
            .close {
                padding-right: $badge-close-padding-x;
                padding-left: $badge-close-padding-x;
                margin-right: -$badge-close-padding-x;
                @include font-size($badge-close-font-size);
            }
        }
    }

    // Quick fix for badges in buttons
    @if $enable-btn {
        .btn .badge {
            position: relative;
            top: -1px;
        }
    }

    // Badge group
    @if $enable-badge-group {
        .badge-group {
            display: inline-flex;
            vertical-align: baseline;

            > .badge {
                &:not(:first-child) {
                    margin-left: -$badge-border-width;
                    @include border-start-radius(0);
                }

                &:not(:last-child) {
                    @include border-end-radius(0);
                }
            }
        }
    }
}
