.bracket {
    position: relative;
    display: flex;
    justify-content: center;
    height: 66px;
    margin: -1rem 0 0;
    pointer-events: none;
}

.bracket-short {
    height: 33px;
}
.bracket-tall {
    height: 90px;
}

.bracket-print {
    display: none;

    & + .box {
        margin-top: 0;
    }
}

.bracket-action {
    position: absolute;
    top: .5rem;
    left: 0;
    pointer-events: auto;
}

.bracket-start {
    width: 32px;
    height: 66px;
    content: url("../img/bracket-start.png");

    .bracket-down & {
        content: url("../img/bracket-down-start.png");
    }
}
.bracket-end {
    width: 32px;
    height: 66px;
    content: url("../img/bracket-end.png");

    .bracket-down & {
        content: url("../img/bracket-down-end.png");
    }
}
.bracket-arrow {
    width: 49px;
    height: 66px;
    content: url("../img/bracket-arrow.png");

    .bracket-down & {
        content: url("../img/bracket-down-arrow.png");
    }
}
.bracket-bar {
    width: 20%;
    height: 66px;
    content: url("../img/bracket-bar.png");

    .bracket-down & {
        content: url("../img/bracket-down-bar.png");
    }
}

.bracket-bar-start {
    width: 32px;
    height: 66px;
    content: url("../img/bracket-bar-start.png");

    .bracket-down & {
        content: url("../img/bracket-down-bar-start.png");
    }
}
.bracket-bar-end {
    width: 32px;
    height: 66px;
    content: url("../img/bracket-bar-end.png");

    .bracket-down & {
        content: url("../img/bracket-down-bar-end.png");
    }
}

.bracket-arrow-vertical {
    width: 49px;
    height: 66px;
    content: url("../img/bracket-arrow-vertical.png");

    .bracket-short & {
        height: 33px;
        content: url("../img/bracket-arrow-vertical-short.png");
    }

    .bracket-tall & {
        height: 90px;
        content: url("../img/bracket-arrow-vertical-tall.png");
    }
}

.bracket-bar-vertical {
    width: 49px;
    height: 66px;
    content: url("../img/bracket-bar-vertical.png");

    .bracket-short & {
        height: 33px;
        content: url("../img/bracket-bar-vertical-short.png");
    }

    .bracket-tall & {
        height: 90px;
        content: url("../img/bracket-bar-vertical-tall.png");
    }
}

.bracket-bar-vertical-stretch {
    height: 100%;
    background-image: url("../img/bracket-bar-vertical.png");
    background-repeat: repeat-y;
    background-position: calc(50% + 1px) center;
}

// Bracket modifiers
.bracket-2,
.bracket-3,
.bracket-4 {
    @include media-breakpoint-down(sm) {
        > :not(.bracket-arrow-vertical) {
            display: none;
        }
    }

    @include media-print-breakpoint-up(md) {
        > .bracket-arrow-vertical {
            display: none;
        }

        // stylelint-disable-next-line selector-max-class
        //&.bracket-down + .grouping > .grouping-title {
        //    position: relative;
        //    top: -1.375rem;
        //}
    }
}
.bracket-3 {
    .bracket-start + .bracket-bar {
        width: 24%;
    }
    .bracket-bar-start + .bracket-bar {
        width: 0%;
    }
    .bracket-arrow + .bracket-bar {
        width: calc(24% + 32px);
    }
}

.bracket-4 {
    .bracket-start + .bracket-bar,
    .bracket-bar-end + .bracket-bar {
        width: 22%;
    }
    .bracket-bar-start + .bracket-bar,
    .bracket-arrow + .bracket-bar {
        width: 7%;
    }
}

// Grouping
.grouping {
    position: relative;
    @include make-row(.5rem);

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    > * {
        flex: 0 0 auto;
        max-width: 100%;
        padding-right: (.5rem / 2);
        padding-left: (.5rem / 2);
    }

    & + & {
        margin-top: 1.5rem;
    }
}
.grouping-title {
    flex: 0 0 100%;
    margin-top: .75rem;
    margin-bottom: 0;

    @include media-print-breakpoint-up(md) {
        margin-top: -1.5rem;
    }

    :not(.bracket) + .grouping & {
        margin-top: 0;
    }
}

h2,
h3,
h4,
h5,
h6 {
    // stylelint-disable selector-max-compound-selectors
    & + .row .review-main .grouping-title,
    & + .row .col-lg-8 .grouping-title {
        margin-top: 0;
    }
    // stylelint-enable selector-max-compound-selectors
}

.grouping-text {
    flex: 1 0;
    width: 100%;
}
.grouping-img {
    width: 100%;
    margin-top: .5rem;

    &:empty {
        display: none;
    }

    @include media-breakpoint-up(md) {
        width: percentage(5 / 12);
        margin-top: 0;
        text-align: right;
    }

    .img-fluid {
        max-height: 30vw;

        @include media-breakpoint-up(md) {
            max-height: 25vw;
        }
        @include media-breakpoint-up(lg) {
            max-height: 20vw;
        }
        @include media-breakpoint-up(xl) {
            max-height: 15vw;
        }
    }
}

.grouping-item {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    & > .box {
        flex-grow: 1;
    }

    @include media-print-breakpoint-up(md) {
        flex: 1 0;
    }

    & + &::before {
        display: block;
        width: 49px;
        height: 33px;
        margin: -1rem auto 0;
        content: "";
        background-image: url("../img/bracket-bar-vertical-short.png");

        @include media-print-breakpoint-up(md) {
            display: none;
        }
    }

    & + & {
        .grouping-item-title {
            position: absolute;
            top: -.5rem;

            @include media-print-breakpoint-up(md) {
                //position: static;
                top: 0;
                margin-top: 0;
            }
        }
    }
}

.bracket + .grouping .grouping-item-title {
    position: absolute;
    top: -1.5rem;
}

.row + .row .review-main .grouping-title,
.row + .row .col-lg-8 .grouping-title,
.bracket-down + .grouping .grouping-title {
    position: absolute;
    top: -2.25rem;

    @include media-print-breakpoint-up(md) {
        top: -1.25rem;
    }

    @include media-print-breakpoint-down(sm) {
        top: 0;
    }
}

.grouping-match {
    .grouping-item > .box {
        flex-grow: 1;
    }
}

.grouping-icon {
    width: 100%;
    margin-top: .75rem;

    .fa {
        // offset 1/2 of nested .box `margin-bottom`
        position: relative;
        top: -.25rem;
        display: block;
        align-self: center;
        line-height: 1.5;
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        width: auto;
    }
}

.grouping-icon-title-offset {
    margin-top: 2.375rem;
}

.grouping-1 {
    @include media-print-breakpoint-up(md) {
        .grouping-title {
            top: 0 !important; // stylelint-disable-line declaration-no-important
        }
        .grouping-img {
            width: percentage(3 / 12);
        }
    }
}

.grouping-2 {
    @include media-print-breakpoint-up(md) {
        .grouping-item {
            width: percentage(6 / 12);
        }
        .grouping-img {
            width: percentage(6 / 12);
        }
    }
}

.grouping-3 {
    @include media-print-breakpoint-up(md) {
        .grouping-item {
            width: percentage(4 / 12);
        }
        .grouping-text {
            flex: 0 0 auto;
        }
        .grouping-img {
            width: percentage(8 / 12);
        }
    }
}

.grouping-4 {
    @include media-print-breakpoint-up(md) {
        .grouping-item {
            width: percentage(3 / 12);
        }
        .grouping-text {
            flex: 0 0 auto;
        }
        .grouping-img {
            width: percentage(9 / 12);
        }
    }
}

// Full routine overrides
.has-sidebar-open .content-sidebar {
    .bracket-2,
    .bracket-3,
    .bracket-4 {
        > :not(.bracket-arrow-vertical) {
            display: none;
        }

        > .bracket-arrow-vertical {
            display: block;
        }
    }

    .grouping-item {
        flex: 0 0 auto;
        width: 100%;

        &::before {
            display: block !important; // stylelint-disable-line declaration-no-important
        }
    }

    .grouping-item + .grouping-item {
        // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors
        .grouping-item-title {
            position: absolute;
            top: -.5rem;
        }
    }

    .grouping-text {
        flex: 1 0;
        width: 100%;
    }

    .grouping-img {
        width: 100%;

        @include media-print-breakpoint-up(md) {
            width: percentage(4 / 12);
        }
    }

    .grouping-icon {
        width: 100%;
        margin-top: -.5rem;
    }

    // stylelint-disable-next-line selector-max-class, selector-max-compound-selectors
    .bracket + .grouping .grouping-title {
        top: 0;
    }
}

// Non-height aligned boxes
// stylelint-disable no-duplicate-selectors
.grouping-item {
    @include media-print-breakpoint-up(md) {
        justify-content: flex-end;
    }

    & > .box {
        flex-grow: 0;
    }
}

.connector ~ .grouping .grouping-item {
    @include media-print-breakpoint-up(md) {
        justify-content: flex-start;
    }
}
// stylelint-ernable no-duplicate-selectors

.grouping-start .grouping-item {
    @include media-print-breakpoint-up(md) {
        justify-content: flex-start;
    }
}
.grouping-end .grouping-item {
    @include media-print-breakpoint-up(md) {
        justify-content: flex-end;
    }
}

.grouping-lessonmap {
    .grouping-item {
        flex-grow: 1;
    }

    .grouping-item + .grouping-item::before {
        content: none;
    }
}
