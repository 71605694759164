// stylelint-disable declaration-no-important, property-disallowed-list

@if $enable-utility-border {
    // Perfect circle
    @if $enable-utility-border-radius-circle {
        .radius-circle {
            border-radius: 50% !important;
        }
    }

    @if $enable-utility-border-radius-pill {
        .radius-pill {
            border-radius: $radius-pill !important;
        }
    }

    // Radius addition - Default size
    @if $enable-utility-border-radius-addition {
        @include radius-sides($radius-border-radius);
        @include radius-corners($radius-border-radius);
    }

    // Radius addition - Size variants
    @if $enable-utility-border-radius-sizing {
        @each $size, $dim in $radii {
            @include radius-sides($dim, $size);
            @include radius-corners($dim, $size);
        }
    }

    // Radius removal
    @if $enable-utility-border-radius-removal {
        @include radius-sides(0 !important, 0);
        @include radius-corners(0 !important, 0);
    }


    // Responsive border addition and removal
    @each $breakpoint in $utility-border-breakpoints {
        $bprule: breakpoint-designator($breakpoint);

        @include media-breakpoint-up($breakpoint) {
            // Border addition
            @if $enable-utility-border-addition {
                .border#{$bprule} { border: $border-width solid $border-color !important; }
                .border#{$bprule}-t,
                .border#{$bprule}-y {
                    border-top: $border-width solid $border-color !important;
                }
                .border#{$bprule}-e,
                .border#{$bprule}-x {
                    border-right: $border-width solid $border-color !important;
                }
                .border#{$bprule}-b,
                .border#{$bprule}-y {
                    border-bottom: $border-width solid $border-color !important;
                }
                .border#{$bprule}-s,
                .border#{$bprule}-x {
                    border-left: $border-width solid $border-color !important;
                }
            }

            // Border removal
            @if $enable-utility-border-removal {
                .border#{$bprule}-0 { border: 0 !important; }
                .border#{$bprule}-t-0,
                .border#{$bprule}-y-0 {
                    border-top: 0 !important;
                }
                .border#{$bprule}-e-0,
                .border#{$bprule}-x-0 {
                    border-right: 0 !important;
                }
                .border#{$bprule}-b-0,
                .border#{$bprule}-y-0 {
                    border-bottom: 0 !important;
                }
                .border#{$bprule}-s-0,
                .border#{$bprule}-x-0 {
                    border-left: 0 !important;
                }
            }
        }
    }

    // Border width
    @if $enable-utility-border-width {
        @each $size, $dim in $border-widths {
            .border-#{$size} {
                border-width: $dim !important;
            }
        }
    }

    // Contextual borders
    @if $enable-utility-border-colors {
        @if (type-of($utility-border-colors) == "map" and length($utility-border-colors) != 0) {
            @each $theme, $color in $utility-border-colors {
                .border-#{$theme} {
                    border-color: $color !important;
                }
            }
        }
    }

    // Palette colors
    @if $enable-palette and $enable-utility-border-palette {
        $palette-colors-borders: $palette-colors !default;
        $palette-levels-borders: $palette-levels !default;

        @each $theme, $color in $palette-colors-borders {
            @each $level in $palette-levels-borders {
                .border-#{$theme}-#{$level} {
                    border-color: palette($color, $level) !important;
                }
            }
        }
    }

    // Special border colors
    @if $enable-utility-border-special {
        .border-white { border-color: $white !important; }
        .border-black { border-color: $black !important; }
        .border-transparent { border-color: transparent !important; }
        .border-inherit { border-color: inherit !important; }
    }
}
