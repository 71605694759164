:root {
    --header-offset: 3rem;
    scroll-margin: var(--header-offset);
}

:target {
    //padding-top: var(--header-offset);
    //margin-top: -var(--header-offset);
    scroll-margin: var(--header-offset);
    background-clip: content-box;

    &:not(.main) {
        display: inline-block;
    }
}

.site-header {
    position: sticky;
    top: 0;
    z-index: $zindex-fixed + 1;
    display: flex;
    justify-content: space-between;
    background-color: $secondary;

    .offcanvas-body {
        @include font-size(.875rem);
    }

    @media print {
        display: none;
    }
}

.site-header-end {
    display: flex;
    padding: .5rem 1rem;
    margin-left: auto;
}

.header-menu-btn {
    padding-top: .25rem;
    padding-bottom: .125rem;

    .fa-bars {
        @include font-size(1.25rem);
    }
}

.header-brand {
    display: inline-flex;
    align-items: center;
    margin-left: .25rem;
    text-decoration: none;

    @include hover-focus() {
        text-decoration: underline;
    }
}

.header-logo {
    display: inline-block;
    width: 2.5rem;
    height: 120%;
    margin-top: -.375rem;
    margin-right: .5rem;
    margin-bottom: calc((.5rem + 3px) * -1);
}

.header-title {
    padding: 0;
    margin-right: .25rem;
    font-family: $font-family-headings;
    @include font-size(1.25rem);
    font-weight: 700;
    color: #000;
    vertical-align: middle;
}

.header-tagline {
    @include font-size(.875rem);
    color: #000;

    @media (max-width: 51.98em) {
        @include sr-only();
    }
}

.header-tts {
    position: fixed;
    top: 0;
    left: Min(50%, 800px);  // stylelint-disable-line function-name-case
    z-index: $zindex-modal + 1;
    display: flex;
    padding: .5rem;
    background-color: $secondary;
    @include border-radius(0 0 .375rem .375rem);
    transform: translateX(-50%);

    .btn-tts:not(:first-child) {
        margin-left: .25rem;
    }

    .tts-pause {
        display: none;
    }

    &.playing {
        .tts-play {
            display: none;
        }
        .tts-pause {
            display: inline-block;
        }
    }

    @media print {
        display: none;
    }
}

.header-collab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: .5rem;
    margin-right: .5rem;
    border-right: 1px solid #bfc6cd;

    .media {
        margin-bottom: 0;
        white-space: nowrap;
    }
}

.header-collab-count {
    display: inline-block;
    margin-left: .125rem;
    @include font-size(.875rem);
    font-weight: 700;
    color: $primary;
}

.header-user {
    position: relative;
}

.header-btns {
    display: inline-flex;
    align-items: center;
}
