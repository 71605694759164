@mixin box-variant($bg, $color) {
    color: $color;
    background-color: $bg;
}

.box {
    //min-height: calc(1.5rem + 1rem + 2px);
    min-height: calc(1.5rem + 1rem);
    padding: calc(.5rem - 3px);
    margin-bottom: 1rem;
    word-break: break-word;
    border: 3px solid transparent;
    @include border-radius($box-border-radius);

    //> :not(.grouping) {
    //    .box:last-child,
    //    .form-group:last-child {
    //        margin-bottom: 0;
    //    }
    //}

    .box {
        margin-bottom: .5rem;
    }
}

// Contextual modifiers
@if (type-of($box-themes) == "map" and length($box-themes) != 0) {
    @each $theme, $colors in $box-themes {
        $box-bg:          map-get($colors, "bg");
        $box-color:       map-get($colors, "color");

        $box-color:       color-if-contrast($box-color, $box-bg);

        .box-#{$theme} {
            @include box-variant($box-bg, $box-color);
        }
    }
}

.box-row-close {
    margin-top: -.5rem;
    margin-right: -.5rem;
    text-align: right;
}

.connector {
    width: 100%;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;

    &:not(.bg-autofill) {
        padding-bottom: 0;
    }
    &.bg-autofill {
        margin-top: .5rem;
    }

    @include media-breakpoint-up(sm) {
        width: 50%;
    }

    @include media-breakpoint-up(md) {
        width: percentage(5 / 12);
    }

    @include media-breakpoint-up(lg) {
        width: percentage(4 / 12);
    }

    .has-sidebar-open .content-sidebar & {
        width: percentage(8 / 12);
    }
}
