.alert-topbar {
    padding: .25rem 2rem;
    margin-bottom: 0;
    text-align: center;
    @include border-radius(0);

    .close {
        top: 50%;
        right: .25rem;
        padding: .25rem .5rem;
        @include font-size(1rem);
        opacity: .9;
        transform: translateY(-50%);

        @include hover-focus() {
            opacity: 1;
        }
    }
}
.alert-view {
    color: $white;
    background: $black;
    border: 0;

    a {
        color: #eee;

        @include hover-focus() {
            color: #fff;
        }
        &:focus {
            outline: #fff solid 1px;
        }
    }
}
