// stylelint-disable declaration-no-important, selector-no-qualifying-type

// Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request:
// http://www.phpied.com/delay-loading-your-print-css/
// ==========================================================================

@if $enable-print-styles {
    @media print {
        *,
        *::before,
        *::after {
            // color: #000 !important; // Black prints faster: http://www.sanbeiji.com/archives/953
            text-shadow: none !important;
            // background: transparent !important;
            box-shadow: none !important;
        }

        a,
        a:visited {
            text-decoration: underline;
        }

        // a[href]::after {
        //     content: " (" attr(href) ")";
        // }

        // a[href^="#"]::after,
        // a[href^="javascript:"]::after {
        //     content: "";
        // }

        abbr[title]::after {
            content: " (" attr(title) ")";
        }

        pre {
            white-space: pre-wrap !important;
        }
        pre,
        blockquote {
            border: $border-width solid #999;   // Figuration change - use `$border-width` setting instead of 1px default
            page-break-inside: avoid;
        }

        // Printing Tables:
        // http://css-discuss.incutio.com/wiki/Printing_Tables
        thead {
            display: table-header-group;
        }

        tr,
        img {
            page-break-inside: avoid;
        }

        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }

        // Figuration components start

        .badge {
            border: $border-width solid #000;
        }

        @if $enable-table {
            .table {
                color: inherit !important;
                border-collapse: collapse !important;
                border-color: $table-border-color !important;

                thead,
                tbody,
                tfoot {
                    color: inherit !important;
                }

                tr,
                td,
                th {
                    color: inherit !important;
                    background-color: #fff !important;
                }
            }
        }

        // Figuration components end
    }
}
