.skipnav {
    position: absolute;
    top: -100px;
    left: 0;
    z-index: 1025;
    display: block;
    max-width: 100%;
    padding: .25rem .5rem;
    @include font-size(1rem);
    font-weight: 700;
    color: #fff;
    text-align: center;
    background: #343e48;

    &:focus {
        top: 0;
        left: 0;
        color: #fff;
    }
}

.skipnav-target {
    outline: 0;
}

.skipnav-main {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
